import { toast } from 'react-toastify';
import { CameoCard, CategoryCard, FeaturedCard, PKRoute, PokemonSet, SetCategoryStats, SetFeaturedStats, SetCameoStats } from '../types/admin';
import config from '../routes/config';
import { API_URL } from '../config';

// Define AuthParams interface if it's not imported
interface AuthParams {
    userName: string;
    adminKey: string;
    [key: string]: string | number | boolean;
}

interface AdminApiResponse {
    success: boolean;
    message?: string;
}

interface GetCategoryCardsResponse extends AdminApiResponse {
    cards?: CategoryCard[];
}

interface GetFeaturedCardsResponse extends AdminApiResponse {
    cards?: FeaturedCard[];
}

interface CardCategoryStats extends AdminApiResponse {
    totalCards?: number;
    processedCards?: number;
}

interface FeaturedCardStats extends AdminApiResponse {
    totalCards?: number;
    featuredCards?: number;
}

interface CameoCardStats extends AdminApiResponse {
    totalCards?: number;
    cameoCards?: number;
}

interface GetCameoCardsResponse extends AdminApiResponse {
    cards?: CameoCard[];
}

interface GetCurrentFeaturedPokemonResponse extends AdminApiResponse {
    pokemon?: {
        id: number;
        name: string;
        picURL: string;
        number: string;
    }[];
}

interface RouteData {
    path?: string;
    state: string;
    tcg_state: string;
    is_beta: boolean;
    is_index: boolean;
    is_menu?: boolean;
    component_name: string;
    is_set?: boolean;
    displayName: string;
    icon?: string;
    pokemon_set?: {
        id: number;
        name: string;
        numberOfCards: number;
        releaseDate: string;
        includesReverseHolographicCards: boolean;
        icon_location: string;
        logo_location: string;
    };
    child?: RouteData[];
}

interface GetRoutesResponse extends AdminApiResponse {
    routes?: RouteData[];
}

interface GetPKRoutesResponse extends AdminApiResponse {
    routes?: PKRoute[];
}

interface UpdatePKRouteResponse extends AdminApiResponse {
    message?: string;
}

interface GetPokemonSetsResponse extends AdminApiResponse {
    sets?: PokemonSet[];
}

interface UpdatePokemonSetResponse extends AdminApiResponse {
    message?: string;
}

// New interfaces for set statistics responses
interface GetSetCategoryStatsResponse extends AdminApiResponse {
    stats?: SetCategoryStats[];
}

interface GetSetFeaturedStatsResponse extends AdminApiResponse {
    stats?: SetFeaturedStats[];
}

interface GetSetCameoStatsResponse extends AdminApiResponse {
    stats?: SetCameoStats[];
}

const fetchConfig = {
    credentials: 'include' as RequestCredentials,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};

let routesCache: {
    data: RouteData[] | undefined;
    timestamp: number;
    promise: Promise<GetRoutesResponse> | null;
} = {
    data: undefined,
    timestamp: 0,
    promise: null
};

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

export const getCardCategoryStats = async (authParams: any): Promise<CardCategoryStats> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`${config.API_URL}/admin/getCardCategoryStats?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: CardCategoryStats = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch card category statistics');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching card statistics');
        return {
            success: false,
            totalCards: 0,
            processedCards: 0,
        };
    }
};

export const getCardCategoryPokemonCardData = async (
    authParams: any,
    setId?: number | null
): Promise<GetCategoryCardsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
            ...(setId && { setId: setId.toString() })
        }).toString();

        const response = await fetch(`${config.API_URL}/admin/getCardCategoryPokemonCardData?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        
        const result: GetCategoryCardsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch card data');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching cards');
        return {
            success: false,
            cards: [],
        };
    }
};

export const updateCardCategory = async (
    authParams: any,
    cardId: number,
    cardType: string
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch(`${config.API_URL}/admin/updateCardCategory`, {
            method: 'POST',
            ...fetchConfig,
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                cardType,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to update card category');
        } else {
            toast.success('Card category updated successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating card category');
        return { success: false };
    }
};

export const getFeaturedCardStats = async (authParams: any): Promise<FeaturedCardStats> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`${config.API_URL}/admin/getFeaturedCardStats?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: FeaturedCardStats = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch featured card stats');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching featured card stats');
        return {
            success: false,
            totalCards: 0,
            featuredCards: 0,
        };
    }
};

export const getFeaturedPokemonCards = async (
    authParams: any,
    setId?: number | null
): Promise<GetFeaturedCardsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
            ...(setId && { setId: setId.toString() })
        }).toString();

        const response = await fetch(`${config.API_URL}/admin/getFeaturedPokemonCards?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: GetFeaturedCardsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch featured cards');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching featured cards');
        return {
            success: false,
            cards: [],
        };
    }
};

export const updateCardFeatured = async (
    authParams: any,
    cardId: number,
    featuredPokemonId: number,
    hasCameoPokemon: boolean
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch(`${config.API_URL}/admin/updateCardFeatured`, {
            method: 'POST',
            ...fetchConfig,
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                featuredPokemonId,
                hasCameoPokemon,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to update featured status');
        } else {
            toast.success('Featured status updated successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating featured status');
        return { success: false };
    }
};

export const getCameoCardStats = async (authParams: any): Promise<CameoCardStats> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`${config.API_URL}/admin/getCameoCardStats?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: CameoCardStats = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch cameo card stats');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching cameo statistics');
        return {
            success: false,
            totalCards: 0,
            cameoCards: 0,
        };
    }
};

export const getCameoPokemonCards = async (authParams: any, setId?: number | null): Promise<GetCameoCardsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        if (setId) {
            stringAuthParams.setId = String(setId);
        }

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`${config.API_URL}/admin/getCameoPokemonCards?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: GetCameoCardsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch cameo cards');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching cameo cards');
        return {
            success: false,
            cards: [],
        };
    }
};

export const updateCardCameo = async (
    authParams: any,
    cardId: number,
    cameoPokemonId: number
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch(`${config.API_URL}/admin/updateCardCameo`, {
            method: 'POST',
            ...fetchConfig,
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                cameoPokemonId,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to update cameo status');
        } else {
            toast.success('Cameo status updated successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating cameo status');
        return { success: false };
    }
};

export const getCurrentFeaturedPokemon = async (
    authParams: any,
    cardId: number
): Promise<GetCurrentFeaturedPokemonResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
            cardId: cardId.toString(),
        }).toString();

        const response = await fetch(`${config.API_URL}/admin/getCurrentFeaturedPokemon?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: GetCurrentFeaturedPokemonResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch current featured Pokemon');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching current featured Pokemon');
        return {
            success: false,
            pokemon: [],
        };
    }
};

export const getCurrentCameoPokemon = async (
    authParams: any,
    cardId: number
): Promise<GetCurrentFeaturedPokemonResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
            cardId: cardId.toString(),
        }).toString();

        const response = await fetch(`${config.API_URL}/admin/getCurrentCameoPokemon?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: GetCurrentFeaturedPokemonResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch current cameo Pokemon');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching current cameo Pokemon');
        return {
            success: false,
            pokemon: [],
        };
    }
};

export const completeFeaturedCard = async (
    authParams: any,
    cardId: number,
    hasCameoPokemon: boolean
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch(`${config.API_URL}/admin/completeFeaturedCard`, {
            method: 'POST',
            ...fetchConfig,
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                hasCameoPokemon,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to complete featured card');
        } else {
            toast.success('Card marked as complete');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while completing the featured card');
        return { success: false };
    }
};

export const completeCameoCard = async (
    authParams: any,
    cardId: number,
    isCameoComplete: boolean
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch(`${config.API_URL}/admin/completeCameoCard`, {
            method: 'POST',
            ...fetchConfig,
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                isCameoComplete,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to complete cameo card');
        } else {
            toast.success('Card marked as complete');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while completing the cameo card');
        return { success: false };
    }
};

export const removeFeaturedPokemon = async (
    authParams: any,
    cardId: number,
    pokemonId: number
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch(`${config.API_URL}/admin/removeFeaturedPokemon`, {
            method: 'POST',
            ...fetchConfig,
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                pokemonId,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to remove featured Pokemon');
        } else {
            toast.success('Featured Pokemon removed successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while removing featured Pokemon');
        return { success: false };
    }
};

export const removeCameoPokemon = async (
    authParams: any,
    cardId: number,
    pokemonId: number
): Promise<AdminApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch(`${config.API_URL}/admin/removeCameoPokemon`, {
            method: 'POST',
            ...fetchConfig,
            body: JSON.stringify({
                ...stringAuthParams,
                cardId,
                pokemonId,
            }),
        });

        const result: AdminApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to remove cameo Pokemon');
        } else {
            toast.success('Cameo Pokemon removed successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while removing cameo Pokemon');
        return { success: false };
    }
};

export const getApplicationRoutes = async (authParams: any): Promise<GetRoutesResponse> => {
    try {
        let queryString = '';
        
        if (authParams && Object.keys(authParams).length > 0) {
            const stringAuthParams = Object.fromEntries(
                Object.entries(authParams).map(([key, value]) => [key, String(value)])
            );
            queryString = `?${new URLSearchParams(stringAuthParams).toString()}`;
        }
        
        const response = await fetch(`${config.API_URL}/admin/routes${queryString}`, {
            ...fetchConfig,
            method: 'GET'
        });
        
        const result: GetRoutesResponse = await response.json();
        
        if (!result.success) {
            console.warn('Failed to fetch application routes:', result.message);
        }
        return result;
    } catch (error) {
        console.error('Error in getApplicationRoutes:', error);
        return { 
            success: false,
            routes: []
        };
    }
};

export const getPKROUTES = async (authParams: any): Promise<GetPKRoutesResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`${config.API_URL}/admin/pk-routes?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: GetPKRoutesResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch routes');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching routes');
        return {
            success: false,
            routes: []
        };
    }
};

export const updatePKROUTE = async (
    authParams: any,
    route: PKRoute
): Promise<UpdatePKRouteResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`${config.API_URL}/admin/pk-routes/${route.route_id}?${queryParams}`, {
            method: 'PUT',
            ...fetchConfig,
            body: JSON.stringify(route)
        });

        const result: UpdatePKRouteResponse = await response.json();

        if (!result.success) {
            toast.error(result.message || 'Failed to update route');
        } else {
            toast.success('Route updated successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating the route');
        return { 
            success: false,
            message: 'Failed to update route'
        };
    }
};

export const getPokemonSets = async (
    authParams: any, 
    includeStats?: boolean, 
    statsType?: 'category' | 'featured' | 'cameo'
): Promise<GetPokemonSetsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
            ...(includeStats && { includeStats: 'true' }),
            ...(statsType && { statsType })
        }).toString();
        
        const response = await fetch(`${config.API_URL}/admin/pokemon-sets?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: GetPokemonSetsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch Pokemon sets');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching Pokemon sets');
        return {
            success: false,
            sets: []
        };
    }
};

export const updatePokemonSet = async (
    authParams: any,
    set: PokemonSet
): Promise<UpdatePokemonSetResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`${config.API_URL}/admin/pokemon-sets/${set.id}?${queryParams}`, {
            method: 'PUT',
            ...fetchConfig,
            body: JSON.stringify(set)
        });

        const result: UpdatePokemonSetResponse = await response.json();

        if (!result.success) {
            toast.error(result.message || 'Failed to update Pokemon set');
        } else {
            toast.success('Pokemon set updated successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating the Pokemon set');
        return { 
            success: false,
            message: 'Failed to update Pokemon set'
        };
    }
};

// Add a function to clear the cache if needed
export const clearRoutesCache = () => {
    routesCache = {
        data: undefined,
        timestamp: 0,
        promise: null
    };
};

// New API functions for set statistics
export const getSetCategoryStats = async (authParams: any): Promise<GetSetCategoryStatsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`${config.API_URL}/admin/set-category-stats?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: GetSetCategoryStatsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch set category statistics');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching set category statistics');
        return {
            success: false,
            stats: []
        };
    }
};

export const getSetFeaturedStats = async (authParams: any): Promise<GetSetFeaturedStatsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`${config.API_URL}/admin/set-featured-stats?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: GetSetFeaturedStatsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch set featured statistics');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching set featured statistics');
        return {
            success: false,
            stats: []
        };
    }
};

export const getSetCameoStats = async (authParams: any): Promise<GetSetCameoStatsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const response = await fetch(`${config.API_URL}/admin/set-cameo-stats?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: GetSetCameoStatsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch set cameo statistics');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching set cameo statistics');
        return {
            success: false,
            stats: []
        };
    }
};

export const getCardSetOrder = async (authParams: AuthParams, setId: number) => {
    console.log(`[getCardSetOrder] Starting request for set ID: ${setId}`);
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        console.log(`[getCardSetOrder] Making request to: ${config.API_URL}/admin/getCardIndex?${queryParams}`);
        
        const response = await fetch(`${config.API_URL}/admin/getCardIndex?${queryParams}`, {
            ...fetchConfig,
            method: 'POST',
            body: JSON.stringify({ setId })
        });
        
        const result = await response.json();
        console.log(`[getCardSetOrder] Response received:`, result);
        
        if (!result.success) {
            console.error(`[getCardSetOrder] Request failed:`, result);
            toast.error('Failed to fetch card set order');
        }
        
        return result;
    } catch (error) {
        console.error('[getCardSetOrder] Error:', error);
        toast.error('An error occurred while fetching card set order');
        return { success: false, cards: [] };
    }
};

export const updateCardSetOrder = async (authParams: AuthParams, setId: number, cards: any[]) => {
    console.log(`[updateCardSetOrder] Starting request for set ID: ${setId}`);
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        
        // Handle single card update vs. multiple cards
        if (cards.length === 1) {
            // For single card update, use the updateCardIndex endpoint
            const card = cards[0];
            console.log(`[updateCardSetOrder] Making single card update request to: ${config.API_URL}/admin/updateCardIndex?${queryParams}`);
            
            const response = await fetch(`${config.API_URL}/admin/updateCardIndex?${queryParams}`, {
                method: 'PUT',
                ...fetchConfig,
                body: JSON.stringify({ 
                    cardId: card.id,
                    newIndex: card.cardsetindex
                })
            });
            
            const result = await response.json();
            console.log(`[updateCardSetOrder] Response received:`, result);
            
            if (!result.success) {
                console.error(`[updateCardSetOrder] Request failed:`, result);
                toast.error('Failed to update card index');
            } else {
                toast.success('Card index updated successfully');
            }
            
            return result;
        } else {
            // For multiple cards, use the old endpoint (this code path should not be reached anymore)
            console.warn(`[updateCardSetOrder] Multiple card update requested but endpoint has been removed`);
            return { success: false, message: "Multiple card updates are no longer supported" };
        }
    } catch (error) {
        console.error('[updateCardSetOrder] Error:', error);
        toast.error('An error occurred while updating card index');
        return { success: false };
    }
};

/**
 * Updates a batch of card indexes efficiently by making multiple small API calls
 * @param authParams Authentication parameters
 * @param cards Array of cards with updated cardsetindex values
 * @param batchSize Number of cards to update in each API call
 * @param onProgress Progress callback function
 * @returns Object with success status and message
 */
export const batchUpdateCardIndexes = async (
    authParams: AuthParams, 
    cards: {
        id: number;
        cardsetindex: number;
        [key: string]: any;
    }[],
    batchSize: number = 5,
    onProgress?: (progress: number) => void
): Promise<{success: boolean, message?: string, totalUpdated?: number}> => {
    console.log(`[batchUpdateCardIndexes] Starting batch update for ${cards.length} cards`);
    
    if (!cards.length) {
        return { success: true, message: "No cards to update", totalUpdated: 0 };
    }
    
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();
        const batches = Math.ceil(cards.length / batchSize);
        let successCount = 0;
        
        console.log(`[batchUpdateCardIndexes] Processing ${batches} batches with batch size of ${batchSize}`);
        
        for (let i = 0; i < batches; i++) {
            const startIdx = i * batchSize;
            const endIdx = Math.min(startIdx + batchSize, cards.length);
            const batchCards = cards.slice(startIdx, endIdx);
            
            console.log(`[batchUpdateCardIndexes] Processing batch ${i + 1}/${batches} with ${batchCards.length} cards`);
            
            // Process each card in the batch sequentially
            for (const card of batchCards) {
                const payload = {
                    cardId: card.id,
                    newIndex: card.cardsetindex
                };
                
                console.log(`[batchUpdateCardIndexes] Updating card ID ${card.id} to index ${card.cardsetindex}`);
                
                const response = await fetch(`${config.API_URL}/admin/updateCardIndex?${queryParams}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify(payload)
                });
                
                const result = await response.json();
                if (result.success) {
                    successCount++;
                } else {
                    console.error(`[batchUpdateCardIndexes] Failed to update card ID ${card.id}:`, result);
                }
                
                // Call progress callback if provided
                if (onProgress) {
                    onProgress(Math.round((successCount / cards.length) * 100));
                }
            }
        }
        
        console.log(`[batchUpdateCardIndexes] Completed batch update. Updated ${successCount}/${cards.length} cards`);
        
        return { 
            success: successCount > 0, 
            message: successCount === cards.length 
                ? `Successfully updated all ${cards.length} cards` 
                : `Updated ${successCount} of ${cards.length} cards`,
            totalUpdated: successCount
        };
    } catch (error) {
        console.error('[batchUpdateCardIndexes] Error:', error);
        return { success: false, message: "An error occurred during batch update", totalUpdated: 0 };
    }
};

// Add more admin API functions here as needed
