import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAdminFunctions } from '../../../../hooks/useAdminFunctions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import RouteManagerHeader from './RouteManagerHeader';
import RouteManagerTable from './RouteManagerTable';
import './RouteManager.css';

export interface PKRoute {
    route_id: number;
    parent_route_id: number | null;
    path: string;
    route_name: string;
    is_index: boolean;
    component_name: string;
    state: string;
    tcg_state: string;
    is_beta: boolean;
    is_active: boolean;
    is_hidden: boolean;
    requires_auth: boolean;
    requires_admin: boolean;
    sidebar_props: string | null;
    display_order: number;
    is_menu: boolean;
    is_set: boolean;
    pokemon_set_id: number | null;
    display_name: string;
    icon: string | null;
}

const RouteManager: React.FC = () => {
    const [routes, setRoutes] = useState<PKRoute[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const { getPKROUTES, updatePKROUTE } = useAdminFunctions();

    useEffect(() => {
        const fetchRoutes = async () => {
            try {
                setIsLoading(true);
                const fetchedRoutes = await getPKROUTES();
                // console.log('Fetched routes:', fetchedRoutes);
                if (Array.isArray(fetchedRoutes)) {
                    setRoutes(fetchedRoutes as PKRoute[]);
                } else {
                    setRoutes([]);
                }
            } catch (error) {
                console.error('Error fetching routes:', error);
                setRoutes([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchRoutes();
    }, [getPKROUTES]);

    const handleRouteUpdate = async (updatedRoute: PKRoute) => {
        try {
            const success = await updatePKROUTE(updatedRoute);
            if (success) {
                setRoutes(routes.map(route => 
                    route.route_id === updatedRoute.route_id ? updatedRoute : route
                ));
            }
        } catch (error) {
            console.error('Error updating route:', error);
        }
    };

    const filteredRoutes = routes.filter(route => 
        route.path.toLowerCase().includes(searchTerm.toLowerCase()) ||
        route.route_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        route.display_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        route.component_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Box sx={{ p: 2 }}>
            <RouteManagerHeader 
                totalRoutes={routes.length} 
                searchTerm={searchTerm}
                onSearchChange={setSearchTerm}
            />
            <RouteManagerTable 
                routes={filteredRoutes} 
                onRouteUpdate={handleRouteUpdate}
            />
        </Box>
    );
};

export default RouteManager; 