// React and related imports
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';

// Material-UI
import { useMediaQuery, Fab, useScrollTrigger, Zoom, ToggleButtonGroup, ToggleButton, Grid, Paper, Typography, Box, Tooltip, Switch } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FilterBAndWIcon from '@mui/icons-material/FilterBAndW';
import InfoIcon from '@mui/icons-material/Info';

// Third-party libraries
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast } from 'react-toastify';

// Custom hooks and contexts
import { useAuth } from '../../../context/AuthContext';
import { useSetSealedProducts } from '../../../hooks/useSetSealedProducts';

// Utility functions and data
import {
    handleAPIResult,
    addCardToCollection,
    removeCardFromCollection,
} from '../../../components/common/reuseablethings';
import assets from '../../../assets';
import defaultCards from '../../../configs/default_cards';
import { defaultTypes } from '../../../data/defaultTypes';
import { defaultRarities } from '../../../data/defaultRarities';

// Component imports
import CardTemplate from './CardTemplate';
import DesktopFilterLayout from './DesktopFilterLayout';
import MobileFilterLayout from './MobileFilterLayout';
import DesktopProgressDisplay from './DesktopProgressDisplay';
import MobileProgressDisplay from './MobileProgressDisplay';
import DesktopSetInfoHeader from './DesktopSetInfoHeader';
import MobileSetInfoHeader from './MobileSetInfoHeader';
import ToggleButtons, { ToggleButtonsProps } from '../../../components/ToggleButtons';
import DesktopSetCardDisplay from './DesktopSetCardDisplay';
import MobileSetCardDisplay from './MobileSetCardDisplay';
import TableCardDisplay from './TableCardDisplay';
import BinderCardDisplay from './BinderCardDisplay';
import SealedProductsPanel from '../../../components/SealedProductsPanel';
import SealedProductModal from '../../../components/SealedProductModal';

// Styles
import '../../../css/filterSection.css';
import config from '../../../routes/config';

// Update import to point to the correct API file
import { incrementCardInventory, decrementCardInventory, getUserCardInventory } from '../../../api/cardApi';

// Create more targeted CSS that only affects our specific card components
const cardStyles = `
    /* Target only our specific card containers */
    .desktop-set-cards-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
        padding: 20px;
    }
    
    /* Card container styles */
    .desk-card-container .MuiPaper-root {
        width: 100%;
        border-radius: 12px;
        overflow: hidden;
        box-sizing: border-box;
    }
    
    /* Card box styles - only target those inside our containers */
    .desk-card-container .desktop-card-box,
    .desk-card-container .mobile-card-box {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        align-items: stretch;
        margin: 0;
    }
    
    /* Image container styles */
    .desk-card-container .card-image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: #ffffff;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        overflow: visible;
    }
    
    /* Image styles */
    .desk-card-container .card-image {
        max-width: 85%;
        max-height: 100%;
        width: auto;
        height: auto;
        object-fit: contain;
        display: block;
        margin: 10px auto;
        padding: 0;
        border-radius: 10px;
    }
    
    /* Info section styles */
    .desk-card-container .card-info-section {
        padding: 10px;
        background-color: #ffffff;
        border-top: 1px solid #e9ecef;
        width: 100%;
        box-sizing: border-box;
    }
    
    /* Name and price row */
    .desk-card-container .card-name-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        width: 100%;
    }
    
    /* Card name */
    .desk-card-container .card-name {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 70%;
    }
    
    /* Card price */
    .desk-card-container .card-price {
        font-weight: bold;
        color: #2e7d32;
        text-align: right;
    }
    
    .desk-card-container .card-price p {
        margin: 0;
        padding: 0;
    }
    
    /* Number and pokeball row */
    .desk-card-container .card-number-pokeball {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    
    /* Card number */
    .desk-card-container .card-number {
        display: block;
    }
    
    .desk-card-container .card-number p {
        margin: 0;
        font-size: 14px;
        color: #6c757d;
        position: static;
    }
    
    /* Pokeball container */
    .desk-card-container .pokeball-container {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
    }
    
    /* Pokeball image */
    .desk-card-container .desktop-pokeball,
    .desk-card-container .mobile-pokeball {
        width: 24px;
        height: 24px;
        transition: transform 0.2s ease;
        position: static;
    }
    
    .desk-card-container .desktop-pokeball:hover,
    .desk-card-container .mobile-pokeball:hover {
        transform: scale(1.2);
    }
    
    /* Fix for lazy load image background */
    .desk-card-container .lazy-load-image-background {
        display: block;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        overflow: visible;
    }
    
    .desk-card-container .lazy-load-image-background.blur {
        overflow: visible;
    }
    
    .desk-card-container .lazy-load-image-background.blur img {
        max-width: 85%;
        margin: 0 auto;
    }
    
    /* Image wrapper styles for consistent sizing */
    .desk-card-container .image-wrapper {
        display: block;
        width: 100%;
        min-height: 200px;
        position: relative;
    }
    
    /* Ensure placeholder images maintain the same dimensions */
    .desk-card-container .lazy-load-image-background.blur {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
`;

interface SetInfo {
    id: number;
    tcgplayerlink: any;
    type: string;
    setName: string;
    setSeries: string;
    setCardCounts: {
        numberOfCards: number;
        mainSetWithSecretRares: number;
        setNumber: number;
        numOfReverseSet: number;
        numberOfSubSet: number;
        pr_first_edition_count?: number;
        pr_shadowless_count?: number;
        pr_unlimited_count?: number;
        pr_fourth_print_count?: number;
    };
    showPriceData: boolean;
    releaseDate: string;
    showAllCards: boolean;
    logo: string;
    icon: string;
    setPrintRuns?: {
        firstEditionSet?: {
            buttonDisplay: string;
            description: string;
            count: number;
        };
        shadowlessSet?: {
            buttonDisplay: string;
            description: string;
            count: number;
        };
        unlimitedEditionSet?: {
            buttonDisplay: string;
            description: string;
            count: number;
        };
        fourthPrintSet?: {
            buttonDisplay: string;
            description: string;
            count: number;
        };
    };
    includesReverseHolographicCards?: boolean;
}

interface Props {
    setInfo: SetInfo;
}

type CheckBoxValues = {
    have: boolean;
    need: boolean;
    rarity: { [key: number]: boolean };
    type: { [key: number]: boolean };
};

// Define a Card type to avoid using 'any'
interface Card {
    id: number;
    cardsetindex: number;
    number: number;
    name: string;
    imagelocation: any;
    isCardInCollection: boolean;
    market_price: number;
    isReverseHolo: boolean;
    isPartOfSubSet: boolean;
    editionId: number;
    rarityId: number;
    type_id: number;
    quantity: number;
}

function ScrollTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Fab
                onClick={handleClick}
                size="small"
                aria-label="scroll back to top"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
}

/**
 * EnglishTemplate component displays a Pokemon card set with various viewing options and filters
 * @component
 * @param {Object} props - Component props
 * @param {SetInfo} props.setInfo - Information about the Pokemon card set
 * @returns {JSX.Element} Rendered component
 */
const EnglishTemplate: React.FC<Props> = ({ setInfo }) => {
    ReactGA.initialize('G-677WMVXWLS');
    const location = useLocation();

    const { authParams, isAuthenticated } = useAuth();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width:789px)');
    const printRun = useSelector((state: RootState) => state.printRun);
    const TCGState = useSelector((state: RootState) => state.TCGState);

    const [showCardTemplate, setShowCardTemplate] = useState<boolean>(false);
    const [selectedCard, setSelectedCard] = useState<any>(null);
    const [currentView, setCurrentView] = useState('Unlimited');
    const [cards, setCards] = useState<Card[]>(defaultCards.map(card => ({
        ...card,
        quantity: 0
    })));
    const [checkBoxValues, setCheckBoxValues] = useState<CheckBoxValues>({
        have: false,
        need: false,
        rarity: {},
        type: {},
    });
    
    // Add state for sealed products
    const [showSealedProducts, setShowSealedProducts] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
    
    // Fetch sealed products data using the custom hook
    const { 
        data: sealedProducts, 
        isLoading: sealedProductsLoading, 
        error: sealedProductsError 
    } = useSetSealedProducts(setInfo);

    // Sort cards using useMemo to avoid unnecessary re-sorting
    const sortedCards = useMemo(() => {
        return [...cards].sort((a, b) => a.cardsetindex - b.cardsetindex);
    }, [cards]);

    // Calculate collection statistics
    const collectedCards = cards.filter((card: Card) => card.isCardInCollection).length;
    const collectedMainSet = cards.filter((card: Card) => card.isCardInCollection && !card.isReverseHolo && !card.isPartOfSubSet).length;
    const collectedReverseHoloSet = cards.filter((card: Card) => card.isCardInCollection && card.isReverseHolo && !card.isPartOfSubSet).length;
    const collectedSubSet = cards.filter((card: Card) => card.isCardInCollection && card.isPartOfSubSet).length;
    const collectedFirstEditionSet = cards.filter((card: Card) => card.isCardInCollection && card.editionId === 1).length;
    const collectedShadowlessSet = cards.filter((card: Card) => card.isCardInCollection && card.editionId === 2).length;
    const collectedUnlimitedSet = cards.filter((card: Card) => card.isCardInCollection && card.editionId === 3).length;
    const collectedFourthPrint = cards.filter((card: Card) => card.isCardInCollection && card.editionId === 4).length;

    // Update cardsToDisplay to use sortedCards
    let cardsToDisplay = sortedCards;

    // Use optional chaining for all setInfo access
    const type = setInfo?.type || 'PokemonCompany';
    const setName = setInfo?.setName || '';
    const seriesName = setInfo?.setSeries || '';
    const mainSet = setInfo?.setCardCounts?.numberOfCards ?? 0;
    const mainSetWithSecretRares = setInfo?.setCardCounts?.mainSetWithSecretRares ?? 0;
    const reverseSet = setInfo?.setCardCounts?.numOfReverseSet ?? 0;
    const subSet = setInfo?.setCardCounts?.numberOfSubSet ?? 0;

    const [showFirstEdition, showShadowlessSet, showfourthPrintSet] = [
        Boolean(setInfo?.setPrintRuns?.firstEditionSet),
        Boolean(setInfo?.setPrintRuns?.shadowlessSet),
        Boolean(setInfo?.setPrintRuns?.fourthPrintSet)
    ];

    const firstEditionSet = showFirstEdition 
        ? setInfo?.setCardCounts?.pr_first_edition_count ?? 0
        : 0;

    const shadowlessSet = showShadowlessSet
        ? setInfo?.setCardCounts?.pr_shadowless_count ?? 0
        : 0;

    const unlimitedSet = setInfo?.setPrintRuns?.unlimitedEditionSet
        ? setInfo?.setCardCounts?.pr_unlimited_count ?? 0
        : 0;

    const fourthPrintSet = showfourthPrintSet
        ? setInfo.setCardCounts?.pr_fourth_print_count ?? 0
        : 0;
        
    let totalSet = 0;

    const handleCheckBoxChange =
        (section: 'have' | 'need' | 'rarity' | 'type', key: string) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCheckBoxValues((prevValues) => {
                if (section === 'have' || section === 'need') {
                    return {
                        ...prevValues,
                        [section]: event.target.checked,
                    };
                } else {
                    return {
                        ...prevValues,
                        [section]: {
                            ...prevValues[section],
                            [key]: event.target.checked,
                        },
                    };
                }
            });
        };

    const uniqueRarities = useMemo(() => {
        return Array.from(new Set(cards.map((card) => card.rarityId)));
    }, [cards]);

    const uniqueTypes = useMemo(() => {
        const types = new Set(cards.map((card) => card.type_id));
        if (cards.some((card) => card.type_id === 0 || card.type_id === undefined)) {
            types.add(0);
        }
        return Array.from(types);
    }, [cards]);

    const changeCardSelectedStatus = (card: any) => {
        // Create a copy of the card first
        const cardCopy = { ...card };
        
        // Store the original card ID
        const cardId = cardCopy.id;
        
        // Merge setInfo into the card data
        let cardData = { ...cardCopy, ...setInfo };
        
        // Restore the original card ID
        cardData.id = cardId;
        
        setSelectedCard(cardData);
        setShowCardTemplate(true);
    };

    const normaliseFirstEditionSet = (value: number) => ((value - 0) * 100) / (firstEditionSet - 0);
    const normaliseShadowlessSet = (value: number) => ((value - 0) * 100) / (shadowlessSet - 0);
    const normaliseUnlimitedSet = (value: number) => ((value - 0) * 100) / (unlimitedSet - 0);
    const normaliseFourthPrint = (value: number) => ((value - 0) * 100) / (fourthPrintSet - 0);

    let normaliseTotal;

    if (type === 'WOTC') {
        totalSet =
            firstEditionSet + shadowlessSet + unlimitedSet + fourthPrintSet + reverseSet + subSet;
        normaliseTotal = (value: number) => ((value - 0) * 100) / (totalSet - 0);
    } else {
        totalSet = mainSet + reverseSet + subSet;
        normaliseTotal = (value: number) => ((value - 0) * 100) / (totalSet - 0);
    }

    useEffect(() => {
        setCurrentView('Unlimited');
        setCheckBoxValues({
            have: false,
            need: false,
            rarity: Object.fromEntries(defaultRarities.map((rarity) => [rarity.id, false])),
            type: Object.fromEntries(defaultTypes.map((type) => [type.id, false])),
        });

        return () => {
            // Code to run when the component is unmounted (optional)
        };
    }, [location.pathname]);

    let showReverseSet = reverseSet > 0 ? true : false;
    let showSubSet = subSet > 0 ? true : false;

    const normaliseMainSet = (value: number) => ((value - 0) * 100) / (mainSet - 0);
    const normaliseReverseSet = (value: number) => ((value - 0) * 100) / (reverseSet - 0);
    const normaliseSubSet = (value: number) => ((value - 0) * 100) / (subSet - 0);

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: 'Set Beta' });
        dispatch({ type: 'SET_PRINTRUN', payload: 'Unlimited' });
        dispatch({ type: 'SET_SETNAME', payload: setName });
        dispatch({ type: 'SET_SERIESNAME', payload: seriesName });
        dispatch({ 
            type: 'SET_SETNUMBEROFCARDS', 
            payload: `${setInfo?.setCardCounts?.setNumber ?? 0}` 
        });
        document.title = `MyPokellection | ${TCGState} | ${seriesName} | ${setName} Set`;
    }, [
        dispatch, 
        setName, 
        seriesName, 
        setInfo?.setCardCounts?.setNumber, 
        TCGState
    ]);

    useEffect(() => {
        let numUserCardsInCollection = cards.filter(
            (card) => card.isCardInCollection === true
        ).length;
        dispatch({ type: 'SET_USERSETCOLLECTEDCARDS', payload: numUserCardsInCollection });
    }, [cards, dispatch]);

    useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
    }, [dispatch]);

    useEffect(() => {
        const queryString = new URLSearchParams({
            setName,
            seriesName,
            TCGState,
        }).toString();
        fetch(`${config.API_URL}/getPokemonCards/?${queryString}`)
            .then((response) => response.json())
            .then((data) => {
                const sortedCards = data.sort(
                    (a: { cardsetindex: number }, b: { cardsetindex: number }) =>
                        a.cardsetindex - b.cardsetindex
                );
                setCards(sortedCards.map((card: any) => ({ ...card, isCardInCollection: false })));
            })
            .then(() => {
                if (isAuthenticated && authParams) {
                    const stringAuthParams = Object.fromEntries(
                        Object.entries(authParams).map(([key, value]) => [key, String(value)])
                    );

                    const queryParams = new URLSearchParams(stringAuthParams).toString();

                    const authParamsObj = Object.fromEntries(new URLSearchParams(queryParams));

                    const requestBody = {
                        ...authParamsObj,
                        setName,
                        seriesName,
                        TCGState,
                        printRun,
                    };

                    const queryString = new URLSearchParams(requestBody).toString();
                    fetch(`${config.API_URL}/getUserPokemonCards?${queryString}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((data: { id: number; quantity: number }[]) => {    
                            const updatedCards = data.map((card) => ({
                                ...card,
                                isCardInCollection: true,
                                quantity: card.quantity,
                            }));
                            setCards((prevCards) => {
                                const newCards = prevCards.map((c) => {
                                    const updatedCard = updatedCards.find((uc) => uc.id === c.id);
                                    return updatedCard
                                        ? {
                                              ...c,
                                              isCardInCollection: updatedCard.isCardInCollection,
                                              quantity: updatedCard.quantity,
                                          }
                                        : {
                                              ...c,
                                              quantity: 0
                                          };
                                });
                                return newCards;
                            });
                        })
                        .catch((error) => {
                            handleAPIResult({
                                success: false,
                                failed: true,
                                message: 'Failed: Unable to retrieve user cards',
                            });
                        });
                }
            })
            .catch((error) => console.error('Error in outer fetch:', error));
    }, [isAuthenticated, authParams, printRun, TCGState, seriesName, setName, setInfo]);

    useEffect(() => {
        // Add this block to update rarity checkboxes
        const uniqueRarities = Array.from(
            new Set(cards.map((card) => card.rarityId?.toString() || '').filter(Boolean))
        );
        setCheckBoxValues((prev) => ({
            ...prev,
            rarity: Object.fromEntries(uniqueRarities.map((rarityId) => [rarityId, false])),
        }));
    }, [cards]);

    // Modified handleToggle function without scroll-to-top action
    const handleToggle = useCallback((event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        if (currentView !== newAlignment) {

            // Always hide sealed products when switching to a card view
            if (newAlignment !== 'sealed-products') {
                setShowSealedProducts(false);
            }

            // Log what should be filtered based on the conditions
            if (type === 'WOTC' && setInfo?.showAllCards !== true) {
                const editionMap: Record<string, number> = {
                    'First Edition': 1,
                    'Shadowless': 2,
                    'Unlimited': 3,
                    '4th Print': 4
                };
                
                const expectedCount = newAlignment === 'All Cards' 
                    ? sortedCards.length
                    : sortedCards.filter(card => card.editionId === editionMap[newAlignment]).length;
            } else {
                const filterConditions = {
                    'Unlimited': 'Not reverse holo and not part of subset',
                    'Reverse Set': 'Is reverse holo and not part of subset',
                    'Sub-Set': 'Is part of subset',
                    'All Cards': 'No filters - showing all cards'
                };

                const expectedCount = (() => {
                    switch(newAlignment) {
                        case 'Unlimited':
                            return sortedCards.filter(card => !card.isReverseHolo && !card.isPartOfSubSet).length;
                        case 'Reverse Set':
                            return sortedCards.filter(card => card.isReverseHolo && !card.isPartOfSubSet).length;
                        case 'Sub-Set':
                            return sortedCards.filter(card => card.isPartOfSubSet).length;
                        case 'All Cards':
                            return sortedCards.length;
                        default:
                            return 0;
                    }
                })();
            }

            setCurrentView(newAlignment);
        }
    }, [currentView, showSealedProducts, setShowSealedProducts, setCurrentView, type, setInfo?.showAllCards, sortedCards]);

    // Add debug logging for filter calculation
    useEffect(() => {
        // Log after view filter is applied
        const afterViewFilter = (() => {
            let filtered = [...sortedCards];
            
            if (type === 'WOTC' && setInfo?.showAllCards !== true) {
                if (currentView === 'First Edition') {
                    filtered = filtered.filter((card: Card) => card.editionId === 1);
                } else if (currentView === 'Shadowless') {
                    filtered = filtered.filter((card: Card) => card.editionId === 2);
                } else if (currentView === 'Unlimited') {
                    if (setInfo?.includesReverseHolographicCards === true) {
                        filtered = filtered.filter((card: Card) => card.editionId === 3 && !card.isReverseHolo && !card.isPartOfSubSet);
                    } else {
                        filtered = filtered.filter((card: Card) => card.editionId === 3 && !card.isPartOfSubSet);
                    }
                } else if (currentView === '4th Print') {
                    filtered = filtered.filter((card: Card) => card.editionId === 4);
                } else if (currentView === 'Reverse Set') {
                    if (setInfo?.includesReverseHolographicCards === true) {
                        filtered = filtered.filter((card: Card) => card.editionId === 3 && card.isReverseHolo && !card.isPartOfSubSet);
                    } else {
                        filtered = filtered.filter((card: Card) => card.editionId === 3 && !card.isPartOfSubSet);
                    }
                } else if (currentView === 'Sub-Set') {
                    filtered = filtered.filter((card: Card) => card.editionId === 3 && card.isPartOfSubSet);
                } else if (currentView === 'All Cards') {
                    // Don't apply any filters when showing all cards
                    filtered = sortedCards;
                }
            } else {
                if (currentView === 'Unlimited') {
                    filtered = filtered.filter((card: Card) => !card.isReverseHolo && !card.isPartOfSubSet);
                } else if (currentView === 'Reverse Set') {
                    filtered = filtered.filter((card: Card) => card.isReverseHolo && !card.isPartOfSubSet);
                } else if (currentView === 'Sub-Set') {
                    filtered = filtered.filter((card: Card) => card.isPartOfSubSet);
                } else if (currentView === 'All Cards') {
                    // Don't apply any filters when showing all cards
                    filtered = sortedCards;
                }
            }
            
            return filtered;
        })();
    }, [currentView, sortedCards, type, setInfo, checkBoxValues]);

    if (type === 'WOTC' && setInfo?.showAllCards !== true) {
        if (currentView === 'First Edition') {
            cardsToDisplay = cardsToDisplay.filter((card: Card) => card.editionId === 1);
        } else if (currentView === 'Shadowless') {
            cardsToDisplay = cardsToDisplay.filter((card: Card) => card.editionId === 2);
        } else if (currentView === 'Unlimited') {
            if (setInfo?.includesReverseHolographicCards === true) {
                cardsToDisplay = cardsToDisplay.filter((card: Card) => card.editionId === 3 && !card.isReverseHolo && !card.isPartOfSubSet);
            } else {
                cardsToDisplay = cardsToDisplay.filter((card: Card) => card.editionId === 3 && !card.isPartOfSubSet);
            }
        } else if (currentView === '4th Print') {
            cardsToDisplay = cardsToDisplay.filter((card: Card) => card.editionId === 4);
        } else if (currentView === 'Reverse Set') {
            if (setInfo?.includesReverseHolographicCards === true) {
                cardsToDisplay = cardsToDisplay.filter((card: Card) => card.editionId === 3 && card.isReverseHolo && !card.isPartOfSubSet);
            } else {
                cardsToDisplay = cardsToDisplay.filter((card: Card) => card.editionId === 3 && !card.isPartOfSubSet);
            }
        } else if (currentView === 'Sub-Set') {
            cardsToDisplay = cardsToDisplay.filter((card: Card) => card.editionId === 3 && card.isPartOfSubSet);
        }
    } else if (setInfo?.showAllCards === true) {
        if (currentView === 'Unlimited') {
            cardsToDisplay = cardsToDisplay.filter((card: Card) => !card.isReverseHolo && !card.isPartOfSubSet);
        } else if (currentView === 'Reverse Set') {
            cardsToDisplay = cardsToDisplay.filter((card: Card) => card.isReverseHolo && !card.isPartOfSubSet);
        } else if (currentView === 'Sub-Set') {
            cardsToDisplay = cardsToDisplay.filter((card: Card) => card.isPartOfSubSet);
        } else if (currentView === 'All Cards') {
            // Don't apply any filters when showing all cards
            cardsToDisplay = sortedCards;
        }
    } else {
        if (currentView === 'Unlimited') {
            cardsToDisplay = cardsToDisplay.filter((card: Card) => !card.isReverseHolo && !card.isPartOfSubSet);
        } else if (currentView === 'Reverse Set') {
            cardsToDisplay = cardsToDisplay.filter((card: Card) => card.isReverseHolo && !card.isPartOfSubSet);
        } else if (currentView === 'Sub-Set') {
            cardsToDisplay = cardsToDisplay.filter((card: Card) => card.isPartOfSubSet);
        } else if (currentView === 'All Cards') {
            // Don't apply any filters when showing all cards
            cardsToDisplay = sortedCards;
        }
    }

    if (checkBoxValues.have && !checkBoxValues.need) {
        cardsToDisplay = cardsToDisplay.filter((card: Card) => card.isCardInCollection);
    } else if (checkBoxValues.need && !checkBoxValues.have) {
        cardsToDisplay = cardsToDisplay.filter((card: Card) => !card.isCardInCollection);
    }

    // Filter by rarity
    const selectedRarityIds = Object.entries(checkBoxValues.rarity)
        .filter(([_, isSelected]) => isSelected)
        .map(([rarityId]) => parseInt(rarityId));

    if (selectedRarityIds.length > 0) {
        cardsToDisplay = cardsToDisplay.filter((card: Card) => selectedRarityIds.includes(card.rarityId || 0));
    }

    // Filter by type
    const selectedTypeIds = Object.entries(checkBoxValues.type)
        .filter(([_, isSelected]) => isSelected)
        .map(([typeId]) => parseInt(typeId));

    if (selectedTypeIds.length > 0) {
        cardsToDisplay = cardsToDisplay.filter((card: Card) => selectedTypeIds.includes(card.type_id || 0));
    }

    const defaultLink = 'https://partner.tcgplayer.com/c/5054575/1780961/21018?u=https://www.tcgplayer.com/categories/trading-and-collectible-card-games/pokemon';
    let dynamicLink = '';
    if (setInfo?.tcgplayerlink) {
        dynamicLink = `https://partner.tcgplayer.com/c/5054575/1780961/21018?u=${encodeURIComponent(setInfo.tcgplayerlink)}`;
    } else {
        dynamicLink = defaultLink;
    }

    // Calculate the total filtered cards
    const totalFilteredCards = useMemo(() => {
        return cardsToDisplay.length;
    }, [cardsToDisplay]);

    useEffect(() => {
        // Add the styles to the document
        const styleElement = document.createElement('style');
        styleElement.innerHTML = cardStyles;
        document.head.appendChild(styleElement);
        
        // Clean up function
        return () => {
            document.head.removeChild(styleElement);
        };
    }, []);

    // Add this new state for view mode
    const [viewMode, setViewMode] = useState<'grid' | 'table' | 'binder'>('grid');
        
    // Add this handler for view mode toggle
    const handleViewModeChange = (
        event: React.MouseEvent<HTMLElement>,
        newViewMode: 'grid' | 'table' | 'binder' | null
    ) => {
        if (newViewMode !== null) {
            setViewMode(newViewMode);
        }
    };

    // Add grayscale mode state at the component level so it can be shared
    const [grayscaleMode, setGrayscaleMode] = useState(false);

    // Add state for inventory mode
    const [inventoryMode, setInventoryMode] = useState(false);
    
    // Update handleInventoryChange to work directly with card quantity
    const handleInventoryChange = useCallback(async (cardId: number, change: number) => {
        if (!isAuthenticated || !authParams) return;

        try {
            const stringAuthParams = Object.fromEntries(
                Object.entries(authParams).map(([key, value]) => [key, String(value)])
            );
            const queryParams = new URLSearchParams(stringAuthParams).toString();

            if (change > 0) {
                await incrementCardInventory(cardId.toString(), queryParams);
            } else {
                await decrementCardInventory(cardId.toString(), queryParams);
            }

            // Update the card's quantity in the local state
            setCards(prevCards => 
                prevCards.map(card => 
                    card.id === cardId 
                        ? { ...card, quantity: Math.max(0, card.quantity + change) }
                        : card
                )
            );
        } catch (error) {
            console.error('Error updating inventory:', error);
            toast.error('Failed to update card quantity');
        }
    }, [isAuthenticated, authParams]);

    // Update changeCardCollectionStatus to handle quantity properly
    const changeCardCollectionStatus = useCallback((cards: Card[], card: Card): void => {
        if (!isAuthenticated || !authParams) return;

        const cardToUpdate = cards.find((c) => c.id === card.id);
        if (!cardToUpdate) return;

        const updatedCard = {
            ...cardToUpdate,
            isCardInCollection: !cardToUpdate.isCardInCollection,
            quantity: !cardToUpdate.isCardInCollection ? 1 : 0,
        };
        
        // Update the cards state with the new collection status
        setCards(prevCards => 
            prevCards.map(c => c.id === card.id ? updatedCard : c)
        );

        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );
        const queryParams = new URLSearchParams(stringAuthParams).toString();

        if (!cardToUpdate.isCardInCollection) {
            addCardToCollection(cardToUpdate.id, queryParams);
        } else {
            removeCardFromCollection(cardToUpdate.id, queryParams);
        }
    }, [isAuthenticated, authParams]);

    // Add wrapper functions for components that expect a single card parameter
    const handleTableCardCollectionStatus = useCallback((card: Card): void => {
        changeCardCollectionStatus(cardsToDisplay, card);
    }, [changeCardCollectionStatus, cardsToDisplay]);

    const handleBinderCardCollectionStatus = useCallback((card: Card): void => {
        changeCardCollectionStatus(cardsToDisplay, card);
    }, [changeCardCollectionStatus, cardsToDisplay]);

    // Reset view when set changes - only depend on setInfo?.id
    useEffect(() => {
        // Always reset to these values when set changes
        setCurrentView('Unlimited');
        setShowSealedProducts(false);
        
        // Reset inventory mode when changing sets
        setInventoryMode(false);
    }, [setInfo]);

    // Initialize Google Analytics - moved before the conditional return
    useEffect(() => {
        ReactGA.initialize('UA-000000-01');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [setInfo]);

    // Handle sealed products toggle
    const handleSealedProductsToggle = () => {
        
        // Toggle sealed products view
        const newShowSealedProducts = !showSealedProducts;
        setShowSealedProducts(newShowSealedProducts);
        
        // If showing sealed products, set currentView to 'sealed-products'
        // If hiding sealed products, set currentView back to 'Unlimited'
        const newView = newShowSealedProducts ? 'sealed-products' : 'Unlimited';
        setCurrentView(newView);
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {showCardTemplate && selectedCard && (
                <CardTemplate cardInfo={selectedCard} onClose={() => setShowCardTemplate(false)} />
            )}
            
            {/* Sealed Product Modal */}
            {selectedProductId !== null && (
                <SealedProductModal
                    open={selectedProductId !== null}
                    onClose={() => setSelectedProductId(null)}
                    productId={selectedProductId}
                />
            )}

            {isMobile ? (
                <>
                    <div style={{ marginBottom: '5px' }}>
                        <MobileSetInfoHeader
                            setInfo={{
                                logo: setInfo?.logo || '',
                                icon: setInfo?.icon || '',
                                setName: setInfo?.setName,
                                releaseDate: setInfo?.releaseDate ?? '',
                                setSeries: setInfo?.setSeries,
                                tcgplayerlink: setInfo?.tcgplayerlink
                            }}
                            dynamicLink={dynamicLink}
                            assets={assets}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <MobileProgressDisplay
                            showFirstEdition={showFirstEdition}
                            showShadowlessSet={showShadowlessSet}
                            showfourthPrintSet={showfourthPrintSet}
                            showReverseSet={showReverseSet}
                            showSubSet={showSubSet}
                            type={type}
                            collectedFirstEditionSet={collectedFirstEditionSet}
                            firstEditionSet={setInfo?.setCardCounts?.pr_first_edition_count ?? 0}
                            collectedShadowlessSet={collectedShadowlessSet}
                            shadowlessSet={setInfo?.setCardCounts?.pr_shadowless_count ?? 0}
                            collectedUnlimitedSet={collectedUnlimitedSet}
                            unlimitedSet={setInfo?.setCardCounts?.pr_unlimited_count ?? 0}
                            collectedMainSet={collectedMainSet}
                            mainSet={mainSet}
                            collectedFourthPrint={collectedFourthPrint}
                            fourthPrintSet={setInfo?.setCardCounts?.pr_fourth_print_count ?? 0}
                            collectedReverseHoloSet={collectedReverseHoloSet}
                            reverseSet={reverseSet}
                            collectedSubSet={collectedSubSet}
                            subSet={subSet}
                            collectedCards={collectedCards}
                            totalSet={totalSet}
                            normaliseFirstEditionSet={normaliseFirstEditionSet}
                            normaliseShadowlessSet={normaliseShadowlessSet}
                            normaliseUnlimitedSet={normaliseUnlimitedSet}
                            normaliseMainSet={normaliseMainSet}
                            normaliseFourthPrint={normaliseFourthPrint}
                            normaliseReverseSet={normaliseReverseSet}
                            normaliseSubSet={normaliseSubSet}
                            normaliseTotal={normaliseTotal}
                            currentView={currentView}
                            mainSetWithSecretRares={mainSetWithSecretRares}
                            numberOfCards={setInfo?.setCardCounts?.numberOfCards}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <MobileFilterLayout
                            checkBoxValues={checkBoxValues}
                            handleCheckBoxChange={handleCheckBoxChange}
                            uniqueRarities={uniqueRarities}
                            uniqueTypes={uniqueTypes}
                            totalFilteredCards={totalFilteredCards}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div style={{ marginBottom: '5px' }}>
                        <DesktopSetInfoHeader
                            setInfo={{
                                logo: setInfo?.logo ?? '',
                                icon: setInfo?.icon ?? '',
                                setName: setInfo?.setName,
                                releaseDate: setInfo?.releaseDate ?? '',
                                setSeries: setInfo?.setSeries,
                                tcgplayerlink: setInfo?.tcgplayerlink
                            }}
                            dynamicLink={dynamicLink}
                            assets={assets}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <DesktopProgressDisplay
                            showFirstEdition={showFirstEdition}
                            showShadowlessSet={showShadowlessSet}
                            showfourthPrintSet={showfourthPrintSet}
                            showReverseSet={showReverseSet}
                            showSubSet={showSubSet}
                            type={type}
                            collectedFirstEditionSet={collectedFirstEditionSet}
                            firstEditionSet={setInfo?.setCardCounts?.pr_first_edition_count ?? 0}
                            collectedShadowlessSet={collectedShadowlessSet}
                            shadowlessSet={setInfo?.setCardCounts?.pr_shadowless_count ?? 0}
                            collectedUnlimitedSet={collectedUnlimitedSet}
                            unlimitedSet={setInfo?.setCardCounts?.pr_unlimited_count ?? 0}
                            collectedMainSet={collectedMainSet}
                            mainSet={mainSet}
                            setNumber={setInfo?.setCardCounts?.setNumber}   
                            mainSetWithSecretRares={mainSetWithSecretRares}
                            collectedFourthPrint={collectedFourthPrint}
                            fourthPrintSet={setInfo?.setCardCounts?.pr_fourth_print_count ?? 0}
                            collectedReverseHoloSet={collectedReverseHoloSet}
                            reverseSet={reverseSet}
                            collectedSubSet={collectedSubSet}
                            subSet={subSet}
                            collectedCards={collectedCards}
                            totalSet={totalSet}
                            numberOfCards={setInfo?.setCardCounts?.numberOfCards}
                            normaliseFirstEditionSet={normaliseFirstEditionSet}
                            normaliseShadowlessSet={normaliseShadowlessSet}
                            normaliseUnlimitedSet={normaliseUnlimitedSet}
                            normaliseMainSet={normaliseMainSet}
                            normaliseFourthPrint={normaliseFourthPrint}
                            normaliseReverseSet={normaliseReverseSet}
                            normaliseSubSet={normaliseSubSet}
                            normaliseTotal={normaliseTotal}
                            currentView={currentView}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <DesktopFilterLayout
                            checkBoxValues={checkBoxValues}
                            handleCheckBoxChange={handleCheckBoxChange}
                            uniqueRarities={uniqueRarities}
                            uniqueTypes={uniqueTypes}
                            totalFilteredCards={totalFilteredCards}
                        />
                    </div>
                </>
            )}

            <div style={{ marginBottom: '5px' }}>
                <ToggleButtons
                    setInfo={setInfo}
                    select={showSealedProducts ? "sealed-products" : currentView}
                    handleToggle={handleToggle}
                    showFirstEdition={showFirstEdition}
                    showShadowlessSet={showShadowlessSet}
                    showfourthPrintSet={showfourthPrintSet}
                    showReverseSet={showReverseSet}
                    showSubSet={showSubSet}
                    type={type}
                    onSealedProductsClick={handleSealedProductsToggle}
                    sealedProductsCount={sealedProducts?.length || 0}
                />
            </div>

            {/* Sealed Products Panel */}
            {showSealedProducts && (
                <Box sx={{ mb: 3 }}>
                    <SealedProductsPanel 
                        products={sealedProducts || []}
                        isLoading={sealedProductsLoading}
                        error={sealedProductsError}
                        onProductClick={(productId) => setSelectedProductId(productId)}
                    />
                </Box>
            )}

            {/* View mode and display options */}
            {!showSealedProducts && (
                <>
                    <Grid 
                        container 
                        justifyContent="center" 
                        alignItems="center" 
                        sx={{ mb: 3, mt: 1 }}
                    >
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            gap: 2, 
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'center'
                        }}>
                            {/* Combined controls in a single row with wrap */}
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // This ensures the Shadow Mode and Inventory Mode stay together
                                '& > .control-group': {
                                    display: 'flex',
                                    gap: 2,
                                    flexWrap: 'nowrap'
                                }
                            }}>
                                {/* Primary view mode controls */}
                                <Paper 
                                    elevation={2} 
                                    sx={{ 
                                        borderRadius: '8px', 
                                        overflow: 'hidden',
                                        display: 'inline-flex',
                                        backgroundColor: '#f5f5f5'
                                    }}
                                >
                                    <ToggleButtonGroup
                                        value={viewMode}
                                        exclusive
                                        onChange={handleViewModeChange}
                                        aria-label="view mode"
                                        size="medium"
                                        sx={{ 
                                            '& .MuiToggleButton-root': {
                                                px: 3,
                                                py: 1.5,
                                                borderRadius: 0,
                                                borderColor: '#e0e0e0',
                                                '&.Mui-selected': {
                                                    backgroundColor: '#ffffff',
                                                    color: 'primary.main',
                                                    fontWeight: 'bold',
                                                    boxShadow: 'inset 0 -2px 0 0 #3f51b5'
                                                }
                                            }
                                        }}
                                    >
                                        <ToggleButton value="grid" aria-label="grid view">
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                                                <ViewModuleIcon />
                                                <Typography variant="caption" sx={{ fontWeight: viewMode === 'grid' ? 'bold' : 'normal' }}>
                                                    Grid
                                                </Typography>
                                            </Box>
                                        </ToggleButton>
                                        <ToggleButton value="table" aria-label="table view">
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                                                <ViewListIcon />
                                                <Typography variant="caption" sx={{ fontWeight: viewMode === 'table' ? 'bold' : 'normal' }}>
                                                    Table
                                                </Typography>
                                            </Box>
                                        </ToggleButton>
                                        <ToggleButton value="binder" aria-label="binder view">
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                                                <AutoStoriesIcon />
                                                <Typography variant="caption" sx={{ fontWeight: viewMode === 'binder' ? 'bold' : 'normal' }}>
                                                    Binder
                                                </Typography>
                                            </Box>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Paper>

                                {/* Secondary controls group - will stay together when wrapping */}
                                <Box className="control-group">
                                    {/* Shadow Mode toggle */}
                                    {(viewMode === 'grid' || viewMode === 'binder') && (
                                        <Paper 
                                            elevation={2} 
                                            sx={{ 
                                                borderRadius: '8px',
                                                overflow: 'hidden',
                                                display: 'inline-flex',
                                                backgroundColor: '#f5f5f5',
                                                p: 1,
                                                alignItems: 'center',
                                                height: '100%'
                                            }}
                                        >
                                                <Tooltip title="Show uncollected cards as shadows">
                                                    <div onClick={() => setGrayscaleMode(prevMode => !prevMode)} style={{ cursor: 'pointer' }}>
                                                        <Box sx={{ 
                                                            display: 'flex', 
                                                            alignItems: 'center', 
                                                            px: 1.5,
                                                            '&:hover': { opacity: 0.8 }
                                                        }}>
                                                            <FilterBAndWIcon sx={{ 
                                                                mr: 1, 
                                                                color: grayscaleMode ? 'primary.main' : 'text.secondary',
                                                                transition: 'color 0.3s'
                                                            }} />
                                                            <Typography variant="body2" fontWeight={grayscaleMode ? 'bold' : 'medium'}>
                                                                Shadow Mode
                                                            </Typography>
                                                        </Box>
                                                    </div>
                                                </Tooltip>
                                        </Paper>
                                    )}
                                    
                                    {/* Inventory Mode toggle */}
                                    {viewMode !== 'binder' && (
                                        <Paper 
                                            elevation={2} 
                                            sx={{ 
                                                borderRadius: '8px',
                                                overflow: 'hidden',
                                                display: 'inline-flex',
                                                backgroundColor: '#f5f5f5',
                                                p: 1,
                                                alignItems: 'center',
                                                height: '100%'
                                            }}
                                        >
                                                <Tooltip title="Toggle inventory count mode">
                                                    <div onClick={() => setInventoryMode(prevMode => !prevMode)} style={{ cursor: 'pointer' }}>
                                                        <Box sx={{ 
                                                            display: 'flex', 
                                                            alignItems: 'center', 
                                                            px: 1.5,
                                                            '&:hover': { opacity: 0.8 }
                                                        }}>
                                                            <InfoIcon sx={{ 
                                                                mr: 1, 
                                                                color: inventoryMode ? 'primary.main' : 'text.secondary',
                                                                transition: 'color 0.3s'
                                                            }} />
                                                            <Typography variant="body2" fontWeight={inventoryMode ? 'bold' : 'medium'}>
                                                                Inventory Mode
                                                            </Typography>
                                                        </Box>
                                                    </div>
                                                </Tooltip>
                                        </Paper>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    
                    {/* Conditionally render either the grid view, table view, or binder view */}
                    <div>
                        {viewMode === 'grid' ? (
                            isMobile ? (
                                <MobileSetCardDisplay
                                    cardsToDisplay={cardsToDisplay}
                                    setInfo={setInfo}
                                    assets={assets}
                                    showPriceData={setInfo?.showPriceData || false}
                                    changeCardSelectedStatus={changeCardSelectedStatus}
                                    changeCardCollectionStatus={changeCardCollectionStatus}
                                    grayscaleMode={grayscaleMode}
                                    inventoryMode={inventoryMode}
                                    onInventoryChange={handleInventoryChange}
                                />
                            ) : (
                                <DesktopSetCardDisplay
                                    cardsToDisplay={cardsToDisplay}
                                    setInfo={setInfo}
                                    assets={assets}
                                    showPriceData={setInfo?.showPriceData || false}
                                    changeCardSelectedStatus={changeCardSelectedStatus}
                                    changeCardCollectionStatus={changeCardCollectionStatus}
                                    grayscaleMode={grayscaleMode}
                                    inventoryMode={inventoryMode}
                                    onInventoryChange={handleInventoryChange}
                                />
                            )
                        ) : viewMode === 'table' ? (
                            <TableCardDisplay
                                cards={cardsToDisplay}
                                setInfo={setInfo}
                                assets={assets}
                                showPriceData={Boolean(setInfo?.showPriceData)}
                                changeCardSelectedStatus={changeCardSelectedStatus}
                                changeCardCollectionStatus={handleTableCardCollectionStatus}
                                inventoryMode={inventoryMode}
                                cardInventory={cards.reduce((acc, card) => ({ ...acc, [card.id]: card.quantity }), {} as Record<number, number>)}
                                onInventoryChange={handleInventoryChange}
                            />
                        ) : (
                            // For binder view, check if we're on mobile
                            isMobile ? (
                                // Show a message for mobile users
                                <Paper
                                    elevation={3}
                                    sx={{
                                        p: 3,
                                        m: 2,
                                        borderRadius: '12px',
                                        textAlign: 'center',
                                        backgroundColor: '#f8f8f8'
                                    }}
                                >
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        Binder View Not Available
                                    </Typography>
                                    <Typography variant="body1" paragraph>
                                        The binder view is designed for larger screens and is only available in desktop mode.
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Please switch to grid or table view, or try accessing this feature on a desktop device.
                                    </Typography>
                                </Paper>
                            ) : (
                                // Show binder view for desktop users
                                <BinderCardDisplay
                                    cards={cardsToDisplay}
                                    setInfo={setInfo}
                                    assets={assets}
                                    showPriceData={Boolean(setInfo?.showPriceData)}
                                    changeCardSelectedStatus={changeCardSelectedStatus}
                                    changeCardCollectionStatus={handleBinderCardCollectionStatus}
                                    grayscaleMode={grayscaleMode}
                                />
                            )
                        )}
                    </div>
                </>
            )}

            <ScrollTop />
        </>
    );
};

export default EnglishTemplate;
