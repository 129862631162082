import React, { useState } from 'react';
import { Paper, Box, Pagination } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CardImageTemplate from './CardImageTemplate';
import '../common/AdminCardDisplay.css';
import assets from '../../../../assets';
import { PokemonCard } from '../../../../api/pokemonSetApi';

interface CardImageDisplayProps {
    cards: PokemonCard[];
    page: number;
    setPage: (page: number) => void;
    onCardsUpdate: (updatedCards: PokemonCard[]) => void;
}

const CARDS_PER_PAGE = 21;

const CardImageDisplay: React.FC<CardImageDisplayProps> = ({
    cards,
    page,
    setPage,
    onCardsUpdate,
}) => {
    const [selectedCard, setSelectedCard] = useState<PokemonCard | null>(null);

    const sortedCards = [...cards].sort((a, b) => a.cardsetindex - b.cardsetindex);
    
    const handleCardClick = (card: PokemonCard) => {
        setSelectedCard(card);
    };

    const handleClose = () => {
        setSelectedCard(null);
    };

    const handleSubmitSuccess = () => {
        // Remove the cards update logic from here since we only want it on completion
    };

    const handleCompleteSuccess = () => {
        // const updatedCards = cards.map(card =>
        //     card.id === selectedCard?.id
        //         ? { ...card, isFeaturedComplete: !card.isFeaturedComplete }
        //         : card
        // );
        // onCardsUpdate(updatedCards);
    };

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div className="admin-card-view-container">
                {sortedCards.map((card) => (
                    <div key={card.cardsetindex} className="admin-card-item" onClick={() => handleCardClick(card)}>
                        <Paper
                            elevation={1}
                            sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                backgroundColor: 'white',
                                '&:hover': {
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <div className="admin-card-content-box">
                                <div className="admin-card-image-wrapper">
                                    <LazyLoadImage
                                        className="admin-card-img"
                                        src={card.imagelocation || assets.site.images.backupImage}
                                        alt={card.name}
                                        effect="blur"
                                        width="100%"
                                        height="100%"
                                        style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '10px'
                                        }}
                                    />
                                </div>
                                
                                <div className="admin-card-info-section">
                                    <div className="admin-card-title-row">
                                        <h3 className="admin-card-title">{card.name}</h3>
                                        {card.number && (
                                            <span className="admin-card-subtitle">{card.number}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>
                ))}
            </div>

            {selectedCard && (
                <CardImageTemplate
                    cardInfo={selectedCard}
                    onClose={handleClose}
                    onSubmitSuccess={handleSubmitSuccess}
                    onCompleteSuccess={handleCompleteSuccess}
                />
            )}
        </>
    );
};

export default CardImageDisplay;
