import { Drawer, List, Stack, Toolbar } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import assets from '../../assets';
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';
import { RouteType } from '../../routes/config';
import Avatar from '@mui/material/Avatar';
import '../../css/sideBar.css';
import { useSidebarRoutes } from '../../routes';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import '../../css/footer.css';
import { useAuth } from '../../context/AuthContext';

const Sidebar = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const { authenticated, userName, favoritePokemonImage } = auth;
    const authenticatedStorage = localStorage.getItem('authenticated');
    const routes = useSidebarRoutes();
    const adminKey = localStorage.getItem('adminKey');
    const [routesReady, setRoutesReady] = useState(false);
    
    // Add AuthContext to get the most up-to-date authentication state
    const { isAuthenticated } = useAuth();
    
    // Determine if user is authenticated by checking all sources
    const isUserAuthenticated = useMemo(() => {
        return isAuthenticated || authenticated || authenticatedStorage === 'true';
    }, [isAuthenticated, authenticated, authenticatedStorage]);

    // Log authentication state for debugging
    // useEffect(() => {
    //     console.log('Sidebar Authentication State:', {
    //         isAuthenticated,
    //         authenticated,
    //         authenticatedStorage,
    //         isUserAuthenticated
    //     });
    // }, [isAuthenticated, authenticated, authenticatedStorage, isUserAuthenticated]);

    const filterRoute = useCallback((route: RouteType): boolean => {
        if (route.beta) {
            return false;
        }

        if (route.path?.startsWith('/admin')) {
            return !!adminKey;
        }

        if (route.state === 'Login') {
            return !isUserAuthenticated;
        }

        if (route.state === 'Logout') {
            return isUserAuthenticated;
        }

        return !!route.displayName;
    }, [isUserAuthenticated, adminKey]);

    // Force re-evaluation of routes when authentication state changes
    useEffect(() => {
        // Add a small delay to ensure all auth state updates have propagated
        const timer = setTimeout(() => {
            setRoutesReady(true);
        }, 100);
        
        return () => clearTimeout(timer);
    }, [isUserAuthenticated]);

    const processRoute = useCallback((route: RouteType): RouteType => {
        if (route.child) {
            return {
                ...route,
                child: route.child
                    .filter(filterRoute)
                    .map(childRoute => processRoute(childRoute))
            };
        }
        return route;
    }, [filterRoute]);

    const filteredRoutes = useMemo(() => {
        if (!routesReady) return [];
        
        // First, filter and process all routes
        const processedRoutes = routes
            .filter(route => {
                const baseFilter = filterRoute(route);
                if (route.isMenu && route.child && route.child.length > 0) {
                    return true;
                }
                return baseFilter;
            })
            .map(route => processRoute(route));
        
        // Log the routes for debugging
        // console.log('Filtered routes:', {
        //     routeCount: processedRoutes.length,
        //     hasLogin: processedRoutes.some(r => r.state === 'Login'),
        //     hasLogout: processedRoutes.some(r => r.state === 'Logout'),
        //     firstRoute: processedRoutes.length > 0 ? processedRoutes[0].state : 'none'
        // });
        
        return processedRoutes;
    }, [routes, filterRoute, processRoute, routesReady]);

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: sizeConfigs.sidebar.width,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: sizeConfigs.sidebar.width,
                    boxSizing: 'border-box',
                    borderRight: '0px',
                    backgroundColor: colorConfigs.sidebar.bg,
                    color: colorConfigs.sidebar.color,
                },
            }}
        >
            <List disablePadding>
                <Toolbar>
                    <Stack sx={{ width: '100%' }} direction="row" justifyContent="center">
                        <Link to="/">
                            <img
                                style={{ width: 'auto', height: 'auto', maxWidth: '100px' }}
                                src={assets.site.images.logo}
                                alt=""
                            />
                        </Link>
                    </Stack>
                </Toolbar>

                <div className="sidebar-userProfile">
                    {isUserAuthenticated ? (
                        <>
                            <Link to={`/u/${userName ?? 'Guest'}`}>
                                <Avatar
                                    alt={userName ?? 'Guest'}
                                    src={favoritePokemonImage || assets.pokedex.pokemon.PK123}
                                    key={favoritePokemonImage}
                                />
                            </Link>
                            <Link to={`/u/${userName ?? 'Guest'}`}>
                                <p>{userName ?? 'Guest'}</p>
                            </Link>
                        </>
                    ) : (
                        <>
                            <Avatar alt="Guest" src={assets.pokedex.pokemon.PK123} />
                            <p>Guest</p>
                        </>
                    )}
                </div>

                {/* Uncomment the debug info for development */}
                {/* {process.env.NODE_ENV === 'development' && (
                    <div style={{ padding: '10px', fontSize: '10px', color: '#666' }}>
                        Auth: {isUserAuthenticated ? 'Yes' : 'No'}<br />
                        Login: {filteredRoutes.some(r => r.state === 'Login') ? 'Visible' : 'Hidden'}<br />
                        Logout: {filteredRoutes.some(r => r.state === 'Logout') ? 'Visible' : 'Hidden'}<br />
                        First Route: {filteredRoutes.length > 0 ? filteredRoutes[0].state : 'none'}
                    </div>
                )} */}

                {filteredRoutes.map((route, index) =>
                    route.displayName ? (
                        route.child ? (
                            <SidebarItemCollapse item={route} key={`${route.path}-${index}`} />
                        ) : (
                            <SidebarItem item={route} key={`${route.path}-${index}`} />
                        )
                    ) : null
                )}
            </List>
            <div className="footer-container">
                {/* <Link className="privacy-policy-link" to="/privacy-policy">
                    Privacy Policy
                </Link> */}
                <p className="footer-text">
                    MyPokellection is not affiliated with, sponsored or endorsed by, or in any way 
                    associated with Pokemon or The Pokemon Company International Inc
                </p>
            </div>
        </Drawer>
    );
};

export default Sidebar;
