import React from 'react';
import { Box, Typography, Paper, CircularProgress, Grid } from '@mui/material';

interface CameoCardHeaderProps {
    totalCards: number;
    cameoCards: number;
    loading?: boolean;
}

const CameoCardHeader: React.FC<CameoCardHeaderProps> = ({
    totalCards,
    cameoCards,
    loading = false,
}) => {
    return (
        <Box sx={{ mb: 3 }}>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Typography variant="h5" component="h1">
                        Cameo Card Manager
                    </Typography>
                    {loading && <CircularProgress size={24} />}
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            Total Cards with Cameo Pokémon: {totalCards}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            Processed Cameo Cards: {cameoCards} ({totalCards > 0 ? Math.round((cameoCards / totalCards) * 100) : 0}%)
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default CameoCardHeader;
