import { RouteType } from './config';
import HomePage from '../pages/home/HomePage';
import ResetPassword from '../pages/login/resetPassword';
import UserProfile from '../pages/userProfile/userProfile';
import LoginPage from '../pages/login/loginpage';
import PrivacyPolicy from '../pages/privacyPolicy/PrivacyPolicy';

// Public routes that are always visible
const publicRoutes: RouteType[] = [
    {
        index: true,
        element: <HomePage />,
        state: 'home',
        tcgState: 'All',
        beta: false,
        displayName: 'Home'
    },
    {
        path: '/u/:userName',
        element: <UserProfile />,
        state: 'UserProfile',
        tcgState: 'All',
        beta: true,
        displayName: 'User Profile'
    }
];

// Auth routes that change based on auth state
const authRoutes: RouteType[] = [
    {
        path: '/login',
        element: <LoginPage />,
        state: 'Login',
        tcgState: 'Admin',
        beta: false,
        displayName: 'Login/Sign Up',
        icon: 'Login',
        requiresAuth: false,
        hideWhenAuth: true,
        isAuthRoute: true
    },
    {
        path: '/logout',
        element: <LoginPage />,
        state: 'Logout',
        tcgState: 'Admin',
        beta: false,
        displayName: 'Logout',
        icon: 'Login',
        requiresAuth: true,
        hideWhenAuth: false,
        isAuthRoute: true
    }
];

// Hidden routes that aren't shown in navigation
const hiddenRoutes: RouteType[] = [
    {
        path: '/resetPassword/:token',
        element: <ResetPassword />,
        state: 'ResetPassword',
        tcgState: 'Admin',
        beta: true,
        displayName: 'Reset Password',
        isHidden: true
    }
];

const privacyPolicyRoute: RouteType = {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
    state: 'PrivacyPolicy',
    tcgState: 'Admin',
    beta: false,
    displayName: 'Privacy Policy'
};

export { publicRoutes, authRoutes, hiddenRoutes, privacyPolicyRoute };
