import { useState, useEffect } from 'react';
import { getSealedProductDetails, getSealedProductPriceHistory } from '../api/pokemonSetApi';
import { SealedProductDetails } from '../types/sealedProduct';

/**
 * Custom hook to fetch detailed information about a sealed product
 * @param productId - The ID of the sealed product
 * @returns Object containing the product details, loading state, and error
 */
const useSealedProductDetails = (productId: number) => {
  const [product, setProduct] = useState<SealedProductDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Don't fetch if no valid product ID is provided
    if (!productId) {
      setIsLoading(false);
      setProduct(null);
      return;
    }

    const fetchProductDetails = async () => {
      setIsLoading(true);
      setError(null);

      try {
        // Fetch product details
        const detailsResponse = await getSealedProductDetails(productId);

        if (detailsResponse.success && detailsResponse.product) {
          // Fetch price history
          const historyResponse = await getSealedProductPriceHistory(productId);
          
          // Create a copy of the product details
          const productDetails = { ...detailsResponse.product };
          
          // Add price history to product details
          if (historyResponse.success && historyResponse.priceHistory) {
            productDetails.price_history = historyResponse.priceHistory;
          } else {
            console.warn('Failed to fetch price history:', historyResponse.message);
            productDetails.price_history = [];
          }

          setProduct(productDetails);
        } else {
          const errorMessage = detailsResponse.message || 'Failed to load product details';
          console.error(errorMessage);
          setError(errorMessage);
          setProduct(null);
        }
      } catch (err) {
        console.error('Error fetching sealed product details:', err);
        setError('Failed to load product details. Please try again later.');
        setProduct(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  return { product, isLoading, error };
};

export default useSealedProductDetails; 