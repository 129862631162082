import { useState, useEffect } from 'react';
import { getSetSealedProducts } from '../api/pokemonSetApi';
import { SealedProduct } from '../types/sealedProduct';
import config from '../routes/config';

// Map of set names to IDs for fallback
const SET_NAME_TO_ID_MAP: Record<string, number> = {
  // Scarlet & Violet Series
  'Scarlet & Violet': 1,
  'Paldea Evolved': 2,
  'Obsidian Flames': 3,
  'Paradox Rift': 4,
  'Temporal Forces': 5,
  'Surging Sparks': 6,
  'Twilight Masquerade': 7,
  
  // Sword & Shield Series
  'Sword & Shield': 101,
  'Rebel Clash': 102,
  'Darkness Ablaze': 103,
  'Vivid Voltage': 104,
  'Battle Styles': 105,
  'Chilling Reign': 106,
  'Evolving Skies': 107,
  'Fusion Strike': 108,
  'Brilliant Stars': 109,
  'Astral Radiance': 110,
  'Lost Origin': 111,
  'Silver Tempest': 112,
  'Crown Zenith': 113,
  
  // Sun & Moon Series
  'Sun & Moon': 201,
  'Guardians Rising': 202,
  'Burning Shadows': 203,
  'Crimson Invasion': 204,
  'Ultra Prism': 205,
  'Forbidden Light': 206,
  'Celestial Storm': 207,
  'Lost Thunder': 208,
  'Team Up': 209,
  'Unbroken Bonds': 210,
  'Unified Minds': 211,
  'Cosmic Eclipse': 212,
  
  // XY Series
  'XY': 301,
  'Flashfire': 302,
  'Furious Fists': 303,
  'Phantom Forces': 304,
  'Primal Clash': 305,
  'Roaring Skies': 306,
  'Ancient Origins': 307,
  'BREAKthrough': 308,
  'BREAKpoint': 309,
  'Fates Collide': 310,
  'Steam Siege': 311,
  'Evolutions': 312,
  
  // Black & White Series
  'Black & White': 401,
  'Emerging Powers': 402,
  'Noble Victories': 403,
  'Next Destinies': 404,
  'Dark Explorers': 405,
  'Dragons Exalted': 406,
  'Boundaries Crossed': 407,
  'Plasma Storm': 408,
  'Plasma Freeze': 409,
  'Plasma Blast': 410,
  'Legendary Treasures': 411,
  
  // HeartGold & SoulSilver Series
  'HeartGold & SoulSilver': 501,
  'Unleashed': 502,
  'Undaunted': 503,
  'Triumphant': 504,
  'Call of Legends': 505,
  
  // Platinum Series
  'Platinum': 601,
  'Rising Rivals': 602,
  'Supreme Victors': 603,
  'Arceus': 604,
  
  // Diamond & Pearl Series
  'Diamond & Pearl': 701,
  'Mysterious Treasures': 702,
  'Secret Wonders': 703,
  'Great Encounters': 704,
  'Majestic Dawn': 705,
  'Stormfront': 706,
  
  // EX Series
  'Ruby & Sapphire': 801,
  'Sandstorm': 802,
  'Dragon': 803,
  'Team Magma vs Team Aqua': 804,
  'Hidden Legends': 805,
  'FireRed & LeafGreen': 806,
  'Team Rocket Returns': 807,
  'Deoxys': 808,
  'Emerald': 809,
  'Unseen Forces': 810,
  'Delta Species': 811,
  'Legend Maker': 812,
  'Holon Phantoms': 813,
  'Crystal Guardians': 814,
  'Dragon Frontiers': 815,
  'Power Keepers': 816,
  
  // Neo Series
  'Neo Genesis': 901,
  'Neo Discovery': 902,
  'Neo Revelation': 903,
  'Neo Destiny': 904,
  
  // Gym Series
  'Gym Heroes': 1001,
  'Gym Challenge': 1002,
  
  // Base Set
  'Base Set': 1101,
  'Jungle': 1102,
  'Fossil': 1103,
  'Base Set 2': 1104,
  'Team Rocket': 1105
};

/**
 * Custom hook to fetch sealed products for a specific Pokemon set
 * @param pokemonSetIdOrSetInfo - The ID of the Pokemon set or the setInfo object
 * @returns Object containing the sealed products data, loading state, and error
 */
export const useSetSealedProducts = (pokemonSetIdOrSetInfo: number | any) => {
  const [data, setData] = useState<SealedProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [lastFetchTime, setLastFetchTime] = useState<number | null>(null);

  useEffect(() => {
    // Extract the set ID from the input parameter
    let pokemonSetId: number;
    
    if (typeof pokemonSetIdOrSetInfo === 'number') {
      // If a number was passed directly, use it
      pokemonSetId = pokemonSetIdOrSetInfo;
    } else if (pokemonSetIdOrSetInfo && typeof pokemonSetIdOrSetInfo === 'object') {
      // If an object was passed, try to extract the ID
      
      // First, check if the id property exists directly on the object
      if (pokemonSetIdOrSetInfo.id !== undefined) {
        pokemonSetId = Number(pokemonSetIdOrSetInfo.id);
      } else {
        // Try to find the id if it's named differently
        const possibleIdKeys = ['setId', 'pokemonSetId', 'set_id', 'pokemon_set_id'];
        let foundId = null;
        
        for (const key of possibleIdKeys) {
          if (key in pokemonSetIdOrSetInfo) {
            foundId = pokemonSetIdOrSetInfo[key];
            break;
          }
        }
        
        // If we found an ID, use it; otherwise, try to extract from pokemon_set
        if (foundId !== null) {
          pokemonSetId = Number(foundId);
        } else if (pokemonSetIdOrSetInfo.pokemon_set && pokemonSetIdOrSetInfo.pokemon_set.id) {
          pokemonSetId = Number(pokemonSetIdOrSetInfo.pokemon_set.id);
        } else {
          // Last resort: check if there's a setName we can use to look up the ID
          const setName = pokemonSetIdOrSetInfo.setName || '';
          if (setName && SET_NAME_TO_ID_MAP[setName]) {
            pokemonSetId = SET_NAME_TO_ID_MAP[setName];
          } else {
            pokemonSetId = 0;
          }
        }
      }
    } else {
      // Fallback
      pokemonSetId = 0;
    }
    
    // Don't fetch if no valid set ID is provided
    if (!pokemonSetId || pokemonSetId <= 0) {
      setIsLoading(false);
      setData([]);
      return;
    }

    const fetchSealedProducts = async () => {
      const startTime = Date.now();
      setLastFetchTime(startTime);
      setIsLoading(true);
      setError(null);

      try {
        // Make the API call
        const result = await getSetSealedProducts(pokemonSetId);
        
        if (result.success && result.products) {
          setData(result.products);
        } else {
          setData([]);
          if (!result.success) {
            setError(result.message || 'Failed to load sealed products');
          }
        }
      } catch (err) {
        console.error('Error fetching sealed products:', err);
        setError('Failed to load sealed products. Please try again later.');
        setData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSealedProducts();
  }, [pokemonSetIdOrSetInfo]);

  return { data, isLoading, error };
};

export default useSetSealedProducts; 