import React from 'react';
import { ToggleButtonGroup, ToggleButton, Box, Badge, Paper } from '@mui/material';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

// Define valid button colors for type safety
type ButtonColor = '#ed6c02' | '#7b1fa2' | '#2e7d32' | '#1976d2' | '#9c27b0' | '#d32f2f' | '#000000';

interface SetInfo {
  type: string;
  setName: string;
  setSeries: string;
  setCardCounts: {
    numberOfCards: number;
    mainSetWithSecretRares: number;
    setNumber: number;
    numOfReverseSet: number;
    numberOfSubSet: number;
    pr_first_edition_count?: number;
    pr_shadowless_count?: number;
    pr_unlimited_count?: number;
    pr_fourth_print_count?: number;
  };
  showPriceData: boolean;
  releaseDate: string;
  showAllCards: boolean;
  logo: string;
  icon: string;
  setPrintRuns?: {
    firstEditionSet?: {
      buttonDisplay: string;
      description: string;
      count: number;
    };
    shadowlessSet?: {
      buttonDisplay: string;
      description: string;
      count: number;
    };
    unlimitedEditionSet?: {
      buttonDisplay: string;
      description: string;
      count: number;
    };
    fourthPrintSet?: {
      buttonDisplay: string;
      description: string;
      count: number;
    };
  };
  includesReverseHolographicCards?: boolean;
}

export interface ToggleButtonsProps {
  setInfo: SetInfo;
  select: string;
  handleToggle: (event: React.MouseEvent<HTMLElement>, newAlignment: string) => void;
  showFirstEdition: boolean;
  showShadowlessSet: boolean;
  showfourthPrintSet: boolean;
  showReverseSet: boolean;
  showSubSet: boolean;
  type: string;
  onSealedProductsClick?: () => void;
  sealedProductsCount?: number;
}

/**
 * Custom ToggleButton component that accepts a buttonColor prop for consistent styling
 * @param buttonColor - The color to use for the button text and background when selected
 * @param children - The content to display inside the button
 * @param props - Additional props to pass to the ToggleButton component
 */
const ColorToggleButton = ({ buttonColor, children, ...props }: { buttonColor: ButtonColor } & Omit<React.ComponentProps<typeof ToggleButton>, 'color'>) => (
  <ToggleButton
    {...props}
    sx={{
      color: buttonColor,
      '& .MuiSvgIcon-root': {
        color: buttonColor
      },
      '&.Mui-selected': {
        color: (theme) => theme.palette.getContrastText(buttonColor),
        backgroundColor: buttonColor,
        '& .MuiSvgIcon-root': {
          color: (theme) => theme.palette.getContrastText(buttonColor)
        },
        '&:hover': {
          backgroundColor: buttonColor,
          opacity: 0.9,
        },
      },
      '&.Mui-disabled': {
        backgroundColor: '#f5f5f5',
        color: 'rgba(0, 0, 0, 0.26)',
      },
      '&:first-of-type': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      ...props.sx
    }}
  >
    {children}
  </ToggleButton>
);

/**
 * ToggleButtons component that displays a set of toggle buttons for different card views
 * Includes a sealed products button and various card set options
 * 
 * Features:
 * - Prevents deselection of buttons (clicking a selected button will not deselect it)
 * - Visual separation between buttons with subtle borders
 * - Consistent styling across all buttons
 * - Sealed products button with badge counter
 * - Conditional rendering of different button sets based on card type and properties
 * 
 * @param setInfo - Information about the current card set
 * @param select - Currently selected button value
 * @param handleToggle - Callback function for button selection changes
 * @param showFirstEdition - Whether to show First Edition button
 * @param showShadowlessSet - Whether to show Shadowless button
 * @param showfourthPrintSet - Whether to show 4th Print button
 * @param showReverseSet - Whether to show Reverse Set button
 * @param showSubSet - Whether to show Sub-Set button
 * @param type - Type of cards being displayed
 * @param onSealedProductsClick - Optional callback for sealed products button click
 * @param sealedProductsCount - Optional count to display on sealed products badge
 */
const ToggleButtons: React.FC<ToggleButtonsProps> = ({
  setInfo,
  select,
  handleToggle,
  showFirstEdition,
  showShadowlessSet,
  showfourthPrintSet,
  showReverseSet,
  showSubSet,
  type,
  onSealedProductsClick,
  sealedProductsCount = 0
}) => {
  // Handle sealed products button click separately to avoid interfering with card toggle
  const handleSealedProductsClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Prevent event from bubbling up
    if (onSealedProductsClick) {
      onSealedProductsClick();
    }
  };

  // Modified handleToggle to prevent deselection
  const handleToggleWithPrevention = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    // If trying to deselect (newAlignment is null), keep the current selection
    if (newAlignment === null) {
      return;
    }
    handleToggle(event, newAlignment);
  };
  
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        p: 1,
      }}
    >
      <Paper
        elevation={4}
        sx={{ 
          width: '100%', 
          borderRadius: '8px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        {/* Sealed Products Button - Separate from ToggleButtonGroup */}
        <ColorToggleButton 
          value="sealed-products"
          buttonColor="#000000"
          onClick={handleSealedProductsClick}
          aria-label="Sealed Products"
          selected={select === "sealed-products"}
          sx={{ 
            borderRadius: 0,
            border: 'none',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            flex: '0 0 auto',
            color: '#000000',
            '&.Mui-selected': {
              color: '#000000',
              backgroundColor: '#FFD700',
              '&:hover': {
                backgroundColor: '#FFD700',
                opacity: 0.9,
              },
            },
          }}
        >
          <Badge badgeContent={sealedProductsCount} color="secondary" sx={{ mr: 1 }}>
            <ShoppingBagIcon />
          </Badge>
          Sealed Products
        </ColorToggleButton>
        
        <ToggleButtonGroup
          color="primary"
          value={select}
          exclusive
          onChange={handleToggleWithPrevention}
          aria-label="Set View"
          size="small"
          sx={{
            flex: 1,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            '& .MuiToggleButtonGroup-grouped': {
              flex: '1 0 auto',
              m: 0,
              border: 'none',
              '&:not(:last-child)': {
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
              },
            },
          }}
        >
          {/* WOTC cards with showAllCards !== true */}
          {type === 'WOTC' && setInfo?.showAllCards !== true && (
            [
              showFirstEdition && (
                <ColorToggleButton key="first-edition" value="First Edition" aria-label="First Edition" buttonColor="#ed6c02">
                  First Edition
                </ColorToggleButton>
              ),
              showShadowlessSet && (
                <ColorToggleButton key="shadowless" value="Shadowless" aria-label="Shadowless" buttonColor="#7b1fa2">
                  Shadowless
                </ColorToggleButton>
              ),
              <ColorToggleButton key="unlimited" value="Unlimited" aria-label="Unlimited" buttonColor="#2e7d32">
                Unlimited
              </ColorToggleButton>,
              showfourthPrintSet && (
                <ColorToggleButton key="4th-print" value="4th Print" aria-label="4th Print" buttonColor="#1976d2">
                  4th Print
                </ColorToggleButton>
              ),
              showReverseSet && (
                <ColorToggleButton key="reverse-set" value="Reverse Set" aria-label="Reverse Set" buttonColor="#9c27b0">
                  Reverse Set
                </ColorToggleButton>
              ),
              showSubSet && (
                <ColorToggleButton key="sub-set" value="Sub-Set" aria-label="Sub-Set" buttonColor="#d32f2f">
                  Sub-Set
                </ColorToggleButton>
              ),
              <ColorToggleButton key="all-cards" value="All Cards" aria-label="All Cards" buttonColor="#2e7d32">
                All Cards
              </ColorToggleButton>
            ].filter(Boolean)
          )}
          
          {/* showAllCards === true */}
          {setInfo?.showAllCards === true && (
            [
              <ColorToggleButton key="unlimited" value="Unlimited" aria-label="Unlimited" buttonColor="#2e7d32">
                Unlimited
              </ColorToggleButton>,
              showReverseSet && (
                <ColorToggleButton key="reverse-set" value="Reverse Set" aria-label="Reverse Set" buttonColor="#9c27b0">
                  Reverse Set
                </ColorToggleButton>
              ),
              showSubSet && (
                <ColorToggleButton key="sub-set" value="Sub-Set" aria-label="Sub-Set" buttonColor="#d32f2f">
                  Sub-Set
                </ColorToggleButton>
              ),
              <ColorToggleButton key="all-cards" value="All Cards" aria-label="All Cards" buttonColor="#2e7d32">
                All Cards
              </ColorToggleButton>
            ].filter(Boolean)
          )}
          
          {/* type !== 'WOTC' && showAllCards !== true */}
          {type !== 'WOTC' && setInfo?.showAllCards !== true && (
            [
              <ColorToggleButton key="unlimited" value="Unlimited" aria-label="Unlimited" buttonColor="#2e7d32">
                Unlimited
              </ColorToggleButton>,
              showReverseSet && (
                <ColorToggleButton key="reverse-set" value="Reverse Set" aria-label="Reverse Set" buttonColor="#9c27b0">
                  Reverse Set
                </ColorToggleButton>
              ),
              showSubSet && (
                <ColorToggleButton key="sub-set" value="Sub-Set" aria-label="Sub-Set" buttonColor="#d32f2f">
                  Sub-Set
                </ColorToggleButton>
              ),
              <ColorToggleButton key="all-cards" value="All Cards" aria-label="All Cards" buttonColor="#2e7d32">
                All Cards
              </ColorToggleButton>
            ].filter(Boolean)
          )}
        </ToggleButtonGroup>
      </Paper>
    </Box>
  );
};

export default ToggleButtons; 