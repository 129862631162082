import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { alpha } from '@mui/material/styles';

interface TableCardDisplayProps {
  cards: any[];
  setInfo: any;
  showPriceData: boolean;
  changeCardCollectionStatus: (card: any) => void;
  changeCardSelectedStatus: (card: any) => void;
  assets: any;
  inventoryMode?: boolean;
  cardInventory?: Record<number, number>;
  onInventoryChange?: (cardId: number, change: number) => void;
}

// Helper function to get edition/type display text
const getEditionTypeText = (card: any): string => {
  if (card.isReverseHolo) return 'Reverse Holo';
  if (card.isPartOfSubSet) return 'Sub-Set';
  switch (card.editionId) {
    case 1: return '1st Edition';
    case 2: return 'Shadowless';
    case 3: return 'Unlimited';
    case 4: return '4th Print';
    default: return '';
  }
};

const TableCardDisplay: React.FC<TableCardDisplayProps> = ({
  cards,
  setInfo,
  changeCardCollectionStatus,
  changeCardSelectedStatus,
  assets,
  inventoryMode = false,
  cardInventory = {},
  onInventoryChange = () => {}
}) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Box sx={{ 
      mx: 'auto', 
      maxWidth: '95%',
      mb: 4
    }}>
      <TableContainer 
        component={Paper} 
        sx={{ 
          borderRadius: '12px',
          boxShadow: 3,
          overflow: 'hidden'
        }}
      >
        <Table aria-label="card collection table" size={isExtraSmall ? "small" : "medium"}>
          <TableHead>
            <TableRow sx={{ 
              backgroundColor: theme.palette.primary.main,
            }}>
              <TableCell width="10%" sx={{ color: 'white' }}>Number</TableCell>
              <TableCell width="35%" sx={{ color: 'white' }}>Name</TableCell>
              <TableCell width="15%" sx={{ color: 'white' }}>Edition/Type</TableCell>
              <TableCell width="15%" align="right" sx={{ color: 'white' }}>Price</TableCell>
              <TableCell width="10%" align="center" sx={{ color: 'white' }}>Collection</TableCell>
              {inventoryMode && (
                <TableCell width="15%" align="center" sx={{ color: 'white' }}>Inventory</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {cards.map((card) => {
              const inventoryCount = card.quantity || 0;
              const editionType = getEditionTypeText(card);
              
              return (
                <TableRow 
                  key={card.id} 
                  hover
                  onClick={() => changeCardSelectedStatus(card)}
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.05),
                    },
                    '&:nth-of-type(odd)': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.02),
                    },
                  }}
                >
                  <TableCell>
                    <Typography variant="body2">
                      {card.number}/{setInfo.setCardCounts.setNumber}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography 
                      variant="body1" 
                      sx={{
                        fontWeight: 'medium',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: { xs: 'normal', sm: 'nowrap' }
                      }}
                    >
                      {card.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="text.secondary">
                      {editionType}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography 
                      variant="body2" 
                      sx={{
                        color: '#2e7d32',
                        fontWeight: 'bold'
                      }}
                    >
                      ${card.market_price?.toFixed(2) || '0.00'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Box 
                      onClick={(e) => {
                        e.stopPropagation();
                        changeCardCollectionStatus(card);
                      }}
                      sx={{ 
                        display: 'inline-block',
                        cursor: 'pointer',
                        '&:hover': {
                          transform: 'scale(1.2)',
                          transition: 'transform 0.2s ease',
                        }
                      }}
                    >
                      <img
                        src={
                          card.isCardInCollection
                            ? assets.site.images.pokeballCaught
                            : assets.site.images.pokeball
                        }
                        alt={card.isCardInCollection ? "Caught" : "Not caught"}
                        style={{ 
                          width: '30px',
                          height: '30px'
                        }}
                      />
                    </Box>
                  </TableCell>
                  
                  {/* Inventory Controls Column */}
                  {inventoryMode && (
                    <TableCell align="center">
                      {card.isCardInCollection ? (
                        <Box 
                          sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 0
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Paper
                            elevation={1}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: '28px',
                              overflow: 'hidden',
                              border: '1px solid #e0e0e0',
                              backgroundColor: '#f8f8f8',
                            }}
                          >
                            <IconButton 
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                onInventoryChange(card.id, -1);
                              }}
                              disabled={inventoryCount <= 1}
                              sx={{ 
                                p: 0.5,
                                mr: 0.5,
                                '&:hover': {
                                  backgroundColor: inventoryCount <= 1 ? 'inherit' : alpha('#ff5252', 0.1),
                                },
                                opacity: inventoryCount <= 1 ? 0.5 : 1
                              }}
                            >
                              <RemoveIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                            
                            <Box
                              sx={{
                                px: 2,
                                py: 0.5,
                                minWidth: '40px',
                                textAlign: 'center',
                                borderLeft: '1px solid #e0e0e0',
                                borderRight: '1px solid #e0e0e0',
                                backgroundColor: '#ffffff'
                              }}
                            >
                              <Typography variant="body2" fontWeight="bold">
                                {inventoryCount}
                              </Typography>
                            </Box>
                            
                            <IconButton 
                              size="small" 
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation();
                                onInventoryChange(card.id, 1);
                              }}
                              sx={{
                                p: 0.5,
                                ml: 0.5,
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                }
                              }}
                            >
                              <AddIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                          </Paper>
                        </Box>
                      ) : (
                        <Box
                          sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Paper
                            elevation={1}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: '28px',
                              overflow: 'hidden',
                              border: '1px solid #e0e0e0',
                              backgroundColor: '#f8f8f8',
                              py: 0.8,
                              px: 2,
                              minWidth: '120px',
                            }}
                          >
                            <Typography 
                              variant="caption" 
                              color="text.secondary" 
                              sx={{ 
                                fontStyle: 'italic',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              card not collected
                            </Typography>
                          </Paper>
                        </Box>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableCardDisplay; 