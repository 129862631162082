import React, { useRef, useEffect, useState } from 'react';
import {
    Box,
    IconButton,
    Typography,
    Card,
    Divider,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAdminFunctions } from '../../../../hooks/useAdminFunctions';
import '../../../../css/popup.css';
import assets from '../../../../assets';
import { CategoryCard } from '../../../../types/admin';

type CardType = 'Pokemon' | 'Trainer' | 'Energy' | 'Other';

interface Props {
    cardInfo: CategoryCard;
    onClose: () => void;
    onSubmitSuccess?: () => void;
}

const CategoryCardTemplate: React.FC<Props> = ({ cardInfo, onClose, onSubmitSuccess }) => {
    // Determine the initial card type based on the card's properties
    const getInitialCardType = (): CardType | '' => {
        // If we don't have the card type fields, try to infer from isProcessed
        if (cardInfo.cardIsPokemon === undefined && 
            cardInfo.cardIsTrainer === undefined && 
            cardInfo.cardIsEnergy === undefined && 
            cardInfo.cardIsOther === undefined) {
            
            // If the card is processed but we don't know the type, default to Pokemon
            if (cardInfo.isProcessed) {
                return 'Pokemon';
            }
            return '';
        }
        
        // Convert values to numbers and check if they equal 1
        if (Number(cardInfo.cardIsPokemon) === 1) return 'Pokemon';
        if (Number(cardInfo.cardIsTrainer) === 1) return 'Trainer';
        if (Number(cardInfo.cardIsEnergy) === 1) return 'Energy';
        if (Number(cardInfo.cardIsOther) === 1) return 'Other';
        return '';
    };

    const initialType = getInitialCardType();
    const [selectedType, setSelectedType] = useState<CardType | ''>(initialType);
    const [loading, setLoading] = useState(false);
    const { updateCardCategory } = useAdminFunctions();
    const containerRef = useRef<HTMLDivElement>(null);

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedType(event.target.value as CardType);
    };

    const handleSubmit = async () => {
        if (!selectedType) return;

        setLoading(true);
        try {
            const success = await updateCardCategory(cardInfo.id, selectedType);
            if (success) {
                onSubmitSuccess?.();
                onClose();
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <Box className="popup-overlay">
            <Box ref={containerRef} className="popup-container">
                <Box className="popup-header">
                    <h2 className="popup-title">{cardInfo.name}</h2>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="popup-content">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                width: '100%',
                                border: 'none',
                                boxShadow: 'none',
                            }}
                        >
                            <LazyLoadImage
                                src={cardInfo.imageLocation2x || cardInfo.imagelocation}
                                alt={cardInfo.name}
                                effect="blur"
                                placeholderSrc={assets.site.images.backupImage}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxWidth: '400px',
                                    objectFit: 'contain',
                                    background: 'transparent',
                                    boxShadow: 'none',
                                    border: 'none',
                                }}
                            />
                            <Box
                                sx={{
                                    flex: 1,
                                    p: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                }}
                            >
                                <Typography variant="h4" component="h3">
                                    {cardInfo.name}
                                </Typography>

                                <Divider />

                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        p: 2,
                                        display: 'flex',
                                        gap: 2,
                                        '& > div': { flex: 1 },
                                    }}
                                >
                                    <div>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Series Name
                                        </Typography>
                                        <Typography variant="body1">
                                            {cardInfo.seriesName}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Set Name
                                        </Typography>
                                        <Typography variant="body1">{cardInfo.setName}</Typography>
                                    </div>
                                </Box>

                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        p: 2,
                                    }}
                                >
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Processing Status
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color={
                                            cardInfo.isProcessed ? 'success.main' : 'warning.main'
                                        }
                                    >
                                        {cardInfo.isProcessed ? 'Processed' : 'Unprocessed'}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        p: 2,
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        What type of card is this?
                                    </Typography>
                                    <FormControl component="fieldset" sx={{ mb: 2, width: '100%' }}>
                                        <RadioGroup
                                            aria-label="card-type"
                                            name="card-type"
                                            value={selectedType}
                                            onChange={handleTypeChange}
                                        >
                                            <FormControlLabel 
                                                value="Pokemon" 
                                                control={<Radio />} 
                                                label="Pokémon" 
                                            />
                                            <FormControlLabel 
                                                value="Trainer" 
                                                control={<Radio />} 
                                                label="Trainer" 
                                            />
                                            <FormControlLabel 
                                                value="Energy" 
                                                control={<Radio />} 
                                                label="Energy" 
                                            />
                                            <FormControlLabel 
                                                value="Other" 
                                                control={<Radio />} 
                                                label="Other" 
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleSubmit}
                                        disabled={!selectedType || loading}
                                    >
                                        {loading ? <CircularProgress size={24} /> : 'Submit'}
                                    </Button>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CategoryCardTemplate;
