import React, { useRef, useEffect, useState } from 'react';
import {
    Box,
    IconButton,
    Typography,
    Card,
    Divider,
    Button,
    FormControl,
    FormControlLabel,
    Checkbox,
    Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAdminFunctions } from '../../../../hooks/useAdminFunctions';
import '../../../../css/popup.css';
import assets from '../../../../assets';
import { PokemonSelector } from '../../../../components/PokemonSelector';
import { FeaturedCard } from '../../../../types/admin';

interface Pokemon {
    id: number;
    name: string;
    picURL: string;
    number: string;
}

interface Props {
    cardInfo: FeaturedCard;
    onClose: () => void;
    onSubmitSuccess?: () => void;
    onCompleteSuccess?: () => void;
}

const FeaturedCardTemplate: React.FC<Props> = ({
    cardInfo,
    onClose,
    onSubmitSuccess,
    onCompleteSuccess,
}) => {
    const [selectedPokemonId, setSelectedPokemonId] = useState<number | null>(null);
    const [hasCameoPokemon, setHasCameoPokemon] = useState(false);
    const {
        updateCardFeatured,
        loading,
        getCurrentFeaturedPokemon,
        completeFeaturedCard,
        removeFeaturedPokemon,
    } = useAdminFunctions();
    const containerRef = useRef<HTMLDivElement>(null);
    const [featuredPokemon, setFeaturedPokemon] = useState<Pokemon[]>([]);

    const handleSubmit = async () => {
        if (!selectedPokemonId) return;

        const success = await updateCardFeatured(cardInfo.id, selectedPokemonId, hasCameoPokemon);
        if (success) {
            const updatedPokemon = await getCurrentFeaturedPokemon(cardInfo.id);
            setFeaturedPokemon(updatedPokemon);
            setSelectedPokemonId(null);
            if (onSubmitSuccess) {
                await onSubmitSuccess();
            }
        }
    };

    const handleComplete = async () => {
        // Ensure hasCameoPokemon is a boolean
        const finalHasCameoPokemon = Boolean(hasCameoPokemon);

        // Update UI immediately with the correct boolean value
        if (onCompleteSuccess) {
            // Update cardInfo with the new values before calling onCompleteSuccess
            const updatedCardInfo = {
                ...cardInfo,
                isFeaturedComplete: 1,
                hasCameoPokemon: finalHasCameoPokemon
            };
            await onCompleteSuccess();
        }

        // Then make the API call with the boolean value
        const success = await completeFeaturedCard(cardInfo.id, finalHasCameoPokemon);

        if (success) {
            onClose();
        }
    };

    const handleRemovePokemon = async (pokemonId: number) => {
        const success = await removeFeaturedPokemon(cardInfo.id, pokemonId);
        if (success) {
            const updatedPokemon = await getCurrentFeaturedPokemon(cardInfo.id);
            setFeaturedPokemon(updatedPokemon);
        }
    };

    // Determine if the card is processed
    const isProcessed = cardInfo.hasCameoPokemon !== null && Number(cardInfo.isFeaturedComplete) === 1;

    // Determine if we need to show the Pokemon selector
    const needsPokemonSelector = !isProcessed && cardInfo.isPokemon;

    // Determine if we can complete the card
    const canCompleteCard = !isProcessed && (!cardInfo.isPokemon || (cardInfo.isPokemon && featuredPokemon.length > 0));

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;

            // Check if click is on Autocomplete or its dropdown
            const isAutocompleteClick = target.closest('.MuiAutocomplete-root') !== null;
            const isAutocompletePopup = target.closest('.MuiAutocomplete-popper') !== null;
            const isCheckboxClick = target.closest('.MuiCheckbox-root') !== null;

            if (isAutocompleteClick || isAutocompletePopup || isCheckboxClick) {
                return;
            }

            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        const fetchFeaturedPokemon = async () => {
            const pokemon = await getCurrentFeaturedPokemon(cardInfo.id);
            setFeaturedPokemon(pokemon);
        };

        fetchFeaturedPokemon();
    }, [cardInfo.id, getCurrentFeaturedPokemon]);

    return (
        <Box className="popup-overlay">
            <Box ref={containerRef} className="popup-container">
                <Box className="popup-header">
                    <h2 className="popup-title">{cardInfo.name}</h2>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="popup-content">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                width: '100%',
                                border: 'none',
                                boxShadow: 'none',
                            }}
                        >
                            <LazyLoadImage
                                src={cardInfo.imageLocation2x || cardInfo.imagelocation}
                                alt={cardInfo.name}
                                effect="blur"
                                placeholderSrc={assets.site.images.backupImage}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxWidth: '400px',
                                    objectFit: 'contain',
                                    background: 'transparent',
                                    boxShadow: 'none',
                                    border: 'none',
                                }}
                            />
                            <Box
                                sx={{
                                    flex: 1,
                                    p: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                }}
                            >
                                <Typography variant="h4" component="h3">
                                    {cardInfo.name}
                                </Typography>

                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        p: 2,
                                        display: 'flex',
                                        gap: 2,
                                        '& > div': { flex: 1 },
                                    }}
                                >
                                    <div>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Series Name
                                        </Typography>
                                        <Typography variant="body1">
                                            {cardInfo.seriesName}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Set Name
                                        </Typography>
                                        <Typography variant="body1">{cardInfo.setName}</Typography>
                                    </div>
                                </Box>

                                {featuredPokemon.length > 0 && (
                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Currently Featured Pokemon
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: 2,
                                                mb: 2,
                                            }}
                                        >
                                            {featuredPokemon.map((pokemon) => (
                                                <Paper
                                                    key={pokemon.id}
                                                    elevation={3}
                                                    sx={{
                                                        position: 'relative',
                                                        width: 200,
                                                        p: 1,
                                                        height: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        transition:
                                                            'box-shadow 0.3s, transform 0.3s',
                                                        '&:hover': {
                                                            boxShadow:
                                                                '0 8px 16px 0 rgba(0,0,0,0.2)',
                                                            transform: 'translateY(-4px)',
                                                        },
                                                    }}
                                                >
                                                    <LazyLoadImage
                                                        src={pokemon.picURL}
                                                        alt={pokemon.name}
                                                        effect="blur"
                                                        style={{
                                                            width: '100%',
                                                            height: 'auto',
                                                            objectFit: 'contain',
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="subtitle2"
                                                        sx={{
                                                            mt: 1,
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {pokemon.name}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        color="text.secondary"
                                                        sx={{ textAlign: 'center' }}
                                                    >
                                                        #{pokemon.number}
                                                    </Typography>
                                                    {!isProcessed && (
                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            size="small"
                                                            onClick={() => handleRemovePokemon(pokemon.id)}
                                                            sx={{ mt: 1 }}
                                                        >
                                                            Remove
                                                        </Button>
                                                    )}
                                                </Paper>
                                            ))}
                                        </Box>
                                    </>
                                )}

                                {!isProcessed && cardInfo.isPokemon && (
                                    <>
                                        <Box
                                            sx={{
                                                bgcolor: 'background.paper',
                                                borderRadius: 1,
                                                p: 2,
                                                width: '100%',
                                                mb: 2
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                color="text.secondary"
                                                gutterBottom
                                            >
                                                What Pokemon is Featured in this Card?
                                            </Typography>
                                            <PokemonSelector
                                                value={selectedPokemonId}
                                                onChange={setSelectedPokemonId}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={hasCameoPokemon}
                                                        onChange={(e) => setHasCameoPokemon(e.target.checked)}
                                                    />
                                                }
                                                label="Does this card feature any Cameo Pokemon?"
                                            />
                                        </Box>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={handleSubmit}
                                            disabled={!selectedPokemonId || loading}
                                            fullWidth
                                            sx={{
                                                mb: 2,
                                                height: 48,
                                                bgcolor: (!selectedPokemonId || loading) ? 'rgba(0, 0, 0, 0.12)' : '#2e7d32',
                                                color: (!selectedPokemonId || loading) ? 'rgba(0, 0, 0, 0.26)' : '#fff'
                                            }}
                                        >
                                            ADD FEATURED POKEMON
                                        </Button>
                                    </>
                                )}

                                {canCompleteCard && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleComplete}
                                        disabled={loading}
                                        fullWidth
                                        sx={{
                                            height: 48,
                                            bgcolor: loading ? 'rgba(0, 0, 0, 0.12)' : '#1976d2',
                                            color: loading ? 'rgba(0, 0, 0, 0.26)' : '#fff'
                                        }}
                                    >
                                        COMPLETE CARD
                                    </Button>
                                )}
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default FeaturedCardTemplate;
