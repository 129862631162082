import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthenticated as setReduxAuthenticated } from '../redux/slices/authSlice';

interface AuthContextType {
    authParams: any;
    setAuthParams: (params: any) => void;
    isAuthenticated: boolean;
    setIsAuthenticated: (value: boolean) => void;
    adminKey: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [authParams, setAuthParams] = useState<any>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [adminKey, setAdminKey] = useState<string | null>(localStorage.getItem('adminKey'));
    const dispatch = useDispatch();

    useEffect(() => {
        // Check local storage for existing auth data on component mount
        const storedAuthParams = localStorage.getItem('authParams');
        const adminKey = localStorage.getItem('adminKey');
        const authState = localStorage.getItem('authState');
        const authenticatedFlag = localStorage.getItem('authenticated');

        // Determine authentication status from all possible sources
        const isAuthFromParams = !!storedAuthParams;
        const isAuthFromState = authState ? JSON.parse(authState).authenticated : false;
        const isAuthFromFlag = authenticatedFlag === 'true';
        
        const isUserAuthenticated = isAuthFromParams || isAuthFromState || isAuthFromFlag;

        if (storedAuthParams) {
            const parsedParams = JSON.parse(storedAuthParams);
            // Include adminKey in the initial authParams if it exists
            const updatedParams = adminKey ? { ...parsedParams, adminKey } : parsedParams;

            setAuthParams(updatedParams);
        }
        
        // Set authentication state and ensure Redux is in sync
        setIsAuthenticated(isUserAuthenticated);
        dispatch(setReduxAuthenticated(isUserAuthenticated));
        
        // Also update localStorage for consistency
        if (isUserAuthenticated) {
            localStorage.setItem('authenticated', 'true');
        }
    }, [dispatch]);

    const updateAuthParams = (params: any) => {
        const adminKey = localStorage.getItem('adminKey');
        const updatedParams = adminKey ? { ...params, adminKey } : params;

        setAuthParams(updatedParams);
        localStorage.setItem('authParams', JSON.stringify(updatedParams));
        setIsAuthenticated(true);
        dispatch(setReduxAuthenticated(true));
        localStorage.setItem('authenticated', 'true');
    };

    // Add a function to handle authentication state changes
    const updateIsAuthenticated = (value: boolean) => {
        setIsAuthenticated(value);
        dispatch(setReduxAuthenticated(value));
        localStorage.setItem('authenticated', value ? 'true' : 'false');
        
        if (!value) {
            // If logging out, clear auth params
            setAuthParams(null);
            localStorage.removeItem('authParams');
        }
    };

    return (
        <AuthContext.Provider
            value={{
                authParams,
                setAuthParams: updateAuthParams,
                isAuthenticated,
                setIsAuthenticated: updateIsAuthenticated,
                adminKey,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
