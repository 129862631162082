import React, { useState, useEffect } from 'react';
import { Paper, Stack, Typography, Box, Button, Grid } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import assets from '../../../src/assets';
import SealedProductsPanel from '../../components/SealedProductsPanel';
import SealedProductModal from '../../components/SealedProductModal';
import { useSetSealedProducts } from '../../hooks/useSetSealedProducts';

interface KnownCard {
    name: string;
    image: string;
}

interface ComingSoonSetInfo {
    logo?: string;
    setName: string;
    setSeries: string;
    releaseDate?: string;
    description?: string;
    tcgplayerlink?: string;
    summary?: string;
    knownCards?: KnownCard[];
    id?: number;
    pokemon_set?: {
        id?: number;
        [key: string]: any;
    };
}

interface ComingSoonTemplateProps {
    setInfo?: ComingSoonSetInfo;
}

const ComingSoonTemplate: React.FC<ComingSoonTemplateProps> = ({ setInfo }) => {
    // State for sealed product modal
    const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
    
    // Comprehensive debug logging for setInfo
    useEffect(() => {
        console.group('🔍 ComingSoonTemplate: DETAILED ROOT INFO DEBUG');
        console.log('Full setInfo object:', setInfo);
        console.log('setInfo type:', typeof setInfo);
        
        if (setInfo) {
            console.log('Direct keys on setInfo:', Object.keys(setInfo));
            
            // Check for id property
            console.log('setInfo.id:', setInfo.id);
            console.log('setInfo.id type:', typeof setInfo.id);
            
            // Check for pokemon_set property
            console.log('setInfo.pokemon_set:', setInfo.pokemon_set);
            if (setInfo.pokemon_set) {
                console.log('setInfo.pokemon_set.id:', setInfo.pokemon_set.id);
                console.log('Keys on pokemon_set:', Object.keys(setInfo.pokemon_set || {}));
            }
            
            // Check for any property that might contain 'id'
            for (const key of Object.keys(setInfo)) {
                const value = (setInfo as any)[key];
                if (typeof value === 'object' && value !== null) {
                    console.log(`Checking object property "${key}":`, value);
                    if ('id' in value) {
                        console.log(`Found id in ${key}.id:`, value.id);
                    }
                }
                
                // Check if the key itself contains 'id'
                if (key.toLowerCase().includes('id')) {
                    console.log(`Property with "id" in name: ${key} =`, (setInfo as any)[key]);
                }
            }
            
            // Check for nested properties that might contain set information
            const potentialSetContainers = ['set', 'pokemonSet', 'pokemon_set', 'setInfo', 'set_info'];
            for (const container of potentialSetContainers) {
                if ((setInfo as any)[container]) {
                    console.log(`Found potential set container "${container}":`, (setInfo as any)[container]);
                }
            }
        } else {
            console.log('setInfo is undefined or null');
        }
        console.groupEnd();
    }, [setInfo]);
    
    // Fetch sealed products data using the custom hook
    const { 
        data: sealedProducts, 
        isLoading: sealedProductsLoading, 
        error: sealedProductsError 
    } = useSetSealedProducts(setInfo);
    
    // Log sealed products data
    useEffect(() => {
        console.group('🛍️ ComingSoonTemplate: Sealed Products Data');
        console.log('sealedProducts:', sealedProducts);
        console.log('sealedProductsLoading:', sealedProductsLoading);
        console.log('sealedProductsError:', sealedProductsError);
        console.log('sealedProducts count:', sealedProducts?.length || 0);
        console.groupEnd();
    }, [sealedProducts, sealedProductsLoading, sealedProductsError]);

    if (!setInfo) {
        return (
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={3} sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
                <Paper elevation={4} sx={{ p: 2, width: '100%', textAlign: 'center' }}>
                    <Typography variant="h6">Set information not available</Typography>
                </Paper>
            </Stack>
        );
    }

    const defaultLink = 'https://partner.tcgplayer.com/c/5054575/1780961/21018?u=https://www.tcgplayer.com/categories/trading-and-collectible-card-games/pokemon';
    const dynamicLink = setInfo.tcgplayerlink ? `https://partner.tcgplayer.com/c/5054575/1780961/21018?u=${encodeURIComponent(setInfo.tcgplayerlink)}` : defaultLink;

    // Handle product click
    const handleProductClick = (productId: number) => {
        console.log('Product clicked:', productId);
        setSelectedProductId(productId);
    };

    // Handle modal close
    const handleCloseModal = () => {
        setSelectedProductId(null);
    };

    // Determine if we should show the sealed products section
    const shouldShowSealedProducts = Boolean(sealedProducts?.length) || sealedProductsLoading;

    return (
        <Box
            sx={{
                position: 'relative',
                minHeight: '100%',
                width: '100%',
                backgroundColor: 'background.default',
                overflow: 'hidden',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    inset: '-50%',
                    backgroundImage: setInfo.logo ? `url("${setInfo.logo}")` : 'none',
                    backgroundPosition: 'center',
                    backgroundSize: '200%',
                    opacity: 0.06,
                    filter: 'blur(15px)',
                    zIndex: 0,
                    pointerEvents: 'none'
                }
            }}
        >
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={4} 
                sx={{ 
                    maxWidth: '1200px', 
                    margin: '0 auto', 
                    padding: '20px',
                    position: 'relative',
                    zIndex: 1
                }}
            >
                {/* Logo Section */}
                {setInfo.logo && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <LazyLoadImage
                            className="image"
                            src={setInfo.logo}
                            alt={`${setInfo.setName} Logo`}
                            effect="blur"
                            style={{
                                width: 'auto',
                                height: 'auto',
                                maxWidth: '400px',
                                maxHeight: '300px',
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                )}

                {/* Title Section */}
                <Typography 
                    variant="h3" 
                    component="h1" 
                    align="center" 
                    sx={{ 
                        fontWeight: 'bold',
                        color: 'primary.main',
                        mb: 2
                    }}
                >
                    {setInfo.setName} is coming {setInfo.releaseDate ? `on ${setInfo.releaseDate}` : 'soon'}
                </Typography>

                {/* Summary Section */}
                {setInfo.summary && (
                    <Paper elevation={4} sx={{ p: 3, width: '100%', bgcolor: 'background.paper' }}>
                        <Typography 
                            variant="h6" 
                            component="div" 
                            align="center"
                            sx={{ 
                                mb: 2,
                                lineHeight: 1.6,
                                color: 'text.primary'
                            }}
                        >
                            {setInfo.summary}
                        </Typography>
                    </Paper>
                )}

                {/* Pre-order Button */}
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mt: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        href={dynamicLink}
                        target="_blank"
                        rel="noreferrer"
                        sx={{
                            py: 2,
                            px: 4,
                            borderRadius: 2,
                            fontSize: '1.2rem',
                            textTransform: 'none'
                        }}
                    >
                        Pre-order on TCGplayer
                    </Button>
                    <img
                        src={assets.site.images.tcgplayerIcon1}
                        alt="TCGplayer"
                        width="100"
                        height="39"
                    />
                    {/* Tracking pixel */}
                    <img
                        height="0"
                        width="0"
                        src="https://partner.tcgplayer.com/Vx4RaM"
                        alt=""
                        style={{ position: 'absolute', visibility: 'hidden' }}
                    />
                </Box>

                {/* Known Cards Section */}
                {setInfo.knownCards && setInfo.knownCards.length > 0 && (
                    <Paper elevation={4} sx={{ p: 3, width: '100%', mt: 4 }}>
                        <Typography variant="h4" fontWeight="bold" gutterBottom align="center">
                            Known Cards
                        </Typography>
                        <Stack direction="row" spacing={2} flexWrap="wrap" justifyContent="center">
                            {setInfo.knownCards.map((card: KnownCard, index: number) => (
                                <Box key={index} sx={{ width: 200, textAlign: 'center', m: 1 }}>
                                    <LazyLoadImage
                                        src={card.image}
                                        alt={card.name}
                                        effect="blur"
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: '10px'
                                        }}
                                    />
                                    <Typography variant="body1" sx={{ mt: 1, fontWeight: 'medium' }}>
                                        {card.name}
                                    </Typography>
                                </Box>
                            ))}
                        </Stack>
                    </Paper>
                )}

                {/* Sealed Products Section */}
                {shouldShowSealedProducts && (
                    <Paper elevation={4} sx={{ p: 3, width: '100%', mt: 4 }}>
                        <Typography variant="h4" fontWeight="bold" gutterBottom align="center">
                            Pre-order Products
                        </Typography>
                        <Typography variant="body1" align="center" sx={{ mb: 3 }}>
                            These products are available for pre-order. Click on a product to see more details.
                        </Typography>
                        
                        <SealedProductsPanel 
                            products={sealedProducts || []}
                            isLoading={sealedProductsLoading}
                            error={sealedProductsError}
                            onProductClick={handleProductClick}
                        />
                    </Paper>
                )}
            </Stack>

            {/* Sealed Product Modal */}
            {selectedProductId !== null && (
                <SealedProductModal
                    open={selectedProductId !== null}
                    onClose={handleCloseModal}
                    productId={selectedProductId}
                />
            )}
        </Box>
    );
};

export default ComingSoonTemplate;
