import React, { useState, useEffect, useCallback, useMemo, memo, useRef } from 'react';
import {
    Box,
    Paper,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Snackbar,
    Alert,
    CircularProgress,
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tooltip
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SortIcon from '@mui/icons-material/Sort';
import assets from '../../../../assets';
import './CardIndexDisplay.css'; // Import the CSS file
import { toast } from 'react-toastify';
import config from '../../../../routes/config';
import { useAuth } from '../../../../context/AuthContext';
import { debounce } from 'lodash';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { batchUpdateCardIndexes } from '../../../../api/adminApi';

interface Card {
    id: number;
    name: string;
    number: string;
    imagelocation: string;
    imageLocation2x: string;
    setName: string;
    seriesName: string;
    isPokemon: boolean;
    cardsetindex: number;
    pokemonsetId: number;
    isFeaturedComplete: boolean;
    hasCameoPokemon: boolean;
    updatedAt: string;
    isErrorCard: boolean;
    isPromoCard: boolean;
    isReverseHolo: boolean;
    isPartOfSubSet: boolean;
}

interface CardIndexDisplayProps {
    cards: Card[];
    onCardsUpdate: (cards: Card[]) => void;
    selectedSetId: number | null;
    isLoading: boolean;
}

// Card component with custom drag and drop
const CardItem = memo(({ 
    card, 
    isSaving, 
    index,
    onDragStart,
    onDragOver,
    onDrop,
    isDropTarget,
    isDragging
}: { 
    card: Card, 
    isSaving: number | null, 
    index: number,
    onDragStart: (e: React.DragEvent, card: Card, index: number) => void,
    onDragOver: (e: React.DragEvent, index: number) => void,
    onDrop: (e: React.DragEvent) => void,
    isDropTarget: boolean,
    isDragging: boolean
}) => {
    const cardRef = useRef<HTMLDivElement>(null);
    
    return (
        <Box
            ref={cardRef}
            className={`desk-card-container ${isDropTarget ? 'drop-target' : ''} ${isDragging ? 'dragging' : ''}`}
            draggable={true}
            onDragStart={(e) => onDragStart(e, card, index)}
            onDragOver={(e) => onDragOver(e, index)}
            onDrop={onDrop}
        >
            <Paper 
                elevation={1}
                sx={{ 
                    p: 1, 
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'box-shadow 0.2s ease',
                    boxShadow: isDropTarget 
                        ? '0 0 0 2px rgba(25, 118, 210, 0.5), 0 4px 8px rgba(0, 0, 0, 0.1)' 
                        : undefined
                }}
            >
                <Box 
                    className="drag-handle"
                >
                    <DragIndicatorIcon />
                </Box>
                
                <Box 
                    className="card-content"
                >
                    <Box
                        component="img"
                        src={card.imageLocation2x || card.imagelocation || assets.site.images.backupImage}
                        alt={card.name}
                        className="card-image"
                        loading="lazy"
                        onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                            e.currentTarget.src = assets.site.images.backupImage;
                        }}
                        sx={{
                            alignSelf: 'center',
                            mb: 1,
                            display: 'block'
                        }}
                    />
                    
                    <Typography 
                        variant="body2" 
                        fontWeight="bold"
                        sx={{ 
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            mb: 0.5
                        }}
                    >
                        {card.name}
                    </Typography>
                    
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ textAlign: 'center', mb: 0.5 }}
                    >
                        #{card.number}
                    </Typography>
                    
                    <Typography
                        className={`card-type ${card.isReverseHolo ? 'reverse-holo' : 'normal-card'}`}
                        sx={{ textAlign: 'center' }}
                    >
                        {card.isReverseHolo ? "Reverse Holo" : "Normal"}
                    </Typography>
                    
                    <Box 
                        className="card-index"
                        sx={{ 
                            mt: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        {isSaving === card.id ? (
                            <CircularProgress size={16} sx={{ mr: 0.5 }} />
                        ) : null}
                        <Typography>
                            Index: {card.cardsetindex}
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
});

// StrictModeDroppable is a workaround for React 18 StrictMode compatibility with react-beautiful-dnd
const StrictModeDroppable = ({ children, ...props }: React.ComponentProps<typeof Droppable>) => {
    const [enabled, setEnabled] = useState(false);
    
    useEffect(() => {
        // This timeout ensures proper mounting of the Droppable component in StrictMode
        const animation = requestAnimationFrame(() => setEnabled(true));
        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);
    
    if (!enabled) {
        return (
            <Box 
                className="desktop-set-cards-container" 
                sx={{ minHeight: 300 }}
            >
                <Box sx={{ 
                    gridColumn: '1 / -1', 
                    textAlign: 'center', 
                    p: 2, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center' 
                }}>
                    <CircularProgress size={24} sx={{ mr: 1 }} />
                    <Typography>Initializing drag and drop...</Typography>
                </Box>
            </Box>
        );
    }
    
    return <Droppable {...props}>{children}</Droppable>;
};

const CardIndexDisplay: React.FC<CardIndexDisplayProps> = ({
    cards,
    onCardsUpdate,
    selectedSetId,
    isLoading,
}) => {
    const { authParams } = useAuth();
    const [isSaving, setIsSaving] = useState<number | null>(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [localCards, setLocalCards] = useState<Card[]>([]);
    const [originalIndexes, setOriginalIndexes] = useState<{[key: number]: number}>({});
    const [batchUpdateInProgress, setBatchUpdateInProgress] = useState(false);
    const [batchUpdateProgress, setBatchUpdateProgress] = useState(0);
    const [draggedCard, setDraggedCard] = useState<Card | null>(null);
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dropTargetIndex, setDropTargetIndex] = useState<number | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    
    // Auto-scroll state
    const [autoScrollDirection, setAutoScrollDirection] = useState<'up' | 'down' | null>(null);
    const autoScrollIntervalRef = useRef<number | null>(null);
    
    // Container ref
    const containerRef = useRef<HTMLDivElement>(null);
    
    // Pagination for better performance
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    
    // Initialize local cards when cards prop changes
    useEffect(() => {
        if (cards && cards.length > 0) {
            // Filter out cards without valid IDs to prevent drag-and-drop issues
            const validCards = cards.filter(card => card.id && typeof card.id === 'number');
            
            if (validCards.length !== cards.length) {
                console.warn(`Filtered out ${cards.length - validCards.length} cards with invalid IDs`);
            }
            
            console.log('Setting cards with valid IDs:', validCards.length);
            
            // Use a more efficient approach to initialize indexes
            const origIndexes: {[key: number]: number} = {};
            const newLocalCards = [...validCards];
            
            // Process in batches to avoid blocking the main thread
            const batchSize = 100;
            let processed = 0;
            
            // Use arrow function to avoid strict mode issues
            const processBatch = () => {
                const end = Math.min(processed + batchSize, validCards.length);
                
                for (let i = processed; i < end; i++) {
                    const card = validCards[i];
                    origIndexes[card.id] = card.cardsetindex;
                }
                
                processed = end;
                
                if (processed < validCards.length) {
                    // Schedule next batch
                    setTimeout(processBatch, 0);
                } else {
                    // All batches processed, update state
                    setOriginalIndexes(origIndexes);
                    setLocalCards(newLocalCards);
                }
            };
            
            // Start batch processing
            processBatch();
        } else {
            setLocalCards([]);
            setOriginalIndexes({});
        }
    }, [cards]);

    // Reset page when selected set changes
    useEffect(() => {
        setPage(0);
    }, [selectedSetId]);

    // Auto-scroll functionality
    useEffect(() => {
        // Start auto-scrolling if direction is set
        if (isDragging && autoScrollDirection) {
            // Clear any existing interval
            if (autoScrollIntervalRef.current) {
                window.clearInterval(autoScrollIntervalRef.current);
            }
            
            // Set new interval for scrolling
            autoScrollIntervalRef.current = window.setInterval(() => {
                const scrollAmount = autoScrollDirection === 'up' ? -40 : 40;
                window.scrollBy({ top: scrollAmount, behavior: 'smooth' });
            }, 50);
            
            // Clean up interval on direction change or when dragging stops
            return () => {
                if (autoScrollIntervalRef.current) {
                    window.clearInterval(autoScrollIntervalRef.current);
                    autoScrollIntervalRef.current = null;
                }
            };
        } else if (autoScrollIntervalRef.current) {
            // Stop scrolling if not dragging
            window.clearInterval(autoScrollIntervalRef.current);
            autoScrollIntervalRef.current = null;
        }
    }, [isDragging, autoScrollDirection]);

    // Add event listeners for drag and drop
    useEffect(() => {
        // Add global dragover handler to enable drop and control auto-scrolling
        const handleGlobalDragOver = (e: DragEvent) => {
            e.preventDefault();
            
            if (isDragging) {
                // Get viewport dimensions
                const viewportHeight = window.innerHeight;
                
                // Define scroll trigger areas (pixels from top/bottom edge)
                const scrollTriggerArea = 100;
                
                // Check mouse position relative to viewport edges
                if (e.clientY < scrollTriggerArea) {
                    // Mouse near top edge - scroll up
                    setAutoScrollDirection('up');
                } else if (e.clientY > viewportHeight - scrollTriggerArea) {
                    // Mouse near bottom edge - scroll down
                    setAutoScrollDirection('down');
                } else {
                    // Not near edges - stop scrolling
                    setAutoScrollDirection(null);
                }
            }
        };
        
        // Clear drop target when drag ends
        const handleGlobalDragEnd = () => {
            setDropTargetIndex(null);
            setDraggedCard(null);
            setDraggedIndex(null);
            setIsDragging(false);
            setAutoScrollDirection(null);
            
            // Clear any auto-scroll interval
            if (autoScrollIntervalRef.current) {
                window.clearInterval(autoScrollIntervalRef.current);
                autoScrollIntervalRef.current = null;
            }
        };
        
        document.addEventListener('dragover', handleGlobalDragOver);
        document.addEventListener('dragend', handleGlobalDragEnd);
        document.addEventListener('drop', handleGlobalDragEnd);
        
        return () => {
            document.removeEventListener('dragover', handleGlobalDragOver);
            document.removeEventListener('dragend', handleGlobalDragEnd);
            document.removeEventListener('drop', handleGlobalDragEnd);
            
            // Clear any auto-scroll interval on unmount
            if (autoScrollIntervalRef.current) {
                window.clearInterval(autoScrollIntervalRef.current);
                autoScrollIntervalRef.current = null;
            }
        };
    }, [isDragging]);

    // Custom drag and drop handlers
    const handleDragStart = useCallback((e: React.DragEvent, card: Card, index: number) => {
        setDraggedCard(card);
        setDraggedIndex(index);
        setIsDragging(true);
        
        // Set drag image (optional)
        if (e.dataTransfer.setDragImage) {
            const draggedElement = e.currentTarget.cloneNode(true) as HTMLElement;
            draggedElement.style.position = 'absolute';
            draggedElement.style.top = '-1000px';
            draggedElement.classList.add('dragged-item');
            document.body.appendChild(draggedElement);
            e.dataTransfer.setDragImage(draggedElement, 50, 50);
            
            // Clean up the temp element
            setTimeout(() => {
                document.body.removeChild(draggedElement);
            }, 0);
        }
        
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', card.id.toString());
    }, []);
    
    const handleDragOver = useCallback((e: React.DragEvent, index: number) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
        
        if (dropTargetIndex !== index) {
            setDropTargetIndex(index);
        }
    }, [dropTargetIndex]);
    
    const handleDrop = useCallback(async (e: React.DragEvent) => {
        e.preventDefault();
        
        // Stop auto-scrolling
        setAutoScrollDirection(null);
        if (autoScrollIntervalRef.current) {
            window.clearInterval(autoScrollIntervalRef.current);
            autoScrollIntervalRef.current = null;
        }
        
        if (draggedCard && draggedIndex !== null && dropTargetIndex !== null) {
            // Get current page's cards
            const currentPageCards = [...localCards]
                .sort((a, b) => a.cardsetindex - b.cardsetindex)
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage);
                
            // Skip if dropped in the same position
            if (draggedIndex === dropTargetIndex) {
                return;
            }
            
            // Create a copy of the cards
            const newPageCards = [...currentPageCards];
            
            // Remove the card from its original position
            newPageCards.splice(draggedIndex, 1);
            
            // Calculate the correct insertion index
            let insertIndex = dropTargetIndex;
            if (dropTargetIndex > draggedIndex) {
                insertIndex--;
            }
            
            // Insert the card at the new position
            newPageCards.splice(insertIndex, 0, draggedCard);
            
            // Update all indexes based on new positions
            const baseIndex = page * rowsPerPage;
            const updatedPageCards = newPageCards.map((card, idx) => ({
                ...card,
                cardsetindex: baseIndex + idx
            }));
            
            // Log how many cards are actually going to have their indexes changed
            const changedCards = updatedPageCards.filter(card => 
                originalIndexes[card.id] !== card.cardsetindex
            );
            console.log(`Cards to update: ${changedCards.length} out of ${updatedPageCards.length}`);
            
            // Update all cards with the new indexes
            const allUpdatedCards = localCards.map(card => {
                const updatedCard = updatedPageCards.find(c => c.id === card.id);
                return updatedCard || card;
            }).sort((a, b) => a.cardsetindex - b.cardsetindex);
            
            // Update local state first to reflect the change immediately
            setLocalCards(allUpdatedCards);
            
            // Reset drag state
            setDraggedCard(null);
            setDraggedIndex(null);
            setDropTargetIndex(null);
            setIsDragging(false);
            
            // Now update the server with the changes
            await updateCardIndexesInBatches(updatedPageCards);
        }
    }, [draggedCard, draggedIndex, dropTargetIndex, localCards, originalIndexes, page, rowsPerPage]);

    // Save a single card's index (only used by drag and drop now)
    const handleSaveIndex = useCallback(async (card: Card, newIndex: number) => {
        if (isNaN(newIndex) || newIndex < 0) {
            toast.error('Index must be a positive number');
            return;
        }
        
        if (newIndex === originalIndexes[card.id]) {
            console.log('No change in index, skipping update');
            return;
        }
        
        setIsSaving(card.id);
        try {
            // Create the query params from authParams
            const stringAuthParams = Object.fromEntries(
                Object.entries(authParams).map(([key, value]) => [key, String(value)])
            );
            
            const queryParams = new URLSearchParams(stringAuthParams).toString();
            
            // Create the request payload for a single card update
            const payload = {
                cardId: card.id,
                newIndex: newIndex
            };
            
            console.log('Saving card index:', payload);
            
            // Make the API call directly for a single card update
            const response = await fetch(`${config.API_URL}/admin/updateCardIndex?${queryParams}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            });
            
            const result = await response.json();
            
            if (!result.success) {
                console.error('Failed to update card index:', result);
                toast.error(result.message || 'Failed to update card index');
                setShowError(true);
            } else {
                console.log('Card index updated successfully');
                
                // Update the card in our local state
                const updatedCard = { ...card, cardsetindex: newIndex };
                
                // Create a new array with the updated card
                const updatedCards = localCards.map(c => 
                    c.id === card.id ? updatedCard : c
                );
                
                // Sort the cards by cardsetindex to reflect the new order
                const sortedCards = [...updatedCards].sort((a, b) => a.cardsetindex - b.cardsetindex);
                
                // Update local state with sorted cards
                setLocalCards(sortedCards);
                
                // Update original indexes
                setOriginalIndexes(prev => ({
                    ...prev,
                    [card.id]: newIndex
                }));
                
                setShowSuccess(true);
            }
        } catch (error) {
            console.error('Error saving card index:', error);
            toast.error('An error occurred while updating card index');
            setShowError(true);
        } finally {
            setIsSaving(null);
        }
    }, [authParams, localCards, originalIndexes]);

    // Process batch updates to the server
    const updateCardIndexesInBatches = async (cardsToUpdate: Card[]) => {
        if (!cardsToUpdate.length) return;
        
        // Filter to only cards whose indexes have actually changed
        const changedCards = cardsToUpdate.filter(card => 
            originalIndexes[card.id] !== card.cardsetindex
        );
        
        if (changedCards.length === 0) {
            console.log('No cards had their indexes changed, skipping API calls');
            toast.info('No index changes detected');
            return;
        }
        
        console.log(`Updating ${changedCards.length} cards with changed indexes out of ${cardsToUpdate.length} total`);
        toast.info(`Updating ${changedCards.length} cards...`);
        
        setBatchUpdateInProgress(true);
        setBatchUpdateProgress(0);
        
        try {
            const batchSize = 10; // Process 10 cards at a time
            const batches = Math.ceil(changedCards.length / batchSize);
            let successCount = 0;
            
            // Create the query params from authParams
            const stringAuthParams = Object.fromEntries(
                Object.entries(authParams).map(([key, value]) => [key, String(value)])
            );
            const queryParams = new URLSearchParams(stringAuthParams).toString();
            
            for (let i = 0; i < batches; i++) {
                const startIdx = i * batchSize;
                const endIdx = Math.min(startIdx + batchSize, changedCards.length);
                const batchCards = changedCards.slice(startIdx, endIdx);
                
                // Process each card in the batch sequentially
                for (const card of batchCards) {
                    const payload = {
                        cardId: card.id,
                        newIndex: card.cardsetindex
                    };
                    
                    const response = await fetch(`${config.API_URL}/admin/updateCardIndex?${queryParams}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                        credentials: 'include',
                        body: JSON.stringify(payload)
                    });
                    
                    const result = await response.json();
                    if (result.success) {
                        successCount++;
                        
                        // Update the original index as we go
                        setOriginalIndexes(prev => ({
                            ...prev,
                            [card.id]: card.cardsetindex
                        }));
                    }
                    
                    // Update progress
                    setBatchUpdateProgress(Math.round((successCount / changedCards.length) * 100));
                }
            }
            
            // Show a different message based on how many cards were updated
            if (changedCards.length === 1) {
                toast.success('Card index updated successfully');
            } else {
                toast.success(`Updated ${successCount} of ${changedCards.length} card indexes`);
            }
            
            setShowSuccess(true);
        } catch (error) {
            console.error('Error during batch update:', error);
            toast.error('An error occurred during the batch update');
            setShowError(true);
        } finally {
            setBatchUpdateInProgress(false);
        }
    };

    // Memoize the card list to prevent unnecessary re-renders
    const visibleCards = useMemo(() => {
        if (!localCards.length) return [];
        
        // Sort by cardsetindex and apply pagination
        return [...localCards]
            .sort((a, b) => a.cardsetindex - b.cardsetindex)
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    }, [localCards, page, rowsPerPage]);

    // Calculate total pages
    const totalPages = Math.ceil(localCards.length / rowsPerPage);
    
    // Automatic sort function
    const handleAutoSort = useCallback(async () => {
        if (!localCards.length || isLoading || batchUpdateInProgress) {
            return;
        }
        
        // Show a toast that sorting has started
        toast.info('Starting automatic sort...');
        
        // Clone the cards to avoid mutating state directly
        const sortedCards = [...localCards];
        
        // Sort by card number first, then by card type (normal before reverse holo)
        sortedCards.sort((a, b) => {
            // Extract numeric parts from the card numbers
            // This handles numbers like "001", "TG01", "SV01", etc.
            const aMatch = a.number.match(/(\d+)/);
            const bMatch = b.number.match(/(\d+)/);
            
            // If both cards have numeric parts, compare those
            if (aMatch && bMatch) {
                const aNum = parseInt(aMatch[0], 10);
                const bNum = parseInt(bMatch[0], 10);
                
                if (aNum !== bNum) {
                    return aNum - bNum;
                }
                
                // If the numbers are the same but prefixes/suffixes are different,
                // compare the full strings
                if (a.number !== b.number) {
                    return a.number.localeCompare(b.number);
                }
            } else {
                // If one or both numbers don't have numeric parts,
                // fall back to full string comparison
                const numCompare = a.number.localeCompare(b.number);
                if (numCompare !== 0) {
                    return numCompare;
                }
            }
            
            // If numbers are the same, put normal cards before reverse holo
            if (a.isReverseHolo !== b.isReverseHolo) {
                return a.isReverseHolo ? 1 : -1; // Normal cards (false) come first
            }
            
            // If both number and type are the same, maintain original order
            return 0;
        });
        
        // Update the card indexes based on their new positions
        const updatedCards = sortedCards.map((card, index) => ({
            ...card,
            cardsetindex: index
        }));
        
        // Update the local state to reflect the new order
        setLocalCards(updatedCards);
        
        // Determine which cards actually need updates
        const changedCards = updatedCards.filter(card => 
            originalIndexes[card.id] !== card.cardsetindex
        );
        
        // Show a message with sorting results
        toast.success(`Sorted ${sortedCards.length} cards. ${changedCards.length} cards changed position.`);
        
        // Reset to first page to show the beginning of the sorted results
        setPage(0);
        
        // Update the server with the changes if there are changes
        if (changedCards.length > 0) {
            await updateCardIndexesInBatches(updatedCards);
        }
    }, [localCards, originalIndexes, isLoading, batchUpdateInProgress]);

    if (!selectedSetId) {
        return (
            <Box sx={{ p: 3, textAlign: 'center' }}>
                <Typography variant="h6">Please select a set from the dropdown</Typography>
            </Box>
        );
    }
    
    if (!localCards || localCards.length === 0) {
        return (
            <Box my={4} textAlign="center">
                <Typography variant="h6">
                    {isLoading ? 'Loading cards...' : 'No cards found in this set'}
                </Typography>
            </Box>
        );
    }
    
    return (
        <Box sx={{ position: 'relative', width: '100%' }}>
            {isLoading && (
                <Box className="loading-overlay">
                    <CircularProgress size={60} />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Loading cards...
                    </Typography>
                </Box>
            )}
            
            {/* Batch update progress overlay */}
            {batchUpdateInProgress && (
                <Box className="loading-overlay">
                    <CircularProgress 
                        variant="determinate" 
                        value={batchUpdateProgress} 
                        size={60} 
                    />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Updating card indexes
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                        {batchUpdateProgress}% complete
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        Please wait while we update the database...
                    </Typography>
                </Box>
            )}
            
            <Paper 
                sx={{ 
                    p: 2, 
                    mb: 2, 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }}
            >
                <Box>
                    <Typography variant="h6">
                        {localCards.length} cards in set
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        Drag cards using the <DragIndicatorIcon fontSize="small" sx={{ verticalAlign: 'middle' }}/> handle to reorder them. 
                        A blue highlight will appear to show where the card will be placed.
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                        Move the card near the top or bottom of the screen to automatically scroll in that direction.
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                        Showing up to {rowsPerPage} cards. The current index is displayed at the bottom of each card.
                    </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                    <Tooltip title="Sort cards by number, with normal cards before reverse holos">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SortIcon />}
                            onClick={handleAutoSort}
                            disabled={isLoading || batchUpdateInProgress}
                            sx={{ height: 40 }}
                        >
                            Auto-Sort by Number
                        </Button>
                    </Tooltip>
                    
                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                        <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
                        <Select
                            labelId="rows-per-page-label"
                            id="rows-per-page-select"
                            value={rowsPerPage.toString()}
                            onChange={(e: SelectChangeEvent) => {
                                const newRowsPerPage = parseInt(e.target.value, 10);
                                setRowsPerPage(newRowsPerPage);
                                setPage(0); // Reset to first page when changing rows per page
                            }}
                            label="Rows per page"
                            disabled={isLoading || batchUpdateInProgress}
                        >
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={200}>200</MenuItem>
                            <MenuItem value={500}>500</MenuItem>
                        </Select>
                    </FormControl>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ mr: 2 }}>
                            Page {page + 1} of {totalPages || 1}
                        </Typography>
                        
                        <Button 
                            disabled={page === 0 || isLoading || batchUpdateInProgress} 
                            onClick={() => setPage(p => Math.max(0, p - 1))}
                            size="small"
                        >
                            Previous
                        </Button>
                        
                        <Button 
                            disabled={page >= totalPages - 1 || isLoading || batchUpdateInProgress} 
                            onClick={() => setPage(p => Math.min(totalPages - 1, p + 1))}
                            size="small"
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </Paper>
            
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Box
                    ref={containerRef}
                    className="desktop-set-cards-container"
                >
                    {visibleCards.map((card, index) => (
                        <CardItem 
                            key={card.id}
                            card={card}
                            isSaving={isSaving}
                            index={index}
                            onDragStart={handleDragStart}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            isDropTarget={dropTargetIndex === index}
                            isDragging={draggedCard === card}
                        />
                    ))}
                </Box>
            </Paper>
            
            <Snackbar 
                open={showSuccess} 
                autoHideDuration={3000}
                onClose={() => setShowSuccess(false)}
            >
                <Alert severity="success">Card index updated successfully!</Alert>
            </Snackbar>
            
            <Snackbar 
                open={showError} 
                autoHideDuration={3000}
                onClose={() => setShowError(false)}
            >
                <Alert severity="error">Failed to update card index!</Alert>
            </Snackbar>
        </Box>
    );
};

export default CardIndexDisplay; 