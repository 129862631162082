import { toast } from 'react-toastify';
import config from '../routes/config';
import { 
  GetSetSealedProductsResponse, 
  GetSealedProductDetailsResponse,
  GetSealedProductPriceHistoryResponse
} from '../types/sealedProduct';

const fetchConfig = {
    credentials: 'include' as RequestCredentials,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};

export interface PokemonSet {
    id: number;
    name: string;
    numberOfCards: number;
    releaseDate: string;
    includesReverseHolographicCards: boolean;
    image_folder: string;
}

interface PokemonSetApiResponse {
    success: boolean;
    message?: string;
    pokemonSets?: PokemonSet[];
}

export interface PokemonSetCardsApiResponse {
    success: boolean;
    message?: string;
    pokemonSetCards?: PokemonCard[];
}

export interface PokemonCard {
    id: number;
    name: string;
    number: number;
    cardsetindex: number;
    imagelocation: string | null;
    imageLocation2x: string | null;
    isReverseHolo: boolean;
    seriesName: string;
    setName: string;
    image_folder: string;
    editionName: string;
}

export interface UploadCardImageResponse {
    success: boolean;
    message?: string;
    imageUrl?: string;
    filename?: string;
}

export interface DeleteCardImageResponse {
    success: boolean;
    message?: string;
}

export interface UploadSetImageResponse {
    success: boolean;
    message?: string;
    imageUrl?: string;
}

const handleApiError = (error: any, defaultMessage: string): PokemonSetApiResponse => {
    return {
        success: false,
        message: error.message || defaultMessage,
    };
};

export const getPokemonSets = async (): Promise<PokemonSetApiResponse> => {
    try {
        const response = await fetch(`${config.API_URL}/getPokemonSets`, {
            ...fetchConfig,
            method: 'GET'
        });

        const result: PokemonSetApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch Pokemon sets');
        }

        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching Pokemon sets');
    }
};

export const getPokemonSetCards = async (
    pokemonSetId: number
): Promise<PokemonSetCardsApiResponse> => {
    try {
        const queryParams = new URLSearchParams({
            pokemonSetId: String(pokemonSetId),
        }).toString();

        const response = await fetch(`${config.API_URL}/getPokemonSetCards?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });

        const result: PokemonSetCardsApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch Pokemon set cards');
        }

        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching Pokemon set cards');
    }
};

export const uploadCardImage = async (
    file: File,
    cardId: number,
    image_folder: string,
    filename: string,
    userName: string,
    adminKey: string
): Promise<UploadCardImageResponse> => {
    try {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('cardId', String(cardId));
        formData.append('image_folder', image_folder);
        formData.append('filename', filename);
        formData.append('userName', userName);
        formData.append('adminKey', adminKey);

        const response = await fetch(`${config.API_URL}/uploadCardImage`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
        });

        const result: UploadCardImageResponse = await response.json();

        if (!result.success) {
            toast.error(result.message || 'Failed to upload image');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while uploading the image');
        return {
            success: false,
            message: 'Failed to upload image',
        };
    }
};

export const deleteCardImage = async (
    cardId: number,
    imageType: 'standard' | 'high',
    userName: string,
    adminKey: string
): Promise<DeleteCardImageResponse> => {
    try {
        const response = await fetch(`${config.API_URL}/deleteCardImage`, {
            method: 'POST',
            ...fetchConfig,
            body: JSON.stringify({
                cardId,
                imageType,
                userName,
                adminKey,
            }),
        });

        const result: DeleteCardImageResponse = await response.json();

        if (!result.success) {
            toast.error(result.message || 'Failed to delete image');
        } else {
            toast.success('Image deleted successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while deleting the image');
        return {
            success: false,
            message: 'Failed to delete image',
        };
    }
};

export const uploadSetImage = async (
    file: File,
    setId: number,
    type: 'icon' | 'logo',
    currentPath: string | null,
    userName: string,
    adminKey: string
): Promise<UploadSetImageResponse> => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('setId', String(setId));
        formData.append('type', type);
        formData.append('currentPath', currentPath || '');
        formData.append('userName', userName);
        formData.append('adminKey', adminKey);

        const response = await fetch(`${config.API_URL}/admin/uploadSetImage`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
        });

        const result: UploadSetImageResponse = await response.json();

        if (!result.success) {
            toast.error(result.message || 'Failed to upload image');
        } else {
            toast.success('Image uploaded successfully');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while uploading the image');
        return {
            success: false,
            message: 'Failed to upload image',
        };
    }
};

/**
 * Get all sealed products for a specific Pokemon set
 * @param pokemonSetId - The ID of the Pokemon set
 * @returns Promise with the sealed products response
 */
export const getSetSealedProducts = async (
  pokemonSetId: number
): Promise<GetSetSealedProductsResponse> => {
  try {
    const queryParams = new URLSearchParams({
      pokemonSetId: String(pokemonSetId),
    }).toString();

    const url = `${config.API_URL}/getSetSealedProducts?${queryParams}`;
    const response = await fetch(url, {
      ...fetchConfig,
      method: 'GET'
    });

    if (!response.ok) {
      console.error(`HTTP error ${response.status} when fetching sealed products`);
    }

    const result = await response.json();

    if (!result.success) {
      toast.error('Failed to fetch sealed products');
    }

    return result;
  } catch (error) {
    console.error('Error fetching sealed products:', error);
    return {
      success: false,
      message: 'Failed to fetch sealed products'
    };
  }
};

/**
 * Get detailed information for a specific sealed product
 * @param productId - The ID of the sealed product
 * @returns Promise with the sealed product details response
 */
export const getSealedProductDetails = async (
  productId: number
): Promise<GetSealedProductDetailsResponse> => {
  try {
    const queryParams = new URLSearchParams({
      productId: String(productId),
    }).toString();

    const response = await fetch(`${config.API_URL}/getSealedProductDetails?${queryParams}`, {
      ...fetchConfig,
      method: 'GET'
    });

    const result = await response.json();

    if (!result.success) {
      toast.error('Failed to fetch sealed product details');
    }

    return result;
  } catch (error) {
    console.error('Error fetching sealed product details:', error);
    return {
      success: false,
      message: 'Failed to fetch sealed product details'
    };
  }
};

/**
 * Get price history for a specific sealed product
 * @param productId - The ID of the sealed product
 * @param limit - Optional limit for the number of price history points
 * @returns Promise with the price history response
 */
export const getSealedProductPriceHistory = async (
  productId: number,
  limit?: number
): Promise<GetSealedProductPriceHistoryResponse> => {
  try {
    const response = await fetch(`${config.API_URL}/getSealedProductPriceHistory/${productId}`, {
      ...fetchConfig,
      method: 'GET'
    });

    const result: GetSealedProductPriceHistoryResponse = await response.json();

    if (!result.success) {
      toast.error(result.message || 'Failed to fetch sealed product price history');
    }

    return result;
  } catch (error) {
    return handleApiError(error, 'An error occurred while fetching sealed product price history');
  }
};
