import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Paper } from '@mui/material';
import { useAdminFunctions } from '../../../../hooks/useAdminFunctions';
import CardIndexDisplay from './CardIndexDisplay';
import CardSetSelector from '../CardImageManager/CardSetSelector';

interface Card {
    id: number;
    name: string;
    number: string;
    imagelocation: string;
    imageLocation2x: string;
    setName: string;
    seriesName: string;
    isPokemon: boolean;
    cardsetindex: number;
    pokemonsetId: number;
    isFeaturedComplete: boolean;
    hasCameoPokemon: boolean;
    updatedAt: string;
    isErrorCard: boolean;
    isPromoCard: boolean;
    isReverseHolo: boolean;
    isPartOfSubSet: boolean;
}

const CardIndexManager: React.FC = () => {
    const { getCardSetOrder, updateCardSetOrder } = useAdminFunctions();
    const [cards, setCards] = useState<Card[]>([]);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [selectedSetId, setSelectedSetId] = useState<number | null>(null);
    const [isLoadingCards, setIsLoadingCards] = useState(false);
    const [totalCards, setTotalCards] = useState(0);
    const [error, setError] = useState<string | null>(null);

    const fetchCards = async (setId: number | null) => {
        if (!setId) return;
        
        setIsLoadingCards(true);
        setError(null);
        try {
            const response = await getCardSetOrder(setId);
            if (response.success) {
                setTimeout(() => {
                    const validCards = ((response.cards || []) as any[]).filter(card => card && card.id);
                    console.log('Setting cards with valid IDs:', validCards.length);
                    setCards(validCards as Card[]);
                    setTotalCards(validCards.length);
                }, 100);
            } else {
                console.error('Failed to fetch card order:', response);
                setCards([]);
                setTotalCards(0);
                setError('Failed to fetch cards. Please try again.');
            }
        } catch (error) {
            console.error('Error fetching card order:', error);
            setCards([]);
            setTotalCards(0);
            setError('An error occurred while fetching cards. Please try again.');
        } finally {
            setIsLoadingCards(false);
        }
    };

    useEffect(() => {
        setIsInitialLoading(false);
    }, []);

    const handleSetChange = async (setId: number | null) => {
        setSelectedSetId(setId);
        if (setId) {
            await fetchCards(setId);
        } else {
            setCards([]);
            setTotalCards(0);
        }
    };

    const handleCardsUpdate = async (updatedCards: Card[]) => {
        if (!selectedSetId) return;

        try {
            console.log('Updating cards:', updatedCards.length);
            console.log('First few updated card IDs:', updatedCards.slice(0, 5).map(card => card.id));
            
            const response = await updateCardSetOrder(selectedSetId, updatedCards);
            if (response.success) {
                console.log('Card update successful');
                setCards(updatedCards);
            } else {
                console.error('Failed to update cards:', response);
            }
        } catch (error) {
            console.error('Error updating card order:', error);
        }
    };

    if (isInitialLoading) {
        return <CircularProgress />;
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Card Index Manager
            </Typography>
            <CardSetSelector 
                selectedSetId={selectedSetId} 
                onSetChange={handleSetChange} 
                includeStats={false}
            />
            {error && (
                <Box sx={{ mt: 2, mb: 2 }}>
                    <Paper elevation={2} sx={{ p: 2, bgcolor: 'error.light' }}>
                        <Typography color="error">{error}</Typography>
                    </Paper>
                </Box>
            )}
            <CardIndexDisplay
                cards={cards}
                onCardsUpdate={handleCardsUpdate}
                selectedSetId={selectedSetId}
                isLoading={isLoadingCards}
            />
        </Box>
    );
};

export default CardIndexManager; 