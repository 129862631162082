import { ReactNode } from 'react';

interface EnvironmentConfig {
  API_URL: string;
  WEBSITE_URL: string;
}

const config: EnvironmentConfig = {
  API_URL: process.env.REACT_APP_API_URL || 'https://mypokellection.com/betaapi',
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL || 'https://mypokellection.com'
};

// console.log('REACT_APP_ENV:', process.env.REACT_APP_ENV);
// console.log('REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
// console.log('Using API URL:', config.API_URL);

export default config;

export type RouteType = {
    element: ReactNode;
    state: string;
    index?: boolean;
    path?: string;
    child?: RouteType[];
    tcgState: string;
    beta: boolean;
    isHidden?: boolean;
    isSet?: boolean;
    isMenu?: boolean;
    isAuthRoute?: boolean;
    displayName: string;
    icon?: string;
    pokemon_set?: {
        id: number;
        name: string;
        tcgplayerlink?: string;
        setCardCounts: {
            numberOfCards: number;
            setNumber: number;
            numOfReverseSet: number;
            numberOfSubSet: number;
            mainSetWithSecretRares: number;
            pr_first_edition_count?: number;
            pr_shadowless_count?: number;
            pr_unlimited_count?: number;
            pr_fourth_print_count?: number;
        };
        releaseDate: string;
        includesReverseHolographicCards: boolean;
        showPriceData?: boolean;
        type?: string;
        icon_location?: string;
        logo_location?: string;
        setPrintRuns?: {
            firstEditionSet?: {
                buttonDisplay: string;
                description: string;
                count: number;
            };
            shadowlessSet?: {
                buttonDisplay: string;
                description: string;
                count: number;
            };
            unlimitedEditionSet?: {
                buttonDisplay: string;
                description: string;
                count: number;
            };
            fourthPrintSet?: {
                buttonDisplay: string;
                description: string;
                count: number;
            };
        };
        summary?: string;
    };
    setInfo?: {
        type?: string;
        setName: string;
        setSeries: string;
        setCardCounts?: {
            numberOfCards: number;
            setNumber: number;
            numOfReverseSet: number;
            numberOfSubSet: number;
            mainSetWithSecretRares: number;
            pr_first_edition_count?: number;
            pr_shadowless_count?: number;
            pr_unlimited_count?: number;
            pr_fourth_print_count?: number;
        };
        showPriceData?: boolean;
        releaseDate?: string;
        showAllCards?: boolean;
        logo?: string;
        icon?: string;
        tcgplayerlink?: string;
        setPrintRuns?: {
            firstEditionSet?: {
                buttonDisplay: string;
                description: string;
                count: number;
            };
            shadowlessSet?: {
                buttonDisplay: string;
                description: string;
                count: number;
            };
            unlimitedEditionSet?: {
                buttonDisplay: string;
                description: string;
                count: number;
            };
            fourthPrintSet?: {
                buttonDisplay: string;
                description: string;
                count: number;
            };
        };
    };
    requiresAuth?: boolean;
    hideWhenAuth?: boolean;
};