import React from 'react';
import { Box } from '@mui/material';
import { PokemonSetSelector } from '../../../../components/PokemonSetSelector';

interface Props {
    selectedSetId: number | null;
    onSetChange: (setId: number | null) => void;
    includeStats?: boolean;
    statsType?: 'category' | 'featured' | 'cameo';
}

const CardSetSelector: React.FC<Props> = ({ 
    selectedSetId, 
    onSetChange, 
    includeStats = false, 
    statsType = 'category' 
}) => {
    return (
        <Box sx={{ mb: 3 }}>
            <PokemonSetSelector 
                value={selectedSetId} 
                onChange={onSetChange} 
                includeStats={includeStats}
                statsType={statsType}
                isAdmin={true}
            />
        </Box>
    );
};

export default CardSetSelector;
