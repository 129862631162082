import React, { useState } from 'react';
import { Paper, Box, Pagination, Typography } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FeaturedCardTemplate from './FeaturedCardTemplate';

import '../common/AdminCardDisplay.css';
import assets from '../../../../assets';
import { FeaturedCard } from '../../../../types/admin';

interface FeaturedCardDisplayProps {
    cards: FeaturedCard[];
    filterStatus: 'processed' | 'unprocessed';
    page: number;
    setPage: (page: number) => void;
    onCardsUpdate: (cards: FeaturedCard[]) => void;
    selectedSetId: number | null;
}

const CARDS_PER_PAGE = 21;

const FeaturedCardDisplay: React.FC<FeaturedCardDisplayProps> = ({
    cards,
    filterStatus,
    page,
    setPage,
    onCardsUpdate,
    selectedSetId,
}) => {
    const [selectedCard, setSelectedCard] = useState<FeaturedCard | null>(null);

    const filteredCards = cards
        .filter((card) => {
            // Determine if the card is processed based on the criteria
            const isProcessed = card.hasCameoPokemon !== null && Number(card.isFeaturedComplete) === 1;
            
            if (filterStatus === 'processed') {
                return isProcessed;
            } else {
                return !isProcessed;
            }
        })
        .sort((a, b) => a.cardsetindex - b.cardsetindex);

    const totalPages = Math.ceil(filteredCards.length / CARDS_PER_PAGE);
    const startIndex = (page - 1) * CARDS_PER_PAGE;
    const paginatedCards = filteredCards.slice(startIndex, startIndex + CARDS_PER_PAGE);

    const handleCardClick = (card: FeaturedCard) => {
        setSelectedCard(card);
    };

    const handleClose = () => {
        setSelectedCard(null);
    };

    const handleSubmitSuccess = () => {
        // Remove the cards update logic from here since we only want it on completion
    };

    const handleCompleteSuccess = () => {
        // Update the selected card's status immediately
        if (selectedCard) {
            // Get the current state of the card from the template
            const updatedCard = {
                ...selectedCard,
                isFeaturedComplete: 1,
                // Important: hasCameoPokemon should be a boolean, not null
                hasCameoPokemon: false
            };

            const updatedCards = cards.map((card) =>
                card.id === selectedCard.id ? updatedCard : card
            );

            // Update the cards list
            onCardsUpdate(updatedCards);
            
            // Close the popup after updating
            handleClose();
        }
    };

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        window.scrollTo(0, 0);
    };

    // If there are no cards to display and we're in the unprocessed tab
    if (filteredCards.length === 0 && filterStatus === 'unprocessed' && cards.length > 0) {
        return (
            <Box my={4} textAlign="center">
                <Paper elevation={2} sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
                    <Typography variant="h6" gutterBottom>All Cards Processed</Typography>
                    <Typography variant="body1" color="text.secondary">
                        Great job! All cards in this set have been featured.
                    </Typography>
                </Paper>
            </Box>
        );
    }

    // If there are no cards at all (no set selected or empty set)
    if (filteredCards.length === 0) {
        return (
            <Box my={4} textAlign="center">
                <Paper elevation={2} sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
                    <Typography variant="h6" gutterBottom>No Cards Available</Typography>
                    <Typography variant="body1" color="text.secondary">
                        {cards.length === 0 
                            ? "Please select a set to view cards." 
                            : `No ${filterStatus} cards found in this set.`}
                    </Typography>
                </Paper>
            </Box>
        );
    }

    return (
        <>
            <div className="admin-card-view-container">
                {paginatedCards.map((card) => (
                    <div key={card.id} className="admin-card-item" onClick={() => handleCardClick(card)}>
                        <Paper
                            elevation={1}
                            sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                backgroundColor: 'white',
                                '&:hover': {
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <div className="admin-card-content-box">
                                <div className="admin-card-image-wrapper">
                                    <LazyLoadImage
                                        className="admin-card-img"
                                        src={card.imagelocation || assets.site.images.backupImage}
                                        alt={card.name}
                                        effect="blur"
                                        width="100%"
                                        height="100%"
                                        style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '10px'
                                        }}
                                    />
                                </div>
                                
                                <div className="admin-card-info-section">
                                    <div className="admin-card-title-row">
                                        <h3 className="admin-card-title">{card.name}</h3>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>
                ))}
            </div>

            {totalPages > 1 && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 3,
                        mb: 3,
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        size="large"
                    />
                </Box>
            )}

            {selectedCard && (
                <FeaturedCardTemplate
                    cardInfo={selectedCard}
                    onClose={handleClose}
                    onSubmitSuccess={handleSubmitSuccess}
                    onCompleteSuccess={handleCompleteSuccess}
                />
            )}
        </>
    );
};

export default FeaturedCardDisplay;
