import React from 'react';
import { Paper, Typography, IconButton, Box } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './MobileSetCardDisplay.css';

interface MobileSetCardDisplayProps {
    cardsToDisplay: any[];
    setInfo: any;
    assets: any;
    showPriceData: boolean;
    changeCardSelectedStatus: (card: any) => void;
    changeCardCollectionStatus: (cards: any[], card: any) => void;
    grayscaleMode?: boolean;
    inventoryMode?: boolean;
    cardInventory?: Record<number, number>;
    onInventoryChange?: (cardId: number, change: number) => void;
}

const MobileSetCardDisplay: React.FC<MobileSetCardDisplayProps> = ({
    cardsToDisplay,
    setInfo,
    assets,
    showPriceData,
    changeCardSelectedStatus,
    changeCardCollectionStatus,
    grayscaleMode = false,
    inventoryMode = false,
    cardInventory = {},
    onInventoryChange = () => {}
}) => {
    return (
        <div className="mobile-card-view-wrapper">
            <div className="mobile-card-view-container">
                {cardsToDisplay &&
                    cardsToDisplay.map((card) => {
                        const shouldApplyGrayscale = grayscaleMode && !card.isCardInCollection;
                        const inventoryCount = card.quantity || 0;
                        
                        return (
                            <div 
                                key={card.id} 
                                className="mobile-card-item"
                                onClick={() => changeCardSelectedStatus(card)}
                            >
                                <Paper 
                                    elevation={1}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        overflow: 'hidden',
                                        backgroundColor: 'white',
                                        borderRadius: '10px'
                                    }}
                                >
                                    <div className="mobile-card-content-box">
                                        <div className="mobile-card-image-wrapper">
                                            <LazyLoadImage
                                                className="mobile-card-img"
                                                src={card.imagelocation || assets.site.images.backupImage}
                                                alt={card.name}
                                                effect="blur"
                                                placeholderSrc={assets.site.images.backupImage}
                                                style={{
                                                    objectFit: 'contain',
                                                    width: '100%',
                                                    height: '100%',
                                                    filter: shouldApplyGrayscale ? 'grayscale(100%)' : 'none',
                                                    opacity: shouldApplyGrayscale ? 0.7 : 1,
                                                    transition: 'filter 0.3s, opacity 0.3s',
                                                    borderRadius: '10px'
                                                }}
                                                wrapperClassName="image-wrapper"
                                            />
                                            
                                            {shouldApplyGrayscale && (
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        color: 'rgba(0,0,0,0.2)',
                                                        fontWeight: 'bold',
                                                        zIndex: 5,
                                                        pointerEvents: 'none'
                                                    }}
                                                >
                                                    {card.number}
                                                </Typography>
                                            )}
                                        </div>
                                        
                                        <div className="mobile-card-info-section">
                                            <div className="mobile-card-name-price-row">
                                                <h3 className="mobile-card-title">{card.name}</h3>
                                                <div className="mobile-card-price-display">
                                                    <p>${card.market_price?.toFixed(2) || '0.00'}</p>
                                                </div>
                                            </div>
                                            
                                            <div className="mobile-card-number-pokeball-row">
                                                <div className="mobile-card-number-display">
                                                    <p>{card.number}/{setInfo.setCardCounts.setNumber}</p>
                                                </div>
                                                <div className="mobile-pokeball-container">
                                                    <img
                                                        loading="lazy"
                                                        className="mobile-pokeball-img"
                                                        src={
                                                            card.isCardInCollection
                                                                ? assets.site.images.pokeballCaught
                                                                : assets.site.images.pokeball
                                                        }
                                                        alt={card.isCardInCollection ? "Caught" : "Not caught"}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            changeCardCollectionStatus(cardsToDisplay, card);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            
                                            {/* Inventory Control Row */}
                                            {inventoryMode && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        mt: 1,
                                                        pt: 1,
                                                        borderTop: '1px solid #eee',
                                                        width: '100%'
                                                    }}
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    {card.isCardInCollection ? (
                                                        <Paper
                                                            elevation={1}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                borderRadius: '28px',
                                                                overflow: 'hidden',
                                                                border: '1px solid #e0e0e0',
                                                                backgroundColor: '#f8f8f8',
                                                            }}
                                                        >
                                                            <IconButton 
                                                                size="small" 
                                                                color="primary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onInventoryChange(card.id, -1);
                                                                }}
                                                                disabled={inventoryCount <= 1}
                                                                sx={{
                                                                    borderRadius: '0 50% 50% 0',
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    '&:hover': {
                                                                        backgroundColor: inventoryCount <= 1 ? 'inherit' : '#e0e0e0',
                                                                    },
                                                                    opacity: inventoryCount <= 1 ? 0.5 : 1
                                                                }}
                                                            >
                                                                <RemoveIcon fontSize="small" />
                                                            </IconButton>
                                                            
                                                            <Box
                                                                sx={{
                                                                    px: 2,
                                                                    py: 0.5,
                                                                    minWidth: '40px',
                                                                    textAlign: 'center',
                                                                    borderLeft: '1px solid #e0e0e0',
                                                                    borderRight: '1px solid #e0e0e0',
                                                                    backgroundColor: '#ffffff'
                                                                }}
                                                            >
                                                                <Typography 
                                                                    variant="body1" 
                                                                    sx={{ 
                                                                        fontWeight: 'bold',
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    {inventoryCount}
                                                                </Typography>
                                                            </Box>
                                                            
                                                            <IconButton 
                                                                size="small" 
                                                                color="primary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onInventoryChange(card.id, 1);
                                                                }}
                                                                sx={{
                                                                    borderRadius: '50% 0 0 50%',
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    '&:hover': {
                                                                        backgroundColor: '#e0e0e0',
                                                                    }
                                                                }}
                                                            >
                                                                <AddIcon fontSize="small" />
                                                            </IconButton>
                                                        </Paper>
                                                    ) : (
                                                        <Paper
                                                            elevation={1}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: '28px',
                                                                overflow: 'hidden',
                                                                border: '1px solid #e0e0e0',
                                                                backgroundColor: '#f8f8f8',
                                                                py: 0.8,
                                                                px: 2,
                                                                minWidth: '120px',
                                                                height: '32px'
                                                            }}
                                                        >
                                                            <Typography 
                                                                variant="caption" 
                                                                sx={{ 
                                                                    color: '#9e9e9e',
                                                                    fontStyle: 'italic',
                                                                    whiteSpace: 'nowrap'
                                                                }}
                                                            >
                                                                card not collected
                                                            </Typography>
                                                        </Paper>
                                                    )}
                                                </Box>
                                            )}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default MobileSetCardDisplay;
