import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Grid, 
  IconButton, 
  useMediaQuery,
  useTheme,
  Pagination,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  Tooltip
} from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GridViewIcon from '@mui/icons-material/GridView';
import FilterBAndWIcon from '@mui/icons-material/FilterBAndW';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface BinderCardDisplayProps {
  cards: any[];
  setInfo: any;
  assets: any;
  showPriceData: boolean;
  changeCardSelectedStatus: (card: any) => void;
  changeCardCollectionStatus: (card: any) => void;
  grayscaleMode?: boolean;
}

type GridLayout = '2x2' | '3x3' | '3x4' | '4x4';

const BinderCardDisplay: React.FC<BinderCardDisplayProps> = ({
  cards,
  setInfo,
  assets,
  showPriceData,
  changeCardSelectedStatus,
  changeCardCollectionStatus,
  grayscaleMode = false
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  // State for grid layout selection
  const [gridLayout, setGridLayout] = useState<GridLayout>(
    isLargeScreen ? '4x4' : isMediumScreen ? '3x4' : isSmallScreen ? '2x2' : '3x3'
  );
  
  // Update grid layout when screen size changes
  React.useEffect(() => {
    if (isLargeScreen) setGridLayout('4x4');
    else if (isMediumScreen) setGridLayout('3x4');
    else if (isSmallScreen) setGridLayout('2x2');
    else setGridLayout('3x3');
  }, [isLargeScreen, isMediumScreen, isSmallScreen]);
  
  // Handle layout change
  const handleLayoutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGridLayout(event.target.value as GridLayout);
  };
  
  // Determine grid layout based on selection
  const getGridSize = () => {
    switch (gridLayout) {
      case '4x4': return { cols: 4, rows: 4 };
      case '3x4': return { cols: 3, rows: 4 };
      case '3x3': return { cols: 3, rows: 3 };
      case '2x2': return { cols: 2, rows: 2 };
      default: return { cols: 3, rows: 3 };
    }
  };
  
  const { cols, rows } = getGridSize();
  const cardsPerPage = cols * rows * 2; // Cards per two-page spread (left and right pages)
  
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(cards.length / cardsPerPage);
  
  // Reset to page 1 when layout changes
  React.useEffect(() => {
    setCurrentPage(1);
  }, [gridLayout]);
  
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    // Scroll to top when page changes
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  // Get current page's cards
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);
  
  // Create placeholder cards if needed to fill the binder page
  const placeholders = Array(cardsPerPage - currentCards.length).fill(null).map((_, i) => ({ 
    id: `placeholder-${i}`, 
    isPlaceholder: true 
  }));
  
  const pageCards = [...currentCards, ...placeholders];
  
  // Split into left and right pages
  const leftPageCards = pageCards.slice(0, cardsPerPage / 2);
  const rightPageCards = pageCards.slice(cardsPerPage / 2, cardsPerPage);
  
  // Render a single card slot (either with a card or empty)
  const renderCardSlot = (card: any, index: number, isLeftPage: boolean, pageIndex: number) => {
    // Calculate continuous slot number based on page number and position
    const cardsPerHalfPage = cardsPerPage / 2;
    const baseSlotNumber = (pageIndex * cardsPerPage) + 1; // Start from 1 on first page
    const slotNumber = isLeftPage 
      ? baseSlotNumber + index 
      : baseSlotNumber + cardsPerHalfPage + index;
    
    const pagePosition = isLeftPage ? 'left' : 'right';
    
    if (card?.isPlaceholder) {
      return (
        <Paper
          key={`${pagePosition}-placeholder-${index}`}
          elevation={1}
          sx={{
            height: '100%',
            aspectRatio: '2.5/3.5',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          <Typography variant="h4" color="text.secondary" fontWeight="light">
            {slotNumber}
          </Typography>
        </Paper>
      );
    }
    
    // Apply grayscale filter for uncollected cards if grayscale mode is on
    const shouldApplyGrayscale = grayscaleMode && !card.isCardInCollection;
    
    return (
      <Paper
        key={card.id}
        elevation={2}
        sx={{
          height: '100%',
          aspectRatio: '2.5/3.5',
          borderRadius: '8px',
          overflow: 'hidden',
          cursor: 'pointer',
          position: 'relative',
          transition: 'transform 0.2s, box-shadow 0.2s',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
          },
          display: 'flex',
          flexDirection: 'column'
        }}
        onClick={() => changeCardSelectedStatus(card)}
      >
        {/* Main content container with fixed proportions */}
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
          {/* Card image section - takes up 80% of the height */}
          <Box 
            sx={{ 
              flex: '0 0 80%', // Fixed proportion - doesn't grow or shrink
              width: '100%',
              backgroundColor: '#ffffff',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden'
            }}
          >
            {/* Card number in top left corner */}
            <Box
              sx={{
                position: 'absolute',
                top: '5px',
                left: '5px',
                backgroundColor: 'rgba(255,255,255,0.8)',
                borderRadius: '4px',
                padding: '2px 4px',
                zIndex: 2
              }}
            >
              <Typography variant="caption" fontWeight="bold">
                {card.number}/{setInfo.setCardCounts.setNumber}
              </Typography>
            </Box>
            
            {/* Card image */}
            <img
              src={card.imagelocation || assets.site.images.backupImage}
              alt={card.name}
              style={{
                maxWidth: '90%',
                maxHeight: '90%',
                objectFit: 'contain',
                filter: shouldApplyGrayscale ? 'grayscale(100%)' : 'none',
                opacity: shouldApplyGrayscale ? 0.7 : 1,
                transition: 'filter 0.3s, opacity 0.3s',
                borderRadius: '10px'
              }}
            />
            
            {/* Large number overlay for grayscale mode */}
            {shouldApplyGrayscale && (
              <Typography
                variant="h3"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'rgba(0,0,0,0.2)',
                  fontWeight: 'bold',
                  zIndex: 5,
                  pointerEvents: 'none'
                }}
              >
                {card.number}
              </Typography>
            )}
          </Box>
          
          {/* Card info section - takes up 20% of the height */}
          <Box 
            sx={{ 
              flex: '0 0 20%', // Fixed proportion - doesn't grow or shrink
              width: '100%',
              backgroundColor: '#f8f8f8',
              padding: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            {/* Card name - on the left */}
            <Typography
              variant="body2"
              fontWeight="medium"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '100%'
              }}
            >
              {card.name}
            </Typography>
          </Box>
        </Box>
        
        {/* Pokeball - positioned absolutely */}
        <Box 
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            changeCardCollectionStatus(card);
          }}
          sx={{ 
            position: 'absolute',
            bottom: '5px',
            right: '5px',
            display: 'flex',
            cursor: 'pointer',
            padding: '6px',
            zIndex: 10,
            backgroundColor: 'rgba(255,255,255,0.7)',
            borderRadius: '50%',
            '&:hover': {
              transform: 'scale(1.2)',
              transition: 'transform 0.2s ease',
              backgroundColor: 'rgba(255,255,255,0.9)',
            }
          }}
        >
          <img
            src={
              card.isCardInCollection
                ? assets.site.images.pokeballCaught
                : assets.site.images.pokeball
            }
            alt={card.isCardInCollection ? "Caught" : "Not caught"}
            style={{ 
              width: '30px',
              height: '30px'
            }}
          />
        </Box>
      </Paper>
    );
  };
  
  // Render a page (left or right)
  const renderPage = (pageCards: any[], isLeftPage: boolean) => {
    return (
      <Box
        sx={{
          flex: 1,
          p: 2,
          backgroundColor: isLeftPage ? '#f0f0f0' : '#e8e8e8',
          borderRadius: isLeftPage ? '8px 0 0 8px' : '0 8px 8px 0',
          boxShadow: isLeftPage ? 'inset -4px 0 6px -4px rgba(0,0,0,0.1)' : 'inset 4px 0 6px -4px rgba(0,0,0,0.1)',
        }}
      >
        <Grid container spacing={2} sx={{ height: '100%' }}>
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <Grid item xs={12} key={`${isLeftPage ? 'left' : 'right'}-row-${rowIndex}`}>
              <Grid container spacing={2} justifyContent="space-between">
                {Array.from({ length: cols }).map((_, colIndex) => {
                  const index = rowIndex * cols + colIndex;
                  return (
                    <Grid item xs={12 / cols} key={`${isLeftPage ? 'left' : 'right'}-${rowIndex}-${colIndex}`}>
                      {index < pageCards.length && renderCardSlot(
                        pageCards[index], 
                        index, 
                        isLeftPage, 
                        currentPage - 1 // Pass page index (0-based)
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  
  return (
    <Box sx={{ mx: 2, mb: 4 }}>
      {/* Layout controls and navigation */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between', 
        alignItems: { xs: 'center', sm: 'flex-end' }, 
        mb: 2,
        gap: 2
      }}>
        {/* Layout and display options */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          gap: 2,
          width: { xs: '100%', sm: 'auto' }
        }}>
          {/* Layout selection */}
          <Paper 
            elevation={1} 
            sx={{ 
              p: 1.5, 
              borderRadius: '8px',
              backgroundColor: '#f5f5f5',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexGrow: 1
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <GridViewIcon sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="body2" fontWeight="medium">
                Layout:
              </Typography>
            </Box>
            
            <RadioGroup
              row
              aria-label="grid-layout"
              name="grid-layout"
              value={gridLayout}
              onChange={handleLayoutChange}
            >
              <FormControlLabel 
                value="2x2" 
                control={<Radio size="small" />} 
                label={<Typography variant="body2">2×2</Typography>} 
                sx={{ mr: 1 }}
              />
              <FormControlLabel 
                value="3x3" 
                control={<Radio size="small" />} 
                label={<Typography variant="body2">3×3</Typography>} 
                sx={{ mr: 1 }}
              />
              <FormControlLabel 
                value="3x4" 
                control={<Radio size="small" />} 
                label={<Typography variant="body2">3×4</Typography>} 
                sx={{ mr: 1 }}
              />
              <FormControlLabel 
                value="4x4" 
                control={<Radio size="small" />} 
                label={<Typography variant="body2">4×4</Typography>} 
              />
            </RadioGroup>
          </Paper>
        </Box>
        
        {/* Page navigation */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          justifyContent: { xs: 'center', sm: 'flex-end' },
          width: { xs: '100%', sm: 'auto' }
        }}>
          <IconButton 
            onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
            disabled={currentPage === 1}
            size="small"
          >
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
          
          <Typography variant="body1" fontWeight="medium">
            Page {currentPage} of {totalPages || 1}
          </Typography>
          
          <IconButton 
            onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
            disabled={currentPage === totalPages || totalPages === 0}
            size="small"
          >
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      
      {/* Binder pages */}
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          borderRadius: '8px',
          overflow: 'hidden',
          minHeight: { xs: '400px', sm: '600px' },
          backgroundColor: '#d0d0d0', // Binder spine color
          position: 'relative',
        }}
      >
        {/* Center spine */}
        <Box 
          sx={{ 
            width: '20px', 
            backgroundColor: '#d0d0d0',
            backgroundImage: 'linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.1) 100%)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          {Array.from({ length: 5 }).map((_, i) => (
            <Box 
              key={`ring-${i}`}
              sx={{ 
                width: '16px', 
                height: '16px', 
                borderRadius: '50%', 
                border: '2px solid rgba(0,0,0,0.3)',
                backgroundColor: '#b0b0b0',
              }} 
            />
          ))}
        </Box>
        
        {/* Left page */}
        {renderPage(leftPageCards, true)}
        
        {/* Right page */}
        {renderPage(rightPageCards, false)}
      </Paper>
      
      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Pagination 
          count={totalPages} 
          page={currentPage} 
          onChange={handlePageChange} 
          color="primary" 
          size={isSmallScreen ? "medium" : "large"}
          showFirstButton 
          showLastButton
        />
      </Box>
    </Box>
  );
};

export default BinderCardDisplay; 