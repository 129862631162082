import React, { memo } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { PKRoute } from './RouteManager';
import RouteEditForm from './RouteEditForm';

interface RouteEditDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    values: Partial<PKRoute>;
    onChange: (field: keyof PKRoute, value: any) => void;
}

const RouteEditDialog = memo(({ 
    open, 
    onClose, 
    onSave, 
    values, 
    onChange 
}: RouteEditDialogProps) => {
    // console.log('RouteEditDialog render');
    
    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            maxWidth="md" 
            fullWidth
        >
            <DialogTitle>Edit Route</DialogTitle>
            <DialogContent>
                <RouteEditForm 
                    values={values}
                    onChange={onChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSave} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
});

RouteEditDialog.displayName = 'RouteEditDialog';

export default RouteEditDialog; 