import React, { useState } from 'react';
import { Paper, Box, Pagination, Typography } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CameoCardTemplate from './CameoCardTemplate';

// Import the common admin card styles instead of local CSS
import '../common/AdminCardDisplay.css';
import assets from '../../../../assets';
import { CameoCard } from '../../../../types/admin';

interface CameoCardDisplayProps {
    cards: CameoCard[];
    filterStatus: 'processed' | 'unprocessed';
    page: number;
    setPage: (page: number) => void;
    onCardsUpdate: (updatedCards: CameoCard[]) => void;
}

const CARDS_PER_PAGE = 21;

const CameoCardDisplay: React.FC<CameoCardDisplayProps> = ({
    cards,
    filterStatus,
    page,
    setPage,
    onCardsUpdate,
}) => {
    const [selectedCard, setSelectedCard] = useState<CameoCard | null>(null);

    const filteredCards = cards.filter((card) => {
        if (filterStatus === 'processed') {
            return card.isCameoComplete;
        } else {
            return !card.isCameoComplete;
        }
    });

    const totalPages = Math.ceil(filteredCards.length / CARDS_PER_PAGE);
    const startIndex = (page - 1) * CARDS_PER_PAGE;
    const paginatedCards = filteredCards.slice(startIndex, startIndex + CARDS_PER_PAGE);

    const handleCardClick = (card: CameoCard) => {
        setSelectedCard(card);
    };

    const handleClose = () => {
        setSelectedCard(null);
    };

    const handleSubmitSuccess = () => {
        // Remove the cards update logic from here since we only want it on completion
    };

    const handleCompleteSuccess = () => {
        const updatedCards = cards.map((card) =>
            card.id === selectedCard?.id
                ? { ...card, isCameoComplete: !card.isCameoComplete }
                : card
        );
        onCardsUpdate(updatedCards);
    };

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        window.scrollTo(0, 0);
    };

    // Render a message when there are no cards to display
    if (filteredCards.length === 0) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
                <Paper elevation={3} sx={{ p: 3, maxWidth: '600px', width: '100%' }}>
                    <Typography variant="h5" align="center" gutterBottom>
                        {cards.length === 0 
                            ? "No Cards Available" 
                            : filterStatus === 'unprocessed' 
                                ? "All Cameo Cards Processed" 
                                : "No Processed Cameo Cards"}
                    </Typography>
                    <Typography align="center" color="text.secondary">
                        {cards.length === 0 
                            ? "There are no cards available to display." 
                            : filterStatus === 'unprocessed' 
                                ? "All cards in this set have been processed for cameo Pokémon." 
                                : "There are no processed cameo cards to display."}
                    </Typography>
                </Paper>
            </Box>
        );
    }

    return (
        <>
            <div className="admin-card-view-container">
                {paginatedCards.map((card) => (
                    <div key={card.id} className="admin-card-item" onClick={() => handleCardClick(card)}>
                        <Paper
                            elevation={1}
                            sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                backgroundColor: 'white',
                                '&:hover': {
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <div className="admin-card-content-box">
                                <div className="admin-card-image-wrapper">
                                    <LazyLoadImage
                                        className="admin-card-img"
                                        src={card.imagelocation || assets.site.images.backupImage}
                                        alt={card.name}
                                        effect="blur"
                                        width="100%"
                                        height="100%"
                                        style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '10px'
                                        }}
                                    />
                                    
                                    {card.isCameoComplete && (
                                        <div className="admin-card-status-indicator admin-status-active" />
                                    )}
                                </div>
                                
                                <div className="admin-card-info-section">
                                    <div className="admin-card-title-row">
                                        <h3 className="admin-card-title">{card.name}</h3>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>
                ))}
            </div>

            {totalPages > 1 && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 3,
                        mb: 3,
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        size="large"
                    />
                </Box>
            )}

            {selectedCard && (
                <CameoCardTemplate
                    cardInfo={selectedCard}
                    onClose={handleClose}
                    onSubmitSuccess={handleSubmitSuccess}
                    onCompleteSuccess={handleCompleteSuccess}
                />
            )}
        </>
    );
};

export default CameoCardDisplay;
