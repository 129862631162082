import React, { useState, useEffect, useCallback } from 'react';
import { Box, useScrollTrigger, Zoom, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FeaturedCardHeader from './FeaturedCardHeader';
import FeaturedCardFilter, { Filters } from './FeaturedCardFilter';
import FeaturedCardDisplay from './FeaturedCardDisplay';
import { useAdminFunctions } from '../../../../hooks/useAdminFunctions';
import { FeaturedCard } from '../../../../types/admin';
import FeaturedCardTemplate from './FeaturedCardTemplate';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import CardSetSelector from '../CardImageManager/CardSetSelector';

function ScrollTop() {
    const trigger = useScrollTrigger({
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}
            >
                <Fab color="primary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>
        </Zoom>
    );
}

const FeaturedCardManager: React.FC = () => {
    const { getFeaturedCardStats, getFeaturedPokemonCards } = useAdminFunctions();
    const [totalCards, setTotalCards] = useState(0);
    const [featuredCards, setFeaturedCards] = useState(0);
    const [cards, setCards] = useState<FeaturedCard[]>([]);
    const [filters, setFilters] = useState<Filters>({
        status: 'unprocessed',
    });
    const [page, setPage] = useState(1);
    const [selectedCard, setSelectedCard] = useState<FeaturedCard | null>(null);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [selectedSetId, setSelectedSetId] = useState<number | null>(null);
    const [isLoadingCards, setIsLoadingCards] = useState(false);

    const fetchStats = useCallback(async () => {
        const stats = await getFeaturedCardStats();
        setTotalCards(stats.totalCards);
        setFeaturedCards(stats.featuredCards);
    }, [getFeaturedCardStats]);

    const fetchCards = useCallback(async (setId: number | null) => {
        if (!setId) return;
        
        setIsLoadingCards(true);
        try {
            const fetchedCards = await getFeaturedPokemonCards(setId);
            setCards(fetchedCards);
        } finally {
            setIsLoadingCards(false);
        }
    }, [getFeaturedPokemonCards]);

    useEffect(() => {
        const fetchInitialData = async () => {
            await fetchStats();
            setIsInitialLoading(false);
        };

        fetchInitialData();
    }, [fetchStats]);

    const handleFiltersChange = (newFilters: Filters) => {
        setFilters(newFilters);
        setPage(1);
    };

    const handleCardsUpdate = (updatedCards: FeaturedCard[]) => {
        setCards(updatedCards);
        setFeaturedCards((prev) => (filters.status === 'unprocessed' ? prev + 1 : prev - 1));
        setTotalCards((prev) => prev);
    };

    const handleCardUpdateSuccess = async () => {
        const stats = await getFeaturedCardStats();
        setTotalCards(stats.totalCards);
        setFeaturedCards(stats.featuredCards);

        if (selectedSetId) {
            await fetchCards(selectedSetId);
        }
    };

    const handleSetChange = async (setId: number | null) => {
        setSelectedSetId(setId);
        setPage(1);

        if (setId) {
            await fetchCards(setId);
        } else {
            setCards([]);
        }
    };

    if (isInitialLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <Box sx={{ p: 2 }}>
                <FeaturedCardHeader 
                    totalCards={totalCards} 
                    featuredCards={featuredCards} 
                    loading={isLoadingCards}
                />
                <CardSetSelector 
                    selectedSetId={selectedSetId} 
                    onSetChange={handleSetChange} 
                    includeStats={true}
                    statsType="featured"
                />
                <FeaturedCardFilter filters={filters} setFilters={handleFiltersChange} />
                <FeaturedCardDisplay
                    cards={cards}
                    filterStatus={filters.status}
                    page={page}
                    setPage={setPage}
                    onCardsUpdate={handleCardsUpdate}
                    selectedSetId={selectedSetId}
                />
            </Box>
            <ScrollTop />
            {selectedCard && (
                <FeaturedCardTemplate
                    cardInfo={selectedCard}
                    onClose={() => setSelectedCard(null)}
                    onSubmitSuccess={handleCardUpdateSuccess}
                    onCompleteSuccess={handleCardUpdateSuccess}
                />
            )}
        </>
    );
};

export default FeaturedCardManager;
