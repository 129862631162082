import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { usePokemonSetFunctions } from '../hooks/usePokemonSetFunctions';
import { PokemonSet } from '../types/admin';
import { useAdminFunctions } from '../hooks/useAdminFunctions';

interface PokemonSetSelectorProps {
    value: number | null;
    onChange: (value: number | null) => void;
    includeStats?: boolean;
    statsType?: 'category' | 'featured' | 'cameo';
    isAdmin?: boolean;
}

export const PokemonSetSelector: React.FC<PokemonSetSelectorProps> = ({ 
    value, 
    onChange, 
    includeStats = false, 
    statsType = 'category',
    isAdmin = false
}) => {
    const { getPokemonSets: getNonAdminPokemonSets, loading: nonAdminLoading } = usePokemonSetFunctions();
    const { getPokemonSets: getAdminPokemonSets, loading: adminLoading } = useAdminFunctions();
    const [pokemonSets, setPokemonSets] = useState<PokemonSet[]>([]);
    const [selectedSet, setSelectedSet] = useState<PokemonSet | null>(null);
    const loading = isAdmin ? adminLoading : nonAdminLoading;

    useEffect(() => {
        const fetchPokemonSets = async () => {
            let sets;
            if (isAdmin) {
                sets = await getAdminPokemonSets(includeStats, statsType);
            } else {
                sets = await getNonAdminPokemonSets();
            }
            setPokemonSets(sets as PokemonSet[]);
        };
        fetchPokemonSets();
    }, [getNonAdminPokemonSets, getAdminPokemonSets, includeStats, statsType, isAdmin]);

    useEffect(() => {
        if (value) {
            const set = pokemonSets.find((s) => s.id === value);
            setSelectedSet(set || null);
        } else {
            setSelectedSet(null);
        }
    }, [value, pokemonSets]);

    const handleChange = (_: any, newValue: PokemonSet | null) => {
        setSelectedSet(newValue);
        onChange(newValue ? newValue.id : null);
    };

    // Format the option label to show statistics if available
    const getOptionLabel = (option: PokemonSet) => {
        if (!includeStats) return option.name;

        let statsText = '';
        if (statsType === 'category') {
            if (option.categoryStats) {
                statsText = ` (${option.categoryStats.processedCards}/${option.categoryStats.totalCards})`;
            } else if ('processedCards' in option && 'totalCards' in option) {
                statsText = ` (${option.processedCards}/${option.totalCards})`;
            }
        } else if (statsType === 'featured') {
            if (option.featuredStats) {
                statsText = ` (${option.featuredStats.featuredCards}/${option.featuredStats.totalCards})`;
            } else if ('featuredCards' in option && 'totalCards' in option) {
                statsText = ` (${option.featuredCards}/${option.totalCards})`;
            }
        } else if (statsType === 'cameo') {
            if (option.cameoStats) {
                statsText = ` (${option.cameoStats.cameoCards}/${option.cameoStats.totalCards})`;
            } else if ('cameoCards' in option && 'totalCards' in option) {
                statsText = ` (${option.cameoCards}/${option.totalCards})`;
            }
        }

        return `${option.name}${statsText}`;
    };

    return (
        <Autocomplete<PokemonSet>
            options={pokemonSets}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select Pokemon Set"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            value={selectedSet}
            onChange={handleChange}
            loading={loading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};
