import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  Box, 
  Grid, 
  CircularProgress, 
  Alert,
  Chip,
  Divider,
  Paper,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import { formatCurrency, formatDate } from '../utils/formatters';
import useSealedProductDetails from '../hooks/useSealedProductDetails';
import '../styles/sealedProducts.css';
import assets from '../assets';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface SealedProductModalProps {
  open: boolean;
  onClose: () => void;
  productId: number;
  isMobile?: boolean;
}

/**
 * Modal component for displaying detailed sealed product information
 */
const SealedProductModal: React.FC<SealedProductModalProps> = ({ 
  open, 
  onClose, 
  productId,
  isMobile = false 
}) => {
  const { product, isLoading, error } = useSealedProductDetails(productId);
  const [timeRange, setTimeRange] = useState<string>('6m');

  // Generate TCGPlayer link for a product
  const generateTCGPlayerLink = (productId: number) => {
    const originalLink = 'https://partner.tcgplayer.com/c/5054575/1780961/21018';
    return `${originalLink}?u=https://www.tcgplayer.com/product/${productId}`;
  };

  // Function to create a safe HTML content object
  const createMarkup = (htmlContent: string) => {
    return { __html: htmlContent };
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Box my={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      );
    }

    if (!product) {
      return (
        <Box my={2}>
          <Alert severity="info">Product information not available.</Alert>
        </Box>
      );
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Paper
            elevation={4}
            sx={{
              borderRadius: '12px',
              overflow: 'hidden',
              bgcolor: '#f5f5f5',
              p: 2,
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <LazyLoadImage
              className="sealed-card-image"
              src={product.image_url || 'https://mypokellection.com/ImageAssets/MissingProduct.png'}
              alt={product.name}
              effect="blur"
              width="auto"
              height="auto"
              placeholderSrc={'https://mypokellection.com/ImageAssets/MissingProduct.png'}
              style={{
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: 350,
                marginTop: 0
              }}
              wrapperClassName="sealed-image-wrapper"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box className="sealed-card-info-section" sx={{ p: 2 }}>
            <Box className="sealed-card-name-price" mb={2}>
              <Typography variant="h5" component="h2" className="sealed-card-name" fontWeight="bold" gutterBottom>
                {product.name}
              </Typography>
              
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography 
                    variant="h4" 
                    className="sealed-card-price" 
                    sx={{ 
                      fontWeight: 'bold',
                      color: '#2e7d32'
                    }}
                  >
                    {formatCurrency(product.market_price || 0)}
                  </Typography>
                  
                  {/* {product.is_pre_sale && (
                    <Chip 
                      label="Pre-Sale" 
                      color="secondary" 
                      size="small"
                      sx={{ fontWeight: 'bold' }}
                    />
                  )} */}
                </Box>
                
                {product.product_id && (
                  <a 
                    href={generateTCGPlayerLink(product.product_id)} 
                    target="_blank" 
                    rel="noreferrer" 
                    id={`tcgplayer-link-${product.id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <img
                      src={assets.site.images.tcgplayerIcon1}
                      alt="Buy on TCGPlayer"
                      style={{ height: '39px' }}
                    />
                  </a>
                )}
              </Box>
            </Box>
          
            <Divider sx={{ my: 2 }} />
          
            {product.description ? (
              <Box 
                className="product-description"
                sx={{ 
                  fontFamily: 'inherit',
                  fontSize: '1rem',
                  lineHeight: 1.6,
                  color: 'text.primary',
                  '& br': { display: 'block', content: '""', marginTop: '0.5em' },
                  '& p': { marginBottom: '0.75em' },
                  '& strong, & b': { fontWeight: 'bold' },
                  '& em, & i': { fontStyle: 'italic' },
                  '& ul, & ol': { paddingLeft: '1.5em', marginBottom: '1em' },
                  '& li': { marginBottom: '0.25em' }
                }}
                dangerouslySetInnerHTML={createMarkup(product.description)}
              />
            ) : (
              <Typography variant="body1" paragraph sx={{ fontFamily: 'inherit' }}>
                No description available for this product.
              </Typography>
            )}
          
            {renderPriceChart()}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderPriceChart = () => {
    if (!product || !product.price_history || product.price_history.length === 0) {
      return (
        <Box my={2}>
          <Alert severity="info">No price history available for this product.</Alert>
        </Box>
      );
    }

    // Sort price history by date (oldest to newest)
    const sortedHistory = [...product.price_history].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    // Filter data based on selected time range
    const filteredHistory = (() => {
      const now = new Date();
      let cutoffDate = new Date(now);
      
      switch (timeRange) {
        case '1m':
          cutoffDate.setMonth(now.getMonth() - 1);
          break;
        case '3m':
          cutoffDate.setMonth(now.getMonth() - 3);
          break;
        case '6m':
          cutoffDate.setMonth(now.getMonth() - 6);
          break;
        case '1y':
          cutoffDate.setFullYear(now.getFullYear() - 1);
          break;
        case 'all':
          return sortedHistory;
        default:
          cutoffDate.setMonth(now.getMonth() - 6);
      }

      return sortedHistory.filter(item => new Date(item.date) >= cutoffDate);
    })();

    if (filteredHistory.length === 0) {
      return (
        <Box my={2}>
          <Alert severity="info">No price history available for the selected time range.</Alert>
        </Box>
      );
    }

    const chartData = {
      labels: filteredHistory.map(item => formatDate(item.date)),
      datasets: [
        {
          label: 'Price History',
          data: filteredHistory.map(item => item.market_price),
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
          fill: false
        }
      ]
    };

    const chartOptions: ChartOptions<'line'> = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: true,
          text: 'Price History'
        }
      },
      scales: {
        y: {
          beginAtZero: false,
          ticks: {
            callback: (value) => formatCurrency(value as number)
          }
        }
      }
    };

    return (
      <Box my={2}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <ToggleButtonGroup
            value={timeRange}
            exclusive
            onChange={(event, newTimeRange) => {
              if (newTimeRange !== null) {
                setTimeRange(newTimeRange);
              }
            }}
            size="small"
          >
            <ToggleButton value="1m">1M</ToggleButton>
            <ToggleButton value="3m">3M</ToggleButton>
            <ToggleButton value="6m">6M</ToggleButton>
            <ToggleButton value="1y">1Y</ToggleButton>
            <ToggleButton value="all">All</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Line data={chartData} options={chartOptions} />
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      aria-labelledby="sealed-product-dialog-title"
      className="sealed-product-modal"
      PaperProps={{
        sx: {
          borderRadius: '12px',
          overflow: 'hidden'
        }
      }}
    >
      <DialogTitle 
        id="sealed-product-dialog-title"
        sx={{ 
          fontFamily: 'inherit', 
          fontWeight: 'bold',
          bgcolor: (theme) => theme.palette.primary.main,
          color: 'white'
        }}
      >
        {product ? product.name : 'Product Details'}
      </DialogTitle>
      <DialogContent dividers>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose} 
          color="primary"
          variant="contained"
          sx={{ borderRadius: '8px' }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SealedProductModal; 