import React, { useRef, useEffect, useState } from 'react';
import { Box, IconButton, Typography, Divider, CircularProgress, styled, Paper, Grid, Alert, ToggleButtonGroup, ToggleButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import assets from '../../../assets';
import config from '../../../routes/config';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

/**
 * Formats a date string for display in the chart
 * @param dateString - ISO date string to format
 * @returns Formatted date string in the format "MMM D, YYYY"
 */
const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric', 
        year: 'numeric' 
    });
};

/**
 * Formats a number as USD currency
 * @param value - Number to format
 * @returns Formatted currency string
 */
const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};

// Styled components
const StyledOverlay = styled(Box)({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,
    padding: '20px',
});

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    maxWidth: '1200px',
    width: '100%',
    maxHeight: '90vh',
    overflow: 'auto',
    position: 'relative',
    boxShadow: theme.shadows[24],
}));

const StyledHeader = styled(Box)(({ theme }) => ({
    padding: '16px 24px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
}));

const StyledContent = styled(Box)(({ theme }) => ({
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
    },
}));

const InfoSection = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
}));

const InfoCard = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    borderRadius: '8px',
    padding: '16px',
}));

const ImageSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
    },
}));

type Props = {
    cardInfo: {
        id: number;
        cardsetindex: number;
        editionId?: number;
        imageLocation2x: string;
        imagelocation: string;
        isCardInCollection: boolean;
        isErrorCard: number;
        isPartOfSubSet: number;
        isPromoCard: number;
        isReverseHolo: number;
        languageId: number;
        market_price: number;
        number: string;
        pokemonsetId: number;
        rarityId?: number;
        seriesId?: number;
        showPriceData?: boolean;
        name: string;
        seriesName: string;
        setName: string;
        product_id: number;
        tcgPlayerProductLink: string;
        setSeries: string;
        icon?: string;
        type: string;
        setPrintRuns?: {
            firstEditionSet?: {
                buttonDisplay: string;
                description: string;
            };
            shadowlessSet?: {
                buttonDisplay: string;
                description: string;
            };
            unlimitedEditionSet?: {
                buttonDisplay: string;
                description: string;
            };
            fourthPrintSet?: {
                buttonDisplay: string;
                description: string;
            };
        };
    };
    onClose: () => void;
};

/**
 * CardTemplate component displays detailed information about a Pokemon card
 * including its market price history, card details, and stats.
 * 
 * @component
 * @param {Props} props - Component props
 * @param {CardInfo} props.cardInfo - Information about the card to display
 * @param {() => void} props.onClose - Callback function to close the modal
 * @returns {JSX.Element} Rendered component
 */
const CardTemplate: React.FC<Props> = ({ cardInfo, onClose }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState({
        edition: null,
        rarity: null,
        hp: null,
        weakness: null,
        resistance: null,
        retreatCost: null,
        type: null,
        typeIcon: null,
        stage: null,
        attacks: [
            {
                attackOriginalString: null,
                attackName: null,
                attackDescription: null,
                attackDamage: null,
                attackEnergyCost: null,
            },
            {
                attackOriginalString: null,
                attackName: null,
                attackDescription: null,
                attackDamage: null,
                attackEnergyCost: null,
            },
        ],
    });
    const [priceHistory, setPriceHistory] = useState<{ date: string; market_price: number }[]>([]);
    const [loading, setLoading] = useState(true);
    const [timeRange, setTimeRange] = useState<string>('6m');

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    useEffect(() => {
        const fetchCardDetails = async () => {
            if (cardInfo.id) {
                setLoading(true);
                try {
                    const response = await fetch(`${config.API_URL}/getCardDetails/${cardInfo.id}`, {
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                        method: 'GET'
                    });
                    const cardDetails = await response.json();

                    setData({
                        edition: cardDetails.editionName,
                        rarity: cardDetails.cardRarityName,
                        hp: cardDetails.cardHP,
                        weakness: cardDetails.cardWeakness,
                        resistance: cardDetails.cardResistance,
                        retreatCost: cardDetails.cardRetreatCost,
                        type: cardDetails.cardType,
                        typeIcon: cardDetails.cardIcon,
                        stage: cardDetails.cardStageName,
                        attacks: (cardDetails.attacks || []).slice(0, 2),
                    });
                    
                    // Fetch price history data
                    try {
                        const priceHistoryResponse = await fetch(`${config.API_URL}/getCardPriceHistory/${cardInfo.id}`, {
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                            },
                            method: 'GET'
                        });
                        
                        if (priceHistoryResponse.ok) {
                            const priceData = await priceHistoryResponse.json();
                            if (priceData.success && priceData.price_history && priceData.price_history.length > 0) {
                                setPriceHistory(priceData.price_history);
                            } else {
                                setPriceHistory([]);
                            }
                        } else {
                            setPriceHistory([]);
                        }
                    } catch (priceError) {
                        console.error('Error fetching price history:', priceError);
                        setPriceHistory([]);
                    } finally {
                        setLoading(false);
                    }
                } catch (error) {
                    console.error('Error fetching card details:', error);
                    setLoading(false);
                }
            }
        };

        fetchCardDetails();
    }, [cardInfo.id, cardInfo.product_id, cardInfo.market_price]);

    const originalLink = 'https://partner.tcgplayer.com/c/5054575/1780961/21018';
    let modifiedLink = '';

    if (cardInfo.type === 'WOTC' && cardInfo?.setPrintRuns?.firstEditionSet) {
        if (cardInfo.editionId === 1) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${cardInfo.product_id}?Printing=1st+Edition+Holofoil|1st+Edition`;
        } else if (cardInfo.editionId === 3) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${cardInfo.product_id}?Printing=Unlimited+Holofoil|Unlimited`;
        }
    } else {
        if (cardInfo.isReverseHolo) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${cardInfo.product_id}?Printing=Reverse+Holofoil`;
        } else if (cardInfo.isPartOfSubSet) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${cardInfo.product_id}?Printing=Holofoil`;
        } else {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${cardInfo.product_id}?Printing=Normal|Holofoil`;
        }
    }

    /**
     * Renders the price history chart component
     * @returns {JSX.Element} Price history chart component
     */
    const renderPriceChart = (): JSX.Element => {
        // Replace the "Coming Soon" feature with actual chart implementation
        if (priceHistory.length === 0) {
            return (
                <Box my={2}>
                    <Alert severity="info">No price history available for this card.</Alert>
                </Box>
            );
        }

        // Sort price history by date (oldest to newest)
        const sortedHistory = [...priceHistory].sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );

        // Filter data based on selected time range
        const filteredHistory = (() => {
            const now = new Date();
            let cutoffDate = new Date(now);
            
            switch (timeRange) {
                case '1m':
                    cutoffDate.setMonth(now.getMonth() - 1);
                    break;
                case '3m':
                    cutoffDate.setMonth(now.getMonth() - 3);
                    break;
                case '6m':
                    cutoffDate.setMonth(now.getMonth() - 6);
                    break;
                case '1y':
                    cutoffDate.setFullYear(now.getFullYear() - 1);
                    break;
                case 'all':
                default:
                    return sortedHistory;
            }
            
            return sortedHistory.filter(item => new Date(item.date) >= cutoffDate);
        })();

        // Handle time range change
        const handleTimeRangeChange = (
            event: React.MouseEvent<HTMLElement>,
            newTimeRange: string,
        ) => {
            if (newTimeRange !== null) {
                setTimeRange(newTimeRange);
            }
        };

        const chartData = {
            labels: filteredHistory.map(point => formatDate(point.date)),
            datasets: [
                {
                    label: 'Market Price',
                    data: filteredHistory.map(point => point.market_price),
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    tension: 0.3,
                },
            ],
        };

        const chartOptions: ChartOptions<'line'> = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top' as const,
                    align: 'end',
                    labels: {
                        boxWidth: 12,
                        usePointStyle: true,
                        pointStyle: 'circle'
                    }
                },
                title: {
                    display: true,
                    text: 'Price History',
                    align: 'start',
                    font: {
                        size: 14
                    }
                },
                tooltip: {
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    titleColor: '#333',
                    bodyColor: '#333',
                    borderColor: 'rgba(75, 192, 192, 0.5)',
                    borderWidth: 1,
                    displayColors: false,
                    callbacks: {
                        label: function(context) {
                            return formatCurrency(context.parsed.y);
                        }
                    },
                    mode: 'index',
                    intersect: false
                }
            },
            interaction: {
                mode: 'index',
                intersect: false,
            },
            hover: {
                mode: 'index',
                intersect: false
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        maxRotation: 45,
                        minRotation: 45,
                        autoSkip: true,
                        maxTicksLimit: 10
                    }
                },
                y: {
                    beginAtZero: false,
                    ticks: {
                        callback: function(value) {
                            return formatCurrency(Number(value));
                        }
                    },
                    grid: {
                        color: 'rgba(0, 0, 0, 0.05)'
                    }
                },
            },
            elements: {
                line: {
                    tension: 0.3
                },
                point: {
                    radius: 2,
                    hoverRadius: 6,
                    hitRadius: 30
                }
            }
        };

        return (
            <Box mt={3}>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 2
                }}>
                    <Typography variant="subtitle1">Price History</Typography>
                    <ToggleButtonGroup
                        value={timeRange}
                        exclusive
                        onChange={handleTimeRangeChange}
                        aria-label="time range"
                        size="small"
                    >
                        <ToggleButton value="1m" aria-label="1 month">
                            1M
                        </ToggleButton>
                        <ToggleButton value="3m" aria-label="3 months">
                            3M
                        </ToggleButton>
                        <ToggleButton value="6m" aria-label="6 months">
                            6M
                        </ToggleButton>
                        <ToggleButton value="1y" aria-label="1 year">
                            1Y
                        </ToggleButton>
                        <ToggleButton value="all" aria-label="all time">
                            All
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Paper
                    elevation={2}
                    sx={{
                        p: 2,
                        borderRadius: '8px',
                        height: 240,
                        width: '100%'
                    }}
                    className="price-history-chart"
                >
                    <Line data={chartData} options={chartOptions} />
                </Paper>
            </Box>
        );
    };

    return (
        <StyledOverlay>
            <StyledContainer ref={containerRef}>
                <StyledHeader>
                    <Typography variant="h6">{cardInfo.name}</Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </StyledHeader>

                <StyledContent>
                    <ImageSection>
                        <LazyLoadImage
                            src={cardInfo.imageLocation2x || cardInfo.imagelocation}
                            alt={cardInfo.name}
                            effect="blur"
                            style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '8px',
                            }}
                        />
                    </ImageSection>

                    <InfoSection>
                        {loading ? (
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                minHeight: '400px'
                            }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <InfoCard>
                                    <Typography variant="subtitle1" gutterBottom>Set Information</Typography>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Set</Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <img
                                                    src={cardInfo.icon}
                                                    alt={cardInfo.setName}
                                                    style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                                <Typography>{cardInfo.setName}</Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Series</Typography>
                                            <Typography>{cardInfo.setSeries}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Card Number</Typography>
                                            <Typography>{cardInfo.number}</Typography>
                                        </Box>
                                    </Box>
                                </InfoCard>

                                <InfoCard>
                                    <Typography variant="subtitle1" gutterBottom>Market Information</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Market Price</Typography>
                                            <Typography variant="h6">
                                                ${cardInfo.market_price?.toFixed(2) || 'N/A'}
                                            </Typography>
                                        </Box>
                                        <a href={modifiedLink} target="_blank" rel="noreferrer" id="1808389">
                                            <img
                                                src={assets.site.images.tcgplayerIcon1}
                                                alt="Buy on TCGPlayer"
                                                style={{ height: '39px' }}
                                            />
                                        </a>
                                    </Box>
                                    
                                    {/* Price History Chart */}
                                    {renderPriceChart()}
                                </InfoCard>

                                <InfoCard>
                                    <Typography variant="subtitle1" gutterBottom>Card Details</Typography>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2 }}>
                                        {cardInfo.type === 'WOTC' && (
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">Edition</Typography>
                                                <Typography>{data.edition || 'N/A'}</Typography>
                                            </Box>
                                        )}
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Rarity</Typography>
                                            <Typography>{data.rarity || 'N/A'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Type</Typography>
                                            <Typography>{data.type || 'N/A'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Stage</Typography>
                                            <Typography>{data.stage || 'N/A'}</Typography>
                                        </Box>
                                    </Box>
                                </InfoCard>

                                <InfoCard>
                                    <Typography variant="subtitle1" gutterBottom>Pokemon Stats</Typography>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2 }}>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">HP</Typography>
                                            <Typography>{data.hp || 'N/A'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Weakness</Typography>
                                            <Typography>{data.weakness || 'N/A'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Resistance</Typography>
                                            <Typography>{data.resistance || 'N/A'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Retreat Cost</Typography>
                                            <Typography>{data.retreatCost || 'N/A'}</Typography>
                                        </Box>
                                    </Box>
                                </InfoCard>

                                {data.attacks.map((attack, index) => (
                                    attack && (
                                        <InfoCard key={index}>
                                            <Typography variant="subtitle1" gutterBottom>Attack {index + 1}</Typography>
                                            {attack.attackOriginalString ? (
                                                <Typography>{attack.attackOriginalString}</Typography>
                                            ) : (
                                                <>
                                                    <Box sx={{ 
                                                        display: 'grid', 
                                                        gridTemplateColumns: 'repeat(3, 1fr)', 
                                                        gap: 2,
                                                        mb: 2 
                                                    }}>
                                                        <Box>
                                                            <Typography variant="body2" color="text.secondary">Energy Cost</Typography>
                                                            <Typography>{attack.attackEnergyCost || 'N/A'}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="body2" color="text.secondary">Name</Typography>
                                                            <Typography>{attack.attackName || 'N/A'}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="body2" color="text.secondary">Damage</Typography>
                                                            <Typography>{attack.attackDamage || 'N/A'}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="body2" color="text.secondary">Description</Typography>
                                                        <Typography>{attack.attackDescription || 'N/A'}</Typography>
                                                    </Box>
                                                </>
                                            )}
                                        </InfoCard>
                                    )
                                ))}
                            </>
                        )}
                    </InfoSection>
                </StyledContent>
            </StyledContainer>
        </StyledOverlay>
    );
};

export default React.memo(CardTemplate);

