import React, { useEffect } from 'react';
import { 
  Grid, 
  Paper,
  Typography, 
  Box, 
  Chip, 
  CircularProgress, 
  Alert
} from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { SealedProduct } from '../types/sealedProduct';
import { formatCurrency } from '../utils/formatters';
import '../styles/sealedProducts.css';
import assets from '../assets';

interface SealedProductsPanelProps {
  products: SealedProduct[];
  isLoading: boolean;
  error: string | null;
  onProductClick: (productId: number) => void;
  isMobile?: boolean;
}

/**
 * Component to display a grid of sealed products for a Pokemon set
 */
const SealedProductsPanel: React.FC<SealedProductsPanelProps> = ({
  products,
  isLoading,
  error,
  onProductClick,
  isMobile = false
}) => {
  // Add logging when the component renders or receives new props
  useEffect(() => {
    // console.group('🛍️ SealedProductsPanel Render');
    // console.log('SealedProductsPanel props:', {
    //   productsCount: products?.length || 0,
    //   isLoading,
    //   hasError: !!error,
    //   errorMessage: error,
    //   timestamp: new Date().toISOString()
    // });
    
    // if (products?.length > 0) {
    //   console.log('First product sample:', {
    //     id: products[0].id,
    //     name: products[0].name,
    //     price: products[0].market_price,
    //     imageUrl: products[0].image_url?.substring(0, 50) + (products[0].image_url?.length > 50 ? '...' : '')
    //   });
    // }
    // console.groupEnd();
  }, [products, isLoading, error]);

  // Log product clicks
  const handleProductClick = (productId: number) => {
    // console.log(`🖱️ Sealed product clicked: ID=${productId}`);
    onProductClick(productId);
  };

  // Generate TCGPlayer link for a product
  const generateTCGPlayerLink = (productId: number) => {
    const originalLink = 'https://partner.tcgplayer.com/c/5054575/1780961/21018';
    return `${originalLink}?u=https://www.tcgplayer.com/product/${productId}`;
  };

  // Handle TCGPlayer link click without triggering the product modal
  const handleTCGPlayerClick = (event: React.MouseEvent, productId: number) => {
    event.stopPropagation();
    // console.log(`🔗 TCGPlayer link clicked for product ID=${productId}`);
    window.open(generateTCGPlayerLink(productId), '_blank');
  };

  if (isLoading) {
    // console.log('⏳ SealedProductsPanel: Showing loading state');
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    console.log('❌ SealedProductsPanel: Showing error state:', error);
    return (
      <Box my={2}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!products || products.length === 0) {
    // console.log('ℹ️ SealedProductsPanel: No products available');
    return (
      <Box my={4} textAlign="center">
        <Paper elevation={2} sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
          <Typography variant="h6" gutterBottom>No Sealed Products Available</Typography>
          <Typography variant="body1" color="text.secondary">
            There are no sealed products available for this set.
          </Typography>
        </Paper>
      </Box>
    );
  }

  // console.log(`✅ SealedProductsPanel: Rendering ${products.length} products`);
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3} justifyContent="flex-start">
        {products.map((product) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={product.id} sx={{ display: 'flex' }}>
            <Paper
              elevation={4}
              sx={{
                height: '100%',
                margin: 'auto',
                borderRadius: '12px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                  boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                  transform: 'translateY(-4px)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                },
              }}
              onClick={() => handleProductClick(product.id)}
            >
              <div className={isMobile ? "sealed-mobile-card-box" : "sealed-desktop-card-box"}>
                <div className="sealed-card-image-container">
                  <LazyLoadImage
                    className="sealed-card-image"
                    src={product.image_url || 'https://mypokellection.com/ImageAssets/MissingProduct.png'}
                    alt={product.name}
                    effect="blur"
                    width="100%"
                    height="auto"
                    placeholderSrc={'https://mypokellection.com/ImageAssets/MissingProduct.png'}
                    style={{
                      objectFit: 'contain',
                      maxHeight: '180px',
                    }}
                    wrapperClassName="sealed-image-wrapper"
                  />
                </div>
                
                <div className="sealed-card-info-section">
                  <div className="sealed-card-name-price">
                    <h3 className="sealed-card-name">{product.name}</h3>
                    <div className="sealed-card-price-container" style={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      height: '28px' // Fixed height to ensure consistent alignment
                    }}>
                      <div className="sealed-card-price" style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ margin: 0 }}>{formatCurrency(product.market_price || 0)}</p>
                      </div>
                      {product.product_id && (
                        <div 
                          className="tcgplayer-link" 
                          onClick={(e) => handleTCGPlayerClick(e, product.product_id)}
                          style={{ 
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <img
                            src={assets.site.images.tcgplayerIcon1}
                            alt="Buy on TCGPlayer"
                            style={{ 
                              height: '24px', 
                              marginLeft: '8px',
                              verticalAlign: 'middle'
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  
                  {/* {product.is_pre_sale && (
                    <div className="sealed-card-number-pokeball">
                      <Chip 
                        label="Pre-Sale" 
                        size="small" 
                        color="secondary"
                        sx={{ fontWeight: 'bold', fontSize: '0.7rem' }}
                      />
                    </div>
                  )} */}
                </div>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SealedProductsPanel; 