import React, { useState, useEffect, useCallback } from 'react';
import { Box, useScrollTrigger, Zoom, Fab, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CameoCardHeader from './CameoCardHeader';
import CameoCardFilter, { Filters } from './CameoCardFilter';
import CameoCardDisplay from './CameoCardDisplay';
import { useAdminFunctions } from '../../../../hooks/useAdminFunctions';
import { CameoCard } from '../../../../types/admin';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import CardSetSelector from '../CardImageManager/CardSetSelector';

function ScrollTop() {
    const trigger = useScrollTrigger({
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}
            >
                <Fab color="primary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>
        </Zoom>
    );
}

const CameoCardManager: React.FC = () => {
    const { getCameoCardStats, getCameoPokemonCards, getPokemonSets } = useAdminFunctions();
    const [totalCards, setTotalCards] = useState(0);
    const [cameoCards, setCameoCards] = useState(0);
    const [cards, setCards] = useState<CameoCard[]>([]);
    const [filters, setFilters] = useState<Filters>({
        status: 'unprocessed',
    });
    const [page, setPage] = useState(1);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isCardsLoading, setIsCardsLoading] = useState(false);
    const [selectedSetId, setSelectedSetId] = useState<number | null>(null);

    const fetchStats = useCallback(async () => {
        const stats = await getCameoCardStats();
        setTotalCards(stats.totalCards);
        setCameoCards(stats.cameoCards);
    }, [getCameoCardStats]);

    const fetchCards = useCallback(async (setId: number | null) => {
        if (!setId) {
            setCards([]);
            return;
        }
        
        setIsCardsLoading(true);
        try {
            const fetchedCards = await getCameoPokemonCards(setId);
            setCards(fetchedCards);
        } finally {
            setIsCardsLoading(false);
        }
    }, [getCameoPokemonCards]);

    useEffect(() => {
        const fetchInitialData = async () => {
            await fetchStats();
            setIsInitialLoading(false);
        };

        fetchInitialData();
    }, [fetchStats]);

    useEffect(() => {
        if (selectedSetId) {
            fetchCards(selectedSetId);
        }
    }, [selectedSetId, fetchCards]);

    const handleFiltersChange = (newFilters: Filters) => {
        setFilters(newFilters);
        setPage(1);
    };

    const handleCardsUpdate = (updatedCards: CameoCard[]) => {
        setCards(updatedCards);
        setCameoCards((prev) => (filters.status === 'unprocessed' ? prev + 1 : prev - 1));
        setTotalCards((prev) => prev);
    };

    const handleSetChange = (setId: number | null) => {
        setSelectedSetId(setId);
        setPage(1);
    };

    if (isInitialLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <Box sx={{ p: 2 }}>
                <CameoCardHeader totalCards={totalCards} cameoCards={cameoCards} loading={isCardsLoading} />
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Select a Set
                    </Typography>
                    <CardSetSelector
                        selectedSetId={selectedSetId}
                        onSetChange={handleSetChange}
                        includeStats={true}
                        statsType="cameo"
                    />
                </Box>
                <CameoCardFilter filters={filters} setFilters={handleFiltersChange} />
                {isCardsLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <LoadingSpinner />
                    </Box>
                ) : (
                    <CameoCardDisplay
                        cards={cards}
                        filterStatus={filters.status}
                        page={page}
                        setPage={setPage}
                        onCardsUpdate={handleCardsUpdate}
                    />
                )}
            </Box>
            <ScrollTop />
        </>
    );
};

export default CameoCardManager;
