import React, { useState, useMemo } from 'react';
import { Divider, Paper, Typography, Box } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CardTemplate from '../English/Template/CardTemplate';

interface NEWMobileCardDisplayProps {
    cardsToDisplay: any[];
    assets: any;
    showPriceData: boolean;
    changeCardSelectedStatus: (card: any) => void;
    changeCardCollectionStatus: (cards: any[], card: any) => void;
}

const NEWMobileCardDisplay: React.FC<NEWMobileCardDisplayProps> = ({
    cardsToDisplay = [],
    assets,
    showPriceData,
    changeCardSelectedStatus,
    changeCardCollectionStatus,
}) => {
    const [selectedCard, setSelectedCard] = useState<any>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCardClick = (card: any) => {
        setSelectedCard(card);
        setIsModalOpen(true);
        changeCardSelectedStatus(card);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedCard(null);
    };

    // Group cards by Set Series and then by Set Name
    const groupedCards = useMemo(() => {
        if (!cardsToDisplay || cardsToDisplay.length === 0) {
            return {};
        }

        const grouped: Record<string, Record<string, any[]>> = {};
        
        cardsToDisplay.forEach(card => {
            const setSeries = card.setSeries || 'Unknown Series';
            const setName = card.setName || 'Unknown Set';
            
            if (!grouped[setSeries]) {
                grouped[setSeries] = {};
            }
            
            if (!grouped[setSeries][setName]) {
                grouped[setSeries][setName] = [];
            }
            
            grouped[setSeries][setName].push(card);
        });
        
        return grouped;
    }, [cardsToDisplay]);

    if (!cardsToDisplay || cardsToDisplay.length === 0) {
        return <Divider>No cards to display</Divider>;
    }

    // Custom styles
    const containerStyle = {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'center' as const,
        alignItems: 'stretch' as const,
        width: '100%',
        padding: '0 4px',
    };

    const cardContainerStyle = {
        margin: '4px',
        width: 'calc(50% - 8px)',
        maxWidth: '175px',
        minWidth: '120px',
        display: 'flex' as const,
        boxSizing: 'border-box' as const,
        padding: '4px',
    };

    const cardBoxStyle = {
        position: 'relative' as const,
        width: '100%',
        display: 'flex' as const,
        flexDirection: 'column' as const,
        alignItems: 'center' as const,
        height: '100%',
        boxSizing: 'border-box' as const,
        margin: 0,
    };

    const imageContainerStyle = {
        position: 'relative' as const,
        width: '100%',
        display: 'flex' as const,
        alignItems: 'center' as const,
        justifyContent: 'center' as const,
        minHeight: '200px',
        overflow: 'hidden' as const,
        padding: '8px',
    };

    const imageWrapperStyle = {
        width: '100%',
        height: '100%',
        display: 'flex' as const,
        alignItems: 'center' as const,
        justifyContent: 'center' as const,
        borderRadius: '10px',
        overflow: 'hidden' as const,
    };

    const infoSectionStyle = {
        width: '100%',
        padding: '8px 12px',
    };

    const namePriceStyle = {
        display: 'flex' as const,
        flexDirection: 'row' as const,
        justifyContent: 'space-between' as const,
        alignItems: 'center' as const,
        marginBottom: '8px',
        width: '100%',
    };

    const nameStyle = {
        fontSize: '0.9rem',
        margin: 0,
        whiteSpace: 'nowrap' as const,
        overflow: 'hidden' as const,
        textOverflow: 'ellipsis' as const,
        maxWidth: '70%',
    };

    const priceStyle = {
        color: 'green',
        fontSize: '0.8rem',
        margin: 0,
        textAlign: 'right' as const,
    };

    const numberPokeballStyle = {
        display: 'flex' as const,
        justifyContent: 'space-between' as const,
        alignItems: 'center' as const,
        width: '100%',
    };

    const numberStyle = {
        fontSize: '0.8rem',
        margin: 0,
    };

    const pokeballStyle = {
        width: '30px',
        height: '30px',
        cursor: 'pointer' as const,
    };

    const seriesHeaderStyle = {
        backgroundColor: '#f5f5f5',
        padding: '10px 12px',
        marginTop: '16px',
        marginBottom: '8px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    };

    const setHeaderStyle = {
        display: 'flex' as const,
        alignItems: 'center',
        padding: '6px 12px',
        marginBottom: '8px',
        backgroundColor: '#e8f4fd',
        borderRadius: '6px',
    };

    const setIconStyle = {
        width: '20px',
        height: '20px',
        marginRight: '8px',
    };

    return (
        <>
            {Object.entries(groupedCards).map(([seriesName, sets]) => (
                <div key={seriesName}>
                    <Paper elevation={2} style={seriesHeaderStyle}>
                        <Typography variant="subtitle1">{seriesName}</Typography>
                    </Paper>
                    
                    {Object.entries(sets).map(([setName, cards]) => (
                        <div key={`${seriesName}-${setName}`}>
                            <Box style={setHeaderStyle}>
                                {cards[0]?.icon ? (
                                    <img 
                                        src={cards[0].icon} 
                                        alt={`${setName} icon`} 
                                        style={setIconStyle}
                                    />
                                ) : (
                                    <img 
                                        src={assets.site.images.pokeball} 
                                        alt="Default icon" 
                                        style={setIconStyle}
                                    />
                                )}
                                <Typography variant="body1">{setName}</Typography>
                            </Box>
                            
                            <div style={containerStyle}>
                                {cards.map((card) => (
                                    <div key={card.id} style={cardContainerStyle}>
                                        <Paper
                                            elevation={4}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                borderRadius: '12px',
                                                overflow: 'hidden',
                                                '&:hover': {
                                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                                    transform: 'translateY(-4px)',
                                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                                },
                                            }}
                                        >
                                            <div style={cardBoxStyle}>
                                                <div style={imageContainerStyle}>
                                                    <div style={imageWrapperStyle}>
                                                        <LazyLoadImage
                                                            src={card.imagelocation || assets.site.images.backupImage}
                                                            alt={card.name}
                                                            effect="blur"
                                                            width="auto"
                                                            height="auto"
                                                            placeholderSrc={assets.site.images.backupImage}
                                                            style={{
                                                                objectFit: 'contain',
                                                                maxWidth: '90%',
                                                                maxHeight: '90%',
                                                                cursor: 'pointer',
                                                                borderRadius: '10px',
                                                                margin: '0 auto',
                                                                display: 'block',
                                                            }}
                                                            onClick={() => handleCardClick(card)}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div style={infoSectionStyle}>
                                                    <div style={namePriceStyle}>
                                                        <h3 style={nameStyle}>{card.name}</h3>
                                                        {showPriceData && (
                                                            <p style={priceStyle}>${card.market_price?.toFixed(2) || '0.00'}</p>
                                                        )}
                                                    </div>
                                                    
                                                    <div style={numberPokeballStyle}>
                                                        <p style={numberStyle}>{card.number}/{card.numberOfCards}</p>
                                                        <img
                                                            loading="lazy"
                                                            src={
                                                                card.isCardInCollection
                                                                    ? assets.site.images.pokeballCaught
                                                                    : assets.site.images.pokeball
                                                            }
                                                            alt={card.isCardInCollection ? "Caught" : "Not caught"}
                                                            style={pokeballStyle}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                changeCardCollectionStatus(cards, card);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Paper>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            ))}

            {isModalOpen && selectedCard && (
                <CardTemplate cardInfo={selectedCard} onClose={handleCloseModal} />
            )}
        </>
    );
};

export default NEWMobileCardDisplay;
