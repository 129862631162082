import React, { useState } from 'react';
import { Paper, Box, Pagination, Typography } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CategoryCardTemplate from './CategoryCardTemplate';
import '../common/AdminCardDisplay.css';
import { CategoryCard } from '../../../../types/admin';

interface CardCategoryDisplayProps {
    cards: CategoryCard[];
    filterStatus: 'processed' | 'unprocessed';
    page: number;
    setPage: (page: number) => void;
    onCardsUpdate: (updatedCards: CategoryCard[]) => void;
}

const CARDS_PER_PAGE = 21;

const CardCategoryDisplay: React.FC<CardCategoryDisplayProps> = ({
    cards,
    filterStatus,
    page,
    setPage,
    onCardsUpdate,
}) => {
    const [selectedCard, setSelectedCard] = useState<CategoryCard | null>(null);

    const filteredCards = cards.filter((card) =>
        filterStatus === 'processed' ? card.isProcessed : !card.isProcessed
    );

    const totalPages = Math.ceil(filteredCards.length / CARDS_PER_PAGE);
    const startIndex = (page - 1) * CARDS_PER_PAGE;
    const paginatedCards = filteredCards.slice(startIndex, startIndex + CARDS_PER_PAGE);

    const handleCardClick = (card: CategoryCard) => {
        setSelectedCard(card);
    };

    const handleClose = () => {
        setSelectedCard(null);
    };

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        window.scrollTo(0, 0);
    };

    const handleSubmitSuccess = async () => {
        // Mark the card as processed locally
        const updatedCards = cards.map((card) =>
            card.id === selectedCard?.id ? { ...card, isProcessed: true } : card
        );

        // Update the parent component's cards state
        if (typeof onCardsUpdate === 'function') {
            onCardsUpdate(updatedCards);
        }
    };

    // If there are no cards to display and we're in the unprocessed tab
    if (filteredCards.length === 0 && filterStatus === 'unprocessed' && cards.length > 0) {
        return (
            <Box my={4} textAlign="center">
                <Paper elevation={2} sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
                    <Typography variant="h6" gutterBottom>All Cards Processed</Typography>
                    <Typography variant="body1" color="text.secondary">
                        Great job! All cards in this set have been processed.
                    </Typography>
                </Paper>
            </Box>
        );
    }

    // If there are no cards at all (no set selected or empty set)
    if (filteredCards.length === 0) {
        return (
            <Box my={4} textAlign="center">
                <Paper elevation={2} sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
                    <Typography variant="h6" gutterBottom>No Cards Available</Typography>
                    <Typography variant="body1" color="text.secondary">
                        {cards.length === 0 
                            ? "Please select a set to view cards." 
                            : `No ${filterStatus} cards found in this set.`}
                    </Typography>
                </Paper>
            </Box>
        );
    }

    return (
        <>
            <div className="admin-card-view-container">
                {paginatedCards.map((card) => (
                    <div key={card.id} className="admin-card-item" onClick={() => handleCardClick(card)}>
                        <Paper
                            elevation={1}
                            sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                backgroundColor: 'white',
                                '&:hover': {
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                },
                            }}
                        >
                            <div className="admin-card-content-box">
                                <div className="admin-card-image-wrapper">
                                    <LazyLoadImage
                                        className="admin-card-img"
                                        src={card.imagelocation}
                                        alt={card.name}
                                        effect="blur"
                                        width="100%"
                                        height="100%"
                                        style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '10px'
                                        }}
                                    />
                                    
                                    {card.isProcessed && (
                                        <div className="admin-card-status-indicator admin-status-active" />
                                    )}
                                </div>
                                
                                <div className="admin-card-info-section">
                                    <div className="admin-card-title-row">
                                        <h3 className="admin-card-title">{card.name}</h3>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>
                ))}
            </div>

            {totalPages > 1 && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 3,
                        mb: 3,
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        size="large"
                    />
                </Box>
            )}

            {selectedCard && (
                <CategoryCardTemplate
                    cardInfo={selectedCard}
                    onClose={handleClose}
                    onSubmitSuccess={handleSubmitSuccess}
                />
            )}
        </>
    );
};

export default CardCategoryDisplay;
